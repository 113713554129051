import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  handleRedirectWithLoading,
  ModalOrSso,
  SectionContentMasterData,
  SectionMasterData,
} from "../../Utility/Utility";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onSaveUnlockData } from "../../Store/Slices/utilityReducer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const PromoBanner = ({
  text,
  sectionId,
  setShowModal,
  login,
  unlockData,
  creditCards,
  setIsLoading,
  isMobileView = false,
}) => {
  const isModal = ModalOrSso();

  const dispatch = useDispatch();
  const { sctionContentdata } = SectionContentMasterData();

  const { sectionMasterData } = SectionMasterData(login);

  const unlock = unlockData.UnlockSectionData;

  const bannerContent = sctionContentdata?.filter(
    (content) => content?.sectionMasterId === sectionId
  );

  const sectionClaimLimit = sectionMasterData?.filter((dataItem) =>
    bannerContent?.some(
      (filterData) => filterData?.linkedMasterId === dataItem?.id
    )
  );

  const isClaimLimitValid = sectionClaimLimit
    ?.map((limitData) => {
      const bannerContentItem = bannerContent?.find(
        (filterData) => filterData?.linkedMasterId === limitData?.id
      );

      const unlockSectionContents = sctionContentdata?.filter(
        (filterData) => filterData?.sectionMasterId === limitData?.id
      );

      const claimedUnits = unlock?.filter(
        (filterData) =>
          filterData?.sectionId === limitData?.id && !limitData?.status
      );

      if (claimedUnits?.length === limitData?.claimLimit && bannerContentItem) {
        return bannerContentItem?.id;
      } else if (
        claimedUnits?.length === unlockSectionContents.length &&
        bannerContentItem
      ) {
        return bannerContentItem?.id;
      }

      return null;
    })
    ?.filter((id) => id !== null);
  const filterBannerCon = bannerContent?.filter((content) => {
    if (content.isUnlockDeal) {
      return !isClaimLimitValid.includes(content?.id) && creditCards?.length;
    }

    return !isClaimLimitValid.includes(content?.id);
  });

  const [centerPadding, setCenterPadding] = useState("40px");

  useEffect(() => {
    const updatePadding = () => {
      if (window.innerWidth >= 430 && window.innerWidth <= 768) {
        setCenterPadding("35px");
      } else if (window.innerWidth === 360 && window.innerWidth <= 768) {
        setCenterPadding("5px");
      } else if (window.innerWidth === 412 && window.innerWidth <= 768) {
        setCenterPadding("30px");
      } else if (window.innerWidth === 414 && window.innerWidth <= 768) {
        setCenterPadding("30px");
      } else if (window.innerWidth >= 390 && window.innerWidth <= 768) {
        setCenterPadding("12px");
      } else {
        setCenterPadding("5px");
      }
    };

    updatePadding();
    window.addEventListener("resize", updatePadding);
    return () => {
      window.removeEventListener("resize", updatePadding);
    };
  }, []);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 8000,
    appendDots: (dots) => (
      <div className="carousel-dots">
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className="dot"></div>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          centerMode: true,
          centerPadding: centerPadding,
          autoplay: true,
          autoplaySpeed: 8000,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
    ],
  };
  const handleNavigate = (content) => {
    if (!login) {
      if (isModal) {
        setShowModal(true);
      } else if (content?.cta) {
        handleRedirectWithLoading(setIsLoading, content.cta, login, setShowModal, isModal);
      }
      return;
    }
  
    if (content?.isUnlockDeal) {
      const sectionMaster = sectionMasterData?.find(
        (filterData) => filterData?.id === content?.linkedMasterId
      );
  
      if (sectionMaster) {
        dispatch(
          onSaveUnlockData({
            sectionId: sectionMaster.id,
            text: sectionMaster.text,
            segmentId: sectionMaster.segmentId,
            claimLimit: sectionMaster.claimLimit,
          })
        );
  
        navigate(`/claim-bonus?stid=${sectionMaster.id}`, { state: content });
      }
      return;
    }
  
    if (content?.cta) {
      handleRedirectWithLoading(setIsLoading, content.cta, login, setShowModal, isModal);
    }
  };

  return (
    <>
      {filterBannerCon?.length ? (
        <div
          className={`${
            isMobileView ? "p-4 container mx-auto" : "pt-5"
          } featured-wrapper`}
        >
          {filterBannerCon?.length ? (
            <h2 className="featured-heading1 home-head  md:hidden">{text}</h2>
          ) : (
            ""
          )}
          <div className="banner-carousel">
            {filterBannerCon?.length > 1 ? (
              <Slider {...settings} autoplay={false} speed={300}>
                {filterBannerCon

                  .sort(
                    (a, b) =>
                      (b.isUnlockDeal ? 1 : 0) - (a.isUnlockDeal ? 1 : 0)
                  )
                  .map((content, index) => (
                    <div className="banner-slide promo-img-sec" key={index}>
                      <img
                        src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${
                          isMobileView ? content?.mobImage : content?.webImage
                        }`}
                        alt={text}
                        title={text}
                        onClick={() => handleNavigate(content)}
                        className="banner-image"
                      />
                    </div>
                  ))}
              </Slider>
            ) : (
              <div className="banner-slide mb-6">
                <img
                  onClick={() => handleNavigate(filterBannerCon?.[0])}
                  src={
                    !isMobileView
                      ? `${process.env.REACT_APP_CLIENT_IMAGE_URL}${filterBannerCon?.[0]?.webImage}`
                      : `${process.env.REACT_APP_CLIENT_IMAGE_URL}${filterBannerCon?.[0]?.mobImage}`
                  }
                  alt={text}
                  title={text}
                  className={`banner-image  ${isMobileView ? "ml-3 px-2" : ""}`}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PromoBanner;

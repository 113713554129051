import axiosIntegration from '../Common/Axios/axiosIntegration';
import API from '../Common/Endpoint/serviceConstrants';
import { ApiConfigData } from '../Utility/Utility';

export const callSendMailPostApi = async (payload) => {
  try {
    const apiconfigData = ApiConfigData();
    const clientCode = apiconfigData?.find(
      (data) => data?.paramsKey === "clientCode"
    )?.paramsValue || '';
    const partnerCode = apiconfigData?.find(
      (data) => data?.paramsKey === "partnerCode"
    )?.paramsValue || '';

    const { data = [] } = await axiosIntegration.post(API.mailApi, payload, {
      headers: {
        clientCode: clientCode,
        partnerCode: partnerCode,
      },
    });
    
    return data;
  } catch (error) {
    console.error('Error in API call:', error);

  }
};


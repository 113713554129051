import axiosInstanceClient from "../Common/Axios/axiosInstanceClient";
import API from "../Common/Endpoint/serviceConstrants";
export const callOfferNdBenefitsGetApi = async (payload) => {
  const { data = {} } = await axiosInstanceClient.get(API.offerhistory, {
    headers: {
      "Customer-Identifier": payload?.memberIdentifier,
    },
  });
  return data;
};
export const callOfferNdBenefitsPostApi = async (payload) => {
  const { data = [] } = await axiosInstanceClient.post(
    API.offerhistory,
    payload
  );
  return data;
};

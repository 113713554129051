import { createSlice } from "@reduxjs/toolkit";

export const utilityReducer = createSlice({
  name: "card_data",
  initialState: {
    getcardData: [],
    creditCards: [],
    error: false,
  },
  reducers: {
    onSaveCards: (state, { payload }) => {
      return {
        ...state,
        creditCards: payload.creditCards,
        debitCards: payload.debitCards,
        cardPointsData: payload.cardPointsData,
      };
    },
    onSaveIamge: (state, { payload }) => {
      return {
        ...state,
        currentBg: payload.currentBg,
        currentPoints: payload?.currentPoints,
        cardName: payload?.cardName,
        totalPointBalance: payload?.totalPointBalance,
      };
    },
    onSaveUnlockData: (state, { payload }) => {
      return {
        ...state,
        sectionId: payload.sectionId,
        text: payload?.text,
        segmentId: payload?.segmentId,
        claimLimit: payload?.claimLimit,
      };
    },
    onSaveUnlockContId: (state, { payload }) => {
      return {
        ...state,
        sectionContentId: payload.sectionContentId,
      };
    },
    onUpdatePointBalance: (state, { payload }) => {
      return {
        ...state,
        creditCards: payload?.updatedCreditCards,
      };
    },
    onSaveDealData: (state, { payload }) => {
      return {
        ...state,
        dealData: payload?.dealData,
        dealCouponData: payload?.dealCouponData,
      };
    },
    onSetApiError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
      };
    },
  },
});

export const {
  onSaveCards,
  onSaveIamge,
  onSaveUnlockData,
  onSaveUnlockContId,
  onUpdatePointBalance,
  onSaveDealData,
  onSetApiError,
} = utilityReducer.actions;

export default utilityReducer.reducer;

import axiosInstanceClient from "../Common/Axios/axiosInstanceClient";
import API from "../Common/Endpoint/serviceConstrants";
export const callDealCouponCodeGetApi = async (payload) => {
  const { data = {} } = await axiosInstanceClient.get(API.dealCouponCode, {
    params: {
      dealCoupounId: payload,
    },
  });
  return data;
};
export const callDealCouponCodeActiveGetApi = async () => {
  const { data = [] } = await axiosInstanceClient.get(API.dealcouponcodeactive);
  return data;
};
export const callDealCouponCodePostApi = async (payload) => {
  
  const { data = [] } = await axiosInstanceClient.post(
    API.dealCouponCode,
    payload
  );
  return data;
};

import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import reducers from './StoreReducer';
import rootSaga from '../Sagas';
import { persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const devMode = process.env.NODE_ENV === 'development';
const persistConfig = {
  key: 'root',
  storage: sessionStorage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middleware),
  devTools: devMode
});

export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
import { call, put, takeLatest } from "redux-saga/effects";
import { callSwitchAccountApi } from "../Context/switchAccountApi";
import { onSwitchAccountError, onSwitchAccountSubmit, onSwitchAccountSuccess } from "../Store/Slices/switchAccountSlice";



function* SwitchAccount({ payload }) {
    try {
        const SwitchAccountResponseData = yield call(callSwitchAccountApi, payload);
        if (SwitchAccountResponseData.responseCode === "200") { 
            yield put(
                onSwitchAccountSuccess({
                    data: SwitchAccountResponseData.response,
                    status_code:SwitchAccountResponseData.responseCode,
                })
            );  
        } else {
            yield put(
                onSwitchAccountError({
                    data: SwitchAccountResponseData.response,
                })
            );
        }
    } catch (error) {
        // const message = error.message || "Something went wrong";
       // yield put(onSwitchAccountError({ data: {}, message, }));
    }
}
export default function* SwitchAccountSaga() {
    yield takeLatest(onSwitchAccountSubmit.type, SwitchAccount);
}

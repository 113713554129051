import axios from "axios";
import { decryptAES, encryptAES } from "./encrypt";

// Create Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_Gateway,
  headers: {
    "Content-Type": "application/json",
  },
});

// List of headers to encrypt
const headersToEncrypt = ["Password", "Userid", "Client-Code", "Partner-Code"];

// Request interceptor for encryption
api.interceptors.request.use(
  (config) => {
    const isMultipart =
      config.headers["Content-Type"] &&
      config.headers["Content-Type"].includes("multipart/form-data");

    // Get Encryption mode from request headers (default to "1" if not present)
    const encryptionMode = config.headers["Encryption"] || "1";

    // Store encryption mode for later use in response interceptor
    config.metadata = { encryptionMode };

    // Encrypt body data if not multipart
    if (!isMultipart && config.data) {
      config.data = encryptAES(config.data, encryptionMode);
    }

    // Encrypt only selected headers
    if (config.headers) {
      for (const header of headersToEncrypt) {
        if (config.headers[header]) {
          config.headers[header] = encryptAES(config.headers[header], encryptionMode);
        }
      }
    }

    return config;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor for decryption
api.interceptors.response.use(
  (response) => {
    // Retrieve the encryption mode stored in request metadata
    const encryptionMode = response.config.metadata?.encryptionMode || "1";

    // If `enciv` is present, decrypt and store the IV key
    if (response.headers["enciv"]) {
      sessionStorage.setItem("ivkey", decryptAES(response.headers["enciv"], encryptionMode));
    } 

    // Decrypt response data
    if (response.data) {
      response.data = decryptAES(response.data);
    }

    return response;
  },
  (error) => {
    console.error("Response Error:", error);
    return Promise.reject(error);
  }
);

export default api;

import { call, put, takeLatest } from "redux-saga/effects";
import {
  onGetDealCouponCode,
  onGetDealCouponCodeActive,
  onGetDealCouponCodeActiveError,
  onGetDealCouponCodeActiveSuccess,
  onGetDealCouponCodeError,
  onGetDealCouponCodeSuccess,
  onPostDealCouponCode,
  onPostDealCouponCodeError,
  onPostDealCouponCodeSuccess,
} from "../Store/Slices/dealCouponCodeSlice";
import {
  callDealCouponCodeActiveGetApi,
  callDealCouponCodeGetApi,
  callDealCouponCodePostApi,
} from "../Context/dealCouponCodeApi";
function* GetDealCouponCode({ payload }) {
  try {
    const getDealCouponCodeResponse = yield call(
      callDealCouponCodeGetApi,
      payload
    );
    if (getDealCouponCodeResponse.responseCode === "200") {
      yield put(
        onGetDealCouponCodeSuccess({
          data: getDealCouponCodeResponse.response,
          message: getDealCouponCodeResponse.responseMessage,
          status_code: getDealCouponCodeResponse.responseCode,
        })
      );
    } else {
      yield put(
        onGetDealCouponCodeError({
          data: getDealCouponCodeResponse.response,
          message: getDealCouponCodeResponse.response.message,
          status_code: getDealCouponCodeResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(
      onGetDealCouponCodeError({ data: [], message, status_code: 400 })
    );
  }
}
function* GetDealCouponCodeActive() {
  try {
    const getDealCouponCodeActiveResponse = yield call(
      callDealCouponCodeActiveGetApi
    );
    if (getDealCouponCodeActiveResponse.responseCode === "200") {
      yield put(
        onGetDealCouponCodeActiveSuccess({
          data: getDealCouponCodeActiveResponse.response,
          message: getDealCouponCodeActiveResponse.responseMessage,
          status_code: getDealCouponCodeActiveResponse.responseCode,
        })
      );
    } else {
      yield put(
        onGetDealCouponCodeActiveError({
          data: getDealCouponCodeActiveResponse.response,
          message: getDealCouponCodeActiveResponse.response.message,
          status_code: getDealCouponCodeActiveResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message =
      error.response.data.responseMessage || "Something went wrong";
    yield put(
      onGetDealCouponCodeError({ data: [], message, status_code: 400 })
    );
  }
}
function* PostDealCouponCode({ payload }) {
  try {
    const postDealCouponCodeResponse = yield call(
      callDealCouponCodePostApi,
      payload
    );
    if (postDealCouponCodeResponse.responseCode === "200") {
      yield put(
        onPostDealCouponCodeSuccess({
          postData: postDealCouponCodeResponse.response,
          message: postDealCouponCodeResponse.responseMessage,
          status_code: postDealCouponCodeResponse.responseCode,
        })
      );
    } else {
      yield put(
        onPostDealCouponCodeError({
          data: postDealCouponCodeResponse.response,
          message: postDealCouponCodeResponse.responseMessage,
          status_code: postDealCouponCodeResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message =
      error.response.data.responseMessage || "Something went wrong";
    yield put(
      onPostDealCouponCodeError({ data: [], message, status_code: 400 })
    );
  }
}
export default function* dealCouponCodeSaga() {
  yield takeLatest(onGetDealCouponCode.type, GetDealCouponCode);
  yield takeLatest(onPostDealCouponCode.type, PostDealCouponCode);
  yield takeLatest(onGetDealCouponCodeActive.type, GetDealCouponCodeActive);
}

import React from 'react';

const WebLayout = (props) => {
    const { Component } = props;
    return (
      <>

        <Component/>
      </>
    );
}

export default WebLayout;

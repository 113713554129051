import React from 'react';
import bonusPoints from "../../Assets/imgNewUI/bonusPoint1.png";

const WebBonusPoints = () => {
    const cards = [1,2,3,4,5];
    
      return (
        <div className="bg-white py-8 px-8">
          <h2 className="w-head mb-6 px-6">
            Bonus Points, No Hidden Charges
          </h2>
          <div className="flex space-x-4 gap-2 px-6 overflow-x-scroll no-scrollbar">
            {cards.map((card) => (
              <div
                key={card.id}
                className="bg-white bonus-card overflow-hidden relative w-96 flex-shrink-0"
              >
                <div className="relative">
                  <img
                    src={bonusPoints}
                    alt={"bonuscard"}
                    className="object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="flex mt-4 space-x-2 items-center justify-center">
        <span className="w-1 h-1 bg-gray-300 rounded-full"></span>
        <span className="w-2 h-2 dot-bg rounded-full"></span>
        <span className="w-1 h-1 bg-gray-300 rounded-full"></span>
      </div>
        </div>
      );
}

export default WebBonusPoints;

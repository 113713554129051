/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Vector from "../../Assets/imgNewUI/Vector.png";
import CardModal from "../CardModal/CardModal";
import { Link, useNavigate } from "react-router-dom";
import {
  onSaveIamge,
  onSetApiError,
  onUpdatePointBalance,
} from "../../Store/Slices/utilityReducer";
import { CustomFormatNumber } from "../../Utility/Utility";
import MyIcon from "../../Assets/imgNewUI/rotate-right.js";
const CardSection = ({
  card,
  creditCards,
  debitCards,
  axiosIntegration,
  generateToken,
  API,
  partnerCode,
  clientCode,
}) => {
  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState(() => {
    const savedCard = sessionStorage.getItem("selectedCard");
    return savedCard ? JSON.parse(savedCard) : null;
  });
  const loginToken = useSelector(
    (state) => state.validateLoginTokenSliceReducer
  );
  const finalCredicards = creditCards?.filter(
    (card) => card?.identifiertype === "1002"
  );
  const finalDebitcards = creditCards?.filter(
    (card) => card?.identifiertype === "1001"
  );
  const [accountData, setAccountData] = useState([]);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiCallDone, setApiCallDone] = useState(false);
  const callSwitchAccountApi2 = async (payload) => {
    try {
      setLoading(true);
      const { data = [] } = await axiosIntegration.post(
        API.switchAccountApi,
        payload,
        {
          headers: {
            clientCode: clientCode,
            partnerCode: partnerCode,
          },
        }
      );

      setAccountData((prevData) =>
        Array.isArray(prevData) ? [...prevData, data] : [data]
      );
      dispatch(onSetApiError(false));
    } catch (error) {
      dispatch(onSetApiError(true));
    } finally {
      setLoading(false);
      sessionStorage.setItem("autoSelect", true);
    }
  };
  const callSwitchAccountApi = async (
    payload,
    cardData,
    isApiCall,
    defaultCard,
    homePage
  ) => {
    if (!generateToken?.data?.accessToken) {
      console.error("Access token is missing");
      return;
    }

    axiosIntegration.defaults.headers["access-token"] =
      generateToken.data.accessToken;

    try {
      setLoading(true);
      const { data = {} } = await axiosIntegration.post(
        API.switchAccountApi,
        payload,
        {
          headers: {
            clientCode,
            partnerCode,
          },
        }
      );

      const updatedCards = creditCards?.map((card) =>
        card.identifier === data?.response?.subIdentifier
          ? {
              ...card,
              pointBalance: data?.response?.memberBalance?.pointBalance,
              showTryAgain: false,
              showViewPoint: false,
            }
          : card
      );

      dispatch(onUpdatePointBalance({ updatedCreditCards: updatedCards }));
      if (!isApiCall) {
        dispatch(
          onSaveIamge({
            currentPoints: data?.response?.memberBalance?.pointBalance,
            currentBg: cardData?.imagelink,
            cardName: cardData?.CardTypeName,
            totalPointBalance: data?.response?.memberBalance?.totalPointBalance,
            showTryAgain: false,
            showViewPoint: false,
          })
        );
      } else if (defaultCard || homePage) {
        dispatch(
          onSaveIamge({
            currentPoints: data?.response?.memberBalance?.pointBalance,
            totalPointBalance: data?.response?.memberBalance?.totalPointBalance,
            currentBg: cardData?.imagelink,
            cardName: cardData?.CardTypeName,
            showTryAgain: false,
            showViewPoint: false,
          })
        );
        setIsOpen(false);
      }
      dispatch(onSetApiError(false));
    } catch (error) {
      console.error("Error switching account:", error);
      const updatedCards = creditCards?.map((card) =>
        card.identifier === payload?.subIdentifier
          ? {
              ...card,
              pointBalance: undefined,
              showTryAgain: true,
              showViewPoint: false,
            }
          : card
      );
      dispatch(onSetApiError(true));
      dispatch(onUpdatePointBalance({ updatedCreditCards: updatedCards }));
      if (!isApiCall) {
        dispatch(
          onSaveIamge({
            currentPoints: undefined,
            currentBg: cardData?.imagelink,
            cardName: cardData?.CardTypeName,
            totalPointBalance: cardData?.totalPointBalance,

            showTryAgain: true,
          })
        );
      }
    } finally {
      setLoading(false);
      !isApiCall && setIsOpen(false);
    }
  };

  const toggleModal = () => {
    setIsOpen((prev) => !prev);
    const apiCallFlag = sessionStorage.getItem("apiCallDone");
    if (!apiCallFlag) {
      const cardPromises = creditCards
        ?.filter((cards) => cards.pointBalance === undefined)
        .map((cardData) =>
          callSwitchAccountApi2({
            identifier: loginToken?.data?.memberIdentifier,
            subIdentifier: cardData?.identifier,
            identifierType: parseInt(cardData?.identifiertype),
            userSessionToken: sessionStorage.getItem("temporaryToken"),
            isAccountSwitch: false,
          })
        );
      Promise.all(cardPromises).then(() => {
        setApiCallDone(true);
        sessionStorage.setItem("apiCallDone", "true");
      });
    }
  };
  useEffect(() => {
    if (accountData?.length && apiCallDone) {
      const updatedCards = creditCards?.map((card) => {
        const matchingAccount = accountData?.find(
          (account) => account?.response?.subIdentifier === card.identifier
        );

        return matchingAccount
          ? {
              ...card,
              pointBalance:
                matchingAccount?.response?.memberBalance?.pointBalance,
              showTryAgain: false,
              showViewPoint: false,
              totalPointBalance:
                matchingAccount?.response?.memberBalance?.totalPointBalance,
            }
          : card;
      });
      dispatch(onUpdatePointBalance({ updatedCreditCards: updatedCards }));
      dispatch(
        onSaveIamge({
          currentBg:
            defaultCard !== null
              ? finalCredicards?.find(
                  (card) => card?.identifier === defaultCard
                )?.imagelink
              : finalCredicards.length
              ? finalCredicards[0]?.imagelink
              : finalDebitcards[0]?.imagelink,
          currentPoints:
            defaultCard !== null
              ? finalCredicards?.find(
                  (card) => card?.identifier === defaultCard
                )?.pointBalance
              : finalCredicards.length
              ? finalCredicards[0]?.pointBalance
              : finalDebitcards[0]?.pointBalance,
          cardName:
            defaultCard !== null
              ? finalCredicards?.find(
                  (card) => card?.identifier === defaultCard
                )?.CardTypeName
              : finalCredicards.length
              ? finalCredicards[0]?.CardTypeName
              : finalDebitcards[0]?.CardTypeName,
          totalPointBalance:
            defaultCard !== null
              ? finalCredicards?.find(
                  (card) => card?.identifier === defaultCard
                )?.totalPointBalance
              : finalCredicards.length
              ? finalCredicards[0]?.totalPointBalance
              : finalDebitcards[0]?.totalPointBalance,
          showTryAgain: false,
          showViewPoint: false,
        })
      );

      dispatch(onUpdatePointBalance({ updatedCreditCards: updatedCards }));
      setAccountData([]);
    }
  }, [accountData]);
  const autoSelect = sessionStorage.getItem("autoSelect");
  const [error, setError] = useState(false);
  const selectCard = async (
    index,
    type,
    card,
    isApiCall,
    defaultCard,
    homePage
  ) => {
    const cardInfo = { index, type, card };
    !isApiCall && setSelectedCard(cardInfo);
    !isApiCall &&
      sessionStorage.setItem("selectedCard", JSON.stringify(cardInfo));
    const payload = {
      identifier: loginToken?.data?.memberIdentifier,
      subIdentifier: card?.identifier,
      identifierType: parseInt(card?.identifiertype),
      userSessionToken: sessionStorage.getItem("loginSessionToken"),
      isAccountSwitch: isApiCall ? false : true,
    };
    if (autoSelect || isApiCall) {
      try {
        await callSwitchAccountApi(
          payload,
          card,
          isApiCall,
          defaultCard,
          homePage
        );
      } catch (error) {}
    }
  };
  const handleNavigate = () => {
    navigate("/reward-point");
  };

  const currenPoints = Math.floor(card?.currentPoints);
  const currenDebitRewardPoints = Math.floor(card?.totalPointBalance);
  const currentImage = card?.currentBg;
  const cardPoints = card?.cardPointsData;
  const cardName = card?.cardName;
  const defaultCard = loginToken?.data?.subIdentifier;
  useEffect(() => {
    if (creditCards?.length && !selectedCard) {
      const cardToSelect =
        defaultCard !== null
          ? creditCards.find((card) => card?.identifier === defaultCard)
          : creditCards[0];

      selectCard(
        defaultCard !== null
          ? cardToSelect?.identifier
          : cardToSelect?.identifier,
        cardToSelect?.identifierType,
        cardToSelect
      );
    }
  }, [creditCards, selectedCard, defaultCard]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  useEffect(() => {
    if (currentImage) {
      const img = new Image();
      img.src = currentImage;

      img.onload = () => setError(false);
      img.onerror = () => setError(true);
    } else {
      setError(true);
    }
  }, [currentImage]);
  const selectcard = JSON.parse(sessionStorage.getItem("selectedCard"))?.card
    ?.identifiertype;

  return (
    <>
      <div className="small-card-wrapper p-4 mt-2">
        <div className="card-container">
          <div className="card card-left">
            <div className="card-content">
              {creditCards?.length && !error ? (
                currentImage ? (
                  <img
                    className="card_img1"
                    src={currentImage}
                    alt="Card not found"
                  />
                ) : (
                  setError(true)
                )
              ) : (
                error && (
                  <span className="redeemable-points mar-none">
                    {selectcard === "1001"
                      ? "Savings Account"
                      : cardName?.length > 15
                      ? String(cardName)?.substring(0, 15)
                      : cardName}
                  </span>
                )
              )}

              {creditCards?.length && (
                <img
                  className={`card_img2 ${
                    !creditCards?.length ? "relative left-32" : ""
                  }`}
                  src={Vector}
                  alt="Vector"
                />
              )}
            </div>
            <div className="other-cards" onClick={toggleModal}>
              {creditCards?.filter((card) => card?.identifiertype === "1002")
                .length ? (
                creditCards?.length === 0 || isNaN(creditCards?.length) ? (
                  "No cards available"
                ) : creditCards?.length === 1 ? (
                  <div className="text-sm text-black-500">
                    •• {creditCards?.[0]?.CardNoLast4Digit}
                  </div>
                ) : (
                  <>
                    +{creditCards?.length - 1} Other Cards
                    <i className="fa-solid fa-angle-down ml-2"></i>
                  </>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="card card-right"
            onClick={() => {
              if (!(creditCards?.length && isNaN(parseInt(currenPoints))))
                handleNavigate();
            }}
          >
            <div className="card-content">
              <div className="card-points">
                {creditCards?.length > 0 ? (
                  !isNaN(
                    parseInt(
                      selectcard === "1001"
                        ? currenDebitRewardPoints
                        : currenPoints
                    )
                  ) ? (
                    CustomFormatNumber(
                      selectcard === "1001"
                        ? currenDebitRewardPoints
                        : currenPoints
                    )
                  ) : (
                    <span
                      className="try-again-container mb-1"
                      onClick={() =>
                        selectedCard &&
                        selectCard(
                          selectedCard.index,
                          selectedCard.type,
                          selectedCard.card,
                          true,
                          false,
                          true
                        )
                      }
                    >
                      <div className="pt-1">
                        <MyIcon color="white" />
                      </div>
                      <span className="try-again">Try Again</span>
                    </span>
                  )
                ) : (
                  0
                )}
              </div>
            </div>

            <div className="redeemable-points" onClick={handleNavigate}>
              Reward Points{" "}
              <Link onClick={handleNavigate}>
                <i
                  className="ml-1 fa-solid fa-angle-right"
                  onClick={handleNavigate}
                ></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {creditCards?.length > 1 && (
        <CardModal
          debitCards={debitCards}
          creditCards={creditCards}
          isOpen={isOpen}
          cardPoints={cardPoints}
          setIsOpen={setIsOpen}
          selectCard={selectCard}
          selectedCard={selectedCard}
          defaultCard={defaultCard}
          loading={loading}
          autoSelect={autoSelect}
          cardData={card}
        />
      )}
    </>
  );
};

export default CardSection;

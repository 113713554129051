import axiosInstanceClient from "../Common/Axios/axiosInstanceClient";
import API from "../Common/Endpoint/serviceConstrants";
export const callUnlockSectionGetApi = async (payload) => {
  const { data = {} } = await axiosInstanceClient.get(
    API.unlocksectionactivity,
    {
      headers: {
        "Customer-Identifier": payload?.memberIdentifier,
      },
    }
  );
  return data;
};
export const callUnlockSectionLinkedMasterGetApi = async () => {
  const { data = {} } = await axiosInstanceClient.get(
    API.unlocksectionactivitylinkedmasterid
  );
  return data;
};

export const callUnlockSectionPostApi = async (payload) => {
  const { data = [] } = await axiosInstanceClient.post(
    API.unlocksectionactivity,
    payload
  );
  return data;
};

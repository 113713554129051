import { createSlice } from "@reduxjs/toolkit";

export const offerNdBenefitsSlice = createSlice({
  name: "Offer-benefits",
  initialState: {
    isLoading: false,
    isError: false,
    offerBenefitData: [],
    message: "",
  },
  reducers: {
    onGetOfferBenefits: (state) => {
      return {
        ...state,
        isgetLoading: true,
        offerBenefitData: [],
        getmessage: "",
      };
    },

    onGetOfferBenefitsSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        offerBenefitData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetOfferBenefitsError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        offerBenefitData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
    onPostOfferBenefits: (state) => {
      return {
        ...state,
        isPostLoading: true,
        postOfferBenefitData: [],
      };
    },

    onPostOfferBenefitsSuccess: (state, { payload }) => {
      const { postData = [], message = "", status_code =  "200" } = payload;
      return {
        ...state,
        isPostLoading: false,
        postOfferBenefitData: postData,
        postMessage: message,
        post_status_code: status_code,
      };
    },

    onPostOfferBenefitsError: (state, { payload }) => {
      const { postData = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        postOfferBenefitData: postData,
        postMessage: message,
        post_status_code: status_code,
        isPostLoading: false,
      };
    },
    onPostOfferBenefitsReset: (state) => {
      return {
        ...state,
        postOfferBenefitData: [],
        postMessage: "",
        post_status_code: null,
      };
    },
  }
});

export const {
  onGetOfferBenefits,
  onGetOfferBenefitsSuccess,
  onGetOfferBenefitsError,
  onPostOfferBenefits,
  onPostOfferBenefitsSuccess,
  onPostOfferBenefitsReset,
  onPostOfferBenefitsError
} = offerNdBenefitsSlice.actions;

export default offerNdBenefitsSlice.reducer;

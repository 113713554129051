import React, { useRef } from "react";
import watch from "../../Assets/imgNewUI/watch.png";
import star from "../../Assets/imgNewUI/EarnStar.png";
const products = [
  {
    id: 1,
    name: "Mon men's watch",
    price: "₹9,999",
    originalPrice: "₹11,199",
    discount: "40%",
    earnPoints: 219,
  },
  {
    id: 2,
    name: "Impex Trimmer for men",
    price: "₹9,999",
    originalPrice: "₹11,199",
    discount: "40%",
    earnPoints: 219,
  },
  {
    id: 1,
    name: "Mon men's watch",
    price: "₹9,999",
    originalPrice: "₹11,199",
    discount: "40%",
    earnPoints: 219,
  },
  {
    id: 2,
    name: "Impex Trimmer for men",
    price: "₹9,999",
    originalPrice: "₹11,199",
    discount: "40%",
    earnPoints: 219,
  },
  {
    id: 1,
    name: "Mon men's watch",
    price: "₹9,999",
    originalPrice: "₹11,199",
    discount: "40%",
    earnPoints: 219,
  },
  {
    id: 2,
    name: "Impex Trimmer for men",
    price: "₹9,999",
    originalPrice: "₹11,199",
    discount: "40%",
    earnPoints: 219,
  },
];

const WebSpecialSection = () => {
  const cardGridRef = useRef(null);
  const sliderThumbRef = useRef(null);
  const sliderRef = useRef(null);

  // Handle left button click
  const handleScrollLeft = () => {
    const cardGrid = cardGridRef.current;
    cardGrid.scrollBy({
      left: -300, // Adjust the value as per your card width
      behavior: "smooth",
    });
  };

  // Handle right button click
  const handleScrollRight = () => {
    const cardGrid = cardGridRef.current;
    cardGrid.scrollBy({
      left: 300, // Adjust the value as per your card width
      behavior: "smooth",
    });
  };
  // Handle slider changes
  const handleSliderChange = (e) => {
    const slider = sliderRef.current;
    const sliderRect = slider?.getBoundingClientRect();
    const thumbWidth = sliderThumbRef.current.offsetWidth;
    const sliderWidth = slider?.offsetWidth - thumbWidth;
    let thumbLeft = e.clientX - sliderRect.left - thumbWidth / 2;

    // Constrain thumb within the slider
    if (thumbLeft < 0) thumbLeft = 0;
    if (thumbLeft > sliderWidth) thumbLeft = sliderWidth;

    // Move the slider thumb
    sliderThumbRef.current.style.left = `${thumbLeft}px`;

    // Calculate the corresponding scroll position for the card grid
    const cardGrid = cardGridRef.current;
    const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
    const scrollLeft = (thumbLeft / sliderWidth) * maxScrollLeft;

    // Scroll the card grid
    cardGrid.scrollLeft = scrollLeft;
  };
  // Update slider thumb when the card grid is scrolled
  const handleCardGridScroll = () => {
    const cardGrid = cardGridRef?.current;
    const scrollLeft = cardGrid?.scrollLeft;
    const maxScrollLeft = cardGrid?.scrollWidth - cardGrid?.clientWidth;
    const sliderWidth = sliderRef?.current?.offsetWidth;
    const thumbWidth = sliderThumbRef?.current?.offsetWidth;
    const maxThumbLeft = sliderWidth - thumbWidth;

    // Calculate the slider thumb position
    const thumbLeft = (scrollLeft / maxScrollLeft) * maxThumbLeft;
    sliderThumbRef.current.style.left = `${thumbLeft}px`;
  };
  return (
    <div className="mx-auto py-10 px-10">
      <h2 className="w-head mb-4">Fathers Day : Best Value Gifts</h2>

      <div className="relative">
        <button
          className="absolute left-[-20px] top-[35%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 flex items-center justify-center w-10 h-10"
          aria-label="Scroll Left"
          onClick={handleScrollLeft}
        >
          <i class="fa-solid fa-arrow-left"></i>
        </button>

        <div
          className="flex gap-4 overflow-x-scroll no-scrollbar"
          ref={cardGridRef}
          onScroll={handleCardGridScroll}
        >
          {products.map((product) => (
            <div key={product.id} className="flex-shrink-0">
              <div className="spe-sec">
                <span className="web-earn px-2 py-1 mb-2">
                  Earn <img src={star} alt="star" className="earn-star" />
                  {product.earnPoints}
                </span>

                <img
                  src={watch}
                  alt="watch"
                  className="w-full h-32 object-contain mb-2 p-4"
                />
              </div>
              <span className="product-name">{product.name}</span>

              <div className="text-sm mt-1">
                <span className="product-orgprice line-through mr-2">
                  {product.originalPrice}
                </span>
                <span className="product-discount">- {product.discount}</span>
              </div>

              <div className="product-price">{product.price}</div>
            </div>
          ))}
        </div>
        <button
          className="absolute right-[-20px] top-[35%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 flex items-center justify-center w-10 h-10"
          aria-label="Scroll Left"
          onClick={handleScrollRight}
        >
          <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
      {products.length > 2 && (
        <div className="slider_wrapper pt-4">
          <div
            className="slider"
            ref={sliderRef}
            onMouseMove={handleSliderChange}
          >
            <div className="slider_track"></div>
            <div className="slider_thumb" ref={sliderThumbRef}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebSpecialSection;

import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ isMobileView, sectionMasterData, sctionContentdata }) => {
  
  const sectionFilter = sectionMasterData?.find(
    (findData) => findData?.sectionType === "Footer"
  );

  const sectionContent = sctionContentdata?.filter(
    (findData) => findData?.sectionMasterId === sectionFilter?.id
  );
  const footerOptions = [
    { id: 1, label: "Terms & Condition", path: "/terms-conditions" },
    { id: 2, label: "Privacy Policy", path: "/privacy-policy" },
    { id: 3, label: "Contact Us", path: "/contact-us" },
  ];

  const handleData = (footerData) => {
    return sectionContent.find(
      (findData) => findData.contentSourceType === footerData?.label
    )?.text;
  };
  return (
    <>
      {sectionFilter ? (
        <footer className="bottom-0 left-0 w-full mt-8">
          <div className="footer-container-1 px-4">
            <div className="footer-border">
              <div
                className={`footer-nav py-4 grid ${
                  !isMobileView ? "grid-col-2" : ""
                } gap-4 md:flex`}
              >
                {footerOptions.map((data) => {
                  const content = handleData(data);
                  return content ? (
                    <Link
                      key={data.id}
                      to={data.path}
                      state={{
                        commonComponent: true,
                        Head: data.label,
                        Content: content,
                      }}
                      className="order-1 md:order-none"
                    >
                      {data.label}
                    </Link>
                  ) : null;
                })}
              </div>
            </div>
          </div>
          <div className="footer-container-mid px-4">
            <div className="footer-border">
              <div
                className={`footer-nav py-4 grid ${
                  !isMobileView ? "grid-col-2" : ""
                } gap-4 md:flex`}
              >
                <div
                  className="mid-text"
                  dangerouslySetInnerHTML={{ __html: sectionFilter.text }}
                ></div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`footer-container-2 py-4 text-center ${
                isMobileView ? "px-9" : ""
              } `}
            >
              <span className="footer-span">
                Copyright @ {new Date().getFullYear()} CustCap Solutions Private
                Limited. All rights reserved.{" "}
              </span>
            </div>
          </div>
        </footer>
      ) : (
        ""
      )}
    </>
  );
};

export default Footer;

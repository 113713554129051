import { createSlice } from "@reduxjs/toolkit";

export const themeMasterSlice = createSlice({
  name: "theme-master",
  initialState: {
    isLoading: false,
    isError: false,
    themeMasterData: [],
    message: "",
    isgetLoading: true,
  },
  reducers: {
    onGetThemeMaster: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getThemeMasterData: [],
        getmessage: "",
      };
    },

    onGetThemeMasterSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getThemeMasterData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetThemeMasterError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getThemeMasterData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
  },
});

export const {
  onGetThemeMaster,
  onGetThemeMasterSuccess,
  onGetThemeMasterError,
} = themeMasterSlice.actions;

export default themeMasterSlice.reducer;

import { call, put, takeLatest } from "redux-saga/effects";
import { onGetsectionMaster, onGetsectionMasterError, onGetsectionMasterSuccess } from "../Store/Slices/sectionMasterSlice";
import { callsectionMasterGetApi } from "../Context/sectionMasterApi";
function* GetsectionMaster({payload}) {
  try {
    const getsectionMasterResponse = yield call(callsectionMasterGetApi,payload);
    if (getsectionMasterResponse.responseCode === "200") {
      yield put(
        onGetsectionMasterSuccess({
          data: getsectionMasterResponse.response,
          message: getsectionMasterResponse.responseMessage,
          status_code:getsectionMasterResponse.responseCode
        })
      );
    } else {
      yield put(

        onGetsectionMasterError({

          data: getsectionMasterResponse.response,
          message: getsectionMasterResponse.response.message,
          status_code:getsectionMasterResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetsectionMasterError({ data: [], message, status_code: 400 }));
  }
}
export default function* sectionMasterSaga() {
  yield takeLatest(onGetsectionMaster.type, GetsectionMaster);
}

import axios from "axios";
import { decryptAES, encryptAES } from "./encrypt";

// Create Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_Gateway,
  headers: {
    "Content-Type": "application/json",
  },
});

// List of headers to encrypt
const headersToEncrypt = ["Password", "Userid", "Client-Code", "Partner-Code","clientCode","partnerCode"]; // Add the headers you want to encrypt here

// Request interceptor for encryption and logging
api.interceptors.request.use(
  (config) => {
    const isMultipart =
      config.headers["Content-Type"] &&
      config.headers["Content-Type"].includes("multipart/form-data");

    // Encrypt body data if not multipart
    if (!isMultipart && config.data) {
      const encryptedData = encryptAES(config.data);
      config.data = encryptedData;
    }

    // Encrypt only allowed headers
    if (config.headers) {
      for (const header of headersToEncrypt) {
        if (config.headers[header]) {
          config.headers[header] = encryptAES(config.headers[header]);
        }
      }
    }

    return config;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor for decryption and logging
api.interceptors.response.use(
  (response) => {
    if (response.data) {
      const decryptedData = decryptAES(response.data);
      response.data = decryptedData;
    }
    return response;
  },
  (error) => {
    const decryptedData = decryptAES(error.response.data);
    error.response.data = decryptedData;
    return Promise.reject(error);
  }
);

export default api;

import React, { useEffect, useState } from "react";
import {
  handleRedirectWithLoading,
  ModalOrSso,
  SectionContentMasterData,
} from "../../Utility/Utility";
import Slider from "react-slick";

const SupportingBanner = ({
  text,
  sectionId,
  isMobileView,
  setShowModal,
  login,
  setIsLoading,
}) => {
  const { sctionContentdata } = SectionContentMasterData();
  const supportingBanner = sctionContentdata.filter(
    (content) => content.sectionMasterId === sectionId
  );
  const isModal = ModalOrSso();

  const [centerPadding, setCenterPadding] = useState("40px");
  useEffect(() => {
    const updatePadding = () => {
      if (window.innerWidth >= 430 && window.innerWidth <= 768) {
        setCenterPadding("35px");
      } else if (window.innerWidth === 360 && window.innerWidth <= 768) {
        setCenterPadding("5px");
      } else if (window.innerWidth === 412 && window.innerWidth <= 768) {
        setCenterPadding("30px");
      } else if (window.innerWidth === 414 && window.innerWidth <= 768) {
        setCenterPadding("30px");
      } else if (window.innerWidth >= 390 && window.innerWidth <= 768) {
        setCenterPadding("12px");
      } else {
        setCenterPadding("5px");
      }
    };

    updatePadding();
    window.addEventListener("resize", updatePadding);
    return () => {
      window.removeEventListener("resize", updatePadding);
    };
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 8000,
    appendDots: (dots) => (
      <div className="carousel-dots">
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className="dot"></div>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          centerMode: true,
          centerPadding: centerPadding,
          autoplay: true,
          autoplaySpeed: 8000,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <div
      className={`all-p featured-wrapper container mx-auto px-3 ${
        isMobileView ? "" : "pt-6"
      }`}
    >
      <div className="row">
        {supportingBanner?.length
          ? text && (
              <div className="col-12">
                <h1
                  className={`home-head heading ${!isMobileView ? "ml-2" : ""}`}
                >
                  {text}
                </h1>
              </div>
            )
          : ""}
      </div>
      <div className={`row  mt-2 ${!text && !isMobileView ? "mb-12" : "mb-5"}`}>
        <div className={`banner-carousel`}>
          {window.innerWidth >= 768 ? (
            window.innerWidth >= 768 && supportingBanner?.length > 2 ? (
              <Slider {...settings}>
                {supportingBanner?.map((bannerData, index) => (
                  <div className="support-slide image-section mb-2" key={index}>
                    <img
                      className="support-wh"
                      src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${
                        isMobileView
                          ? bannerData?.mobImage
                          : bannerData?.webImage
                      }`}
                      onClick={() =>
                        bannerData.cta &&
                        handleRedirectWithLoading(
                          setIsLoading,
                          bannerData.cta,
                          login,
                          setShowModal,
                          isModal
                        )
                      }
                      alt={text}
                      title={text}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="two-banners-container">
                {supportingBanner?.map((bannerData, index) => (
                  <div className="support-slide image-section" key={index}>
                    <img
                      className="support-wh"
                      src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${bannerData?.webImage}`}
                      onClick={() =>
                        bannerData.cta &&
                        handleRedirectWithLoading(
                          setIsLoading,
                          bannerData.cta,
                          login,
                          setShowModal,
                          isModal
                        )
                      }
                      alt={text}
                      title={text}
                    />
                  </div>
                ))}
              </div>
            )
          ) : supportingBanner.length > 1 ? (
            <Slider {...settings}>
              {supportingBanner?.map((bannerData, index) => (
                <div className="support-slide image-section mb-2" key={index}>
                  <img
                    className="support-wh"
                    src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${bannerData?.mobImage}`}
                    onClick={() =>
                      bannerData.cta &&
                      handleRedirectWithLoading(
                        setIsLoading,
                        bannerData.cta,
                        login,
                        setShowModal,
                        isModal
                      )
                    }
                    alt={text}
                    title={text}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <div className="support-slide s-slide-1 image-section mb-2">
              <img
                className="support-wh"
                src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${supportingBanner?.[0]?.mobImage}`}
                onClick={() =>
                  supportingBanner?.[0].cta &&
                  handleRedirectWithLoading(
                    setIsLoading,
                    supportingBanner?.[0].cta,
                    login,
                    setShowModal,
                    isModal
                  )
                }
                alt="Banner"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupportingBanner;

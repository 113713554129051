/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ScrollToTop from "../../Utility/ScrollToTop/ScrollToTop";
import { Link, useNavigate } from "react-router-dom";
import menu1icon from "../../Assets/imgNewUI/1.svg";
import menu2icon from "../../Assets/imgNewUI/2.svg";
import offerhistory from "../../Assets/imgNewUI/offerhistory.png";
import menu3icon from "../../Assets/imgNewUI/3.png";
import HistoryMenu from "./HistoryMenu";
import Loader from "../../Utility/Loader/Loader";
import {
  ApiConfigData,
  CustomFormatNumber,
  handleRedirectWithLoading,
  ModalOrSso,
  UtilityReducer,
  VoucherHistoryReducer,
} from "../../Utility/Utility";
import { useDispatch, useSelector } from "react-redux";
import { onGetVoucherHistory } from "../../Store/Slices/voucherHistorySlice";

const RewardPoint = ({ setIsLoading, Loading, login, setShowModal }) => {
  const dispatch = useDispatch();
  const isModal = ModalOrSso();
  const loginToken = useSelector(
    (state) => state.validateLoginTokenSliceReducer?.data?.memberIdentifier
  );

  const voucherHistory = VoucherHistoryReducer();

  useEffect(() => {
    dispatch(onGetVoucherHistory({ customerId: loginToken }));
  }, []);
  const [historyMenu, setHistoryMenu] = useState(false);
  const card = UtilityReducer();
  const currenPoints = CustomFormatNumber(Math.floor(card?.currentPoints));
  const totalPointBalance = CustomFormatNumber(
    Math.floor(card?.totalPointBalance)
  );
  const apiconfig = ApiConfigData();
  const karmaRedirectionUrl = apiconfig?.find(
    (data) => data?.paramsKey === "karmaRedirectionUrl"
  )?.paramsValue;

  const navigate = useNavigate();
  const myOfferBenefit = (data) => {
    if (data) {
      navigate(`/${data}`);
    } else {
      handleRedirectWithLoading(
        setIsLoading,
        karmaRedirectionUrl,
        login,
        setShowModal,
        isModal
      );
    }
  };
  const selectcard = JSON.parse(sessionStorage.getItem("selectedCard"))?.card
    ?.identifiertype;
  return (
    <>
      <ScrollToTop />
      {voucherHistory?.isgetLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="row ">
            <div className="reward-point mobile_heading sticky top-0 z-10 bg-white">
              <h3 className="hide_desktop backIcon">
                <Link to="/" className="relative flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-8 h-6"
                  >
                    <path
                      strokeLinecap="floor"
                      strokeLinejoin="floor"
                      d="M10 19l-7-7 7-7"
                    />
                    <line
                      x1="3"
                      y1="12"
                      x2="21"
                      y2="12"
                      stroke="currentColor"
                      strokeWidth="2"
                    />
                  </svg>
                  {/* <i class="fa-solid fa-chevron-left mt-1 ml-4"></i> */}
                </Link>
              </h3>
              <h3>Reward Points</h3>
              <div></div>
            </div>

            {Loading ? (
              <Loader />
            ) : (
              <>
                <div className="reward-container">
                  {selectcard === "1002" ? (
                    <div className="total-point">
                      <h2>
                        {isNaN(parseInt(currenPoints)) ? 0 : currenPoints}
                      </h2>
                      <div className="subtitle">Available Reward Points</div>
                    </div>
                  ) : (
                    <div className="flex w-90 justify-between re-card-b p-4">
                      <div className="flex-1">
                        <span className="re-span-1 block">
                          {isNaN(parseInt(currenPoints)) ? 0 : currenPoints}
                        </span>
                        <span className="re-span-2">
                          Redeemable Reward Points
                        </span>
                      </div>

                      <div className="reward-center-b"></div>

                      <div className="text-end flex-1">
                        <span className="re-span-1 block">
                          {" "}
                          {isNaN(parseInt(totalPointBalance))
                            ? 0
                            : totalPointBalance}
                        </span>
                        <span className="re-span-2">Total Reward Points</span>
                      </div>
                    </div>
                  )}

                  <div className="reward-link">
                    <ul>
                      <li onClick={() => myOfferBenefit("order-history")}>
                        <div className="link">
                          <img src={menu1icon} alt="" /> My Offers & Benefits
                        </div>
                        <i className="fa-solid fa-angle-right mr-2"></i>
                      </li>
                      <li onClick={() => setHistoryMenu(true)}>
                        <div className="link">
                          <img src={menu2icon} alt="" /> My Orders and Bookings
                        </div>
                        <i className="fa-solid fa-angle-right mr-2"></i>{" "}
                      </li>
                      {voucherHistory?.voucherHistoryData?.length ? (
                        <li onClick={() => myOfferBenefit("voucher-history")}>
                          <div className="link">
                            <img src={offerhistory} alt="" />
                            <div className="flex flex-col  pt-1">
                              <span className="text-black">
                                Voucher History
                              </span>
                              <span className="text-sm text-gray-500">
                                Till 11th March - 2025
                              </span>
                            </div>
                          </div>
                          <i
                            className="fa-solid fa-angle-right mr-2"
                            onClick={() => myOfferBenefit("voucher-history")}
                          />
                        </li>
                      ) : (
                        ""
                      )}
                      {card?.cardName?.toLowerCase() === "first private" && (
                        <li>
                          <div
                            className="link"
                            onClick={() => myOfferBenefit()}
                          >
                            <img alt="alt-found" src={menu3icon} /> Karma Points
                          </div>
                          <i
                            className="fa-solid fa-angle-right mr-2"
                            onClick={() => myOfferBenefit()}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {historyMenu && (
        <>
          <HistoryMenu
            login={login}
            setShowModal={setShowModal}
            apiconfig={apiconfig}
            setIsLoading={setIsLoading}
            Loading={Loading}
            title={"My Orders and Bookings"}
            onClose={() => setHistoryMenu()}
          />
        </>
      )}
    </>
  );
};
export default RewardPoint;

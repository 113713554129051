import React from 'react';
import WebDownloadApp from '../WebDownloadApp/WebDownloadApp';

const WebOffers = () => {
  return (
    <>
    <WebDownloadApp/>
    </>
  )
}

export default WebOffers;

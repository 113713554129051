import { call, put, takeLatest } from "redux-saga/effects";
import { callUserCardBaseGetApi, callUserCardBaseGetHeadApi, callUserCardBasePostApi } from "../Context/userCardBaseApi";
import { onGetUserCardBase, onGetUserCardBaseError, onGetUserCardBaseHead, onGetUserCardBaseHeadError, onGetUserCardBaseHeadSuccess, onGetUserCardBaseSuccess, onPostUserCardBase, onPostUserCardBaseError, onPostUserCardBaseSuccess } from "../Store/Slices/userCardBaseSlice";

function* GetUserCardBase() {
  try {
    const getUserCardBaseResponse = yield call(callUserCardBaseGetApi);
    if (getUserCardBaseResponse.responseCode === "200") {
      yield put(
        onGetUserCardBaseSuccess({
          data: getUserCardBaseResponse.response,
          message: getUserCardBaseResponse.responseMessage,
          status_code: getUserCardBaseResponse.responseCode,
        })
      );
    } else {
      yield put(
        onGetUserCardBaseError({
          data: getUserCardBaseResponse.response,
          message: getUserCardBaseResponse.response.message,
          status_code: getUserCardBaseResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetUserCardBaseError({ data: [], message, status_code: 400 }));
  }
}

function* GetUserCardBaseHead({ payload }) {
  try {
    const getUserCardBaseResponse = yield call(callUserCardBaseGetHeadApi, payload);
    if (getUserCardBaseResponse.responseCode === "200") {
      yield put(
        onGetUserCardBaseHeadSuccess({
          data: getUserCardBaseResponse.response,
          message: getUserCardBaseResponse.responseMessage,
          status_code: getUserCardBaseResponse.responseCode,
        })
      );
    } else {
      yield put(
        onGetUserCardBaseHeadError({
          data: getUserCardBaseResponse.response,
          message: getUserCardBaseResponse.response.message,
          status_code: getUserCardBaseResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(
      onGetUserCardBaseHeadError({ data: [], message, status_code: 400 })
    );
  }
}

function* PostUserCardBase({ payload }) {
  try {
    const postUserCardBaseResponse = yield call(callUserCardBasePostApi, payload);
    if (postUserCardBaseResponse.responseCode === "200") {
      yield put(
        onPostUserCardBaseSuccess({
          postData: postUserCardBaseResponse.response,
          message: postUserCardBaseResponse.responseMessage,
          status_code: postUserCardBaseResponse.responseCode,
        })
      );
    } else {
      yield put(
        onPostUserCardBaseError({
          data: postUserCardBaseResponse.response,
          message: postUserCardBaseResponse.responseMessage,
          status_code: postUserCardBaseResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message =
      error.response.data.responseMessage || "Something went wrong";
    yield put(onPostUserCardBaseError({ data: [], message, status_code: 400 }));
  }
}
export default function* userCardBaseSaga() {
  yield takeLatest(onGetUserCardBase.type, GetUserCardBase);
  yield takeLatest(onGetUserCardBaseHead.type, GetUserCardBaseHead);
  yield takeLatest(onPostUserCardBase.type, PostUserCardBase);
}

import axiosInstanceClient from '../Common/Axios/axiosInstanceClient';
import API from '../Common/Endpoint/serviceConstrants';

export const callApiConfigGetApi = async (payload) => {
  const { data = [] } = await axiosInstanceClient.get(API.apiconfig, {
    params: {
      serviceName: payload?.serviceName
    }
  });
  return data;
};

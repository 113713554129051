/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {
  DealCouponData,
  DealCouponFreqData,
  DealData,
  SectionContentMasterData,
} from "../../Utility/Utility";
import CouponModal from "../CouponModal/CouponModal";
import Star from "../../Assets/imgNewUI/topoffer/StarT.png";
import WebTopOffer from "../../WebComponents/WebTopOffer/WebTopOffer";
import { encryptAES } from "../../Common/Axios/encrypt";

const TopOffer = ({
  sectionData,
  setIsModalOpenoffer,
  isModalOpenoffer,
  creditCards,
  isMobileView,
}) => {
  const [dealsData, setDealsData] = useState({
    dealCoupon: [],
    deal: null,
  });
  const { dealCouponFreqData } = DealCouponFreqData();
  const { sctionContentdata } = SectionContentMasterData();
  const { dealCouponData } = DealCouponData();
  const { dealData } = DealData();
  const cardGridRef = useRef(null);
  const navigate = useNavigate();
  const sliderThumbRef = useRef(null);
  const sliderRef = useRef(null);
  const filtersData = sctionContentdata?.filter(
    (filterData) => filterData?.sectionMasterId === sectionData?.id
  );

  const netFilterDealCoupons = dealCouponData?.filter((filtersData) => {
    if (filtersData.offerType === "NetworkCardType") {
      if (filtersData.offerTypeValue.toUpperCase() === "RUPAY") {
        return creditCards?.some(
          (card) =>
            card.Network.toLowerCase() ===
            filtersData.offerTypeValue.toLowerCase()
        );
      }
      return true;
    }

    if (filtersData.offerType === "SpecialType") {
      return creditCards?.some(
        (card) =>
          card.CardTypeName.toLowerCase() ===
          filtersData.offerTypeValue.toLowerCase()
      );
    }
    return true;
  });

  const dealCouponFilter = netFilterDealCoupons.filter((deal) => {
    if (deal.typeOfCoupoun === "Static" || deal.typeOfCoupoun === "NoCode") {
      return dealCouponFreqData.some(
        (freqData) => freqData.dealCoupounId === deal.id
      );
    } else if (deal.typeOfCoupoun === "Dynamic") {
      return false;
      // return dealCouponCodeData
      //   ?.filter((deal) => deal?.enabled && deal?.status === "Active")
      //   ?.some(
      //     (codeData) =>
      //       codeData.dealCoupounId === deal.id &&
      //       dealCouponFreqData?.some(
      //         (coupon) => coupon?.dealCoupounId === deal.id
      //       )
      //   );
    }
    return false;
  });

  const dealfilter = dealData
    .filter((deal) =>
      dealCouponFilter.some((freqData) => freqData.dealId === deal.id)
    )
    .sort((a, b) => {
      const aHasBankOffer = dealCouponFilter.some(
        (coupon) =>
          coupon.dealId === a.id && coupon.source.toLowerCase().includes("bank")
      );
      const bHasBankOffer = dealCouponFilter.some(
        (coupon) =>
          coupon.dealId === b.id && coupon.source.toLowerCase().includes("bank")
      );

      return bHasBankOffer - aHasBankOffer;
    })
    .filter((deal) =>
      filtersData?.some((filter) => filter.linkedMasterId === deal.id)
    );

  // update slider thumb when the card grid is scrolled
  const handleCardGridScroll = () => {
    const cardGrid = cardGridRef?.current;
    const sliderThumb = sliderThumbRef?.current;
    const slider = sliderRef?.current;

    // ensure all elements are available
    if (!cardGrid || !sliderThumb || !slider) return;

    const scrollLeft = cardGrid.scrollLeft;
    const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
    const sliderWidth = slider.offsetWidth;
    const thumbWidth = sliderThumb.offsetWidth;
    const maxThumbLeft = sliderWidth - thumbWidth;

    // calculate the slider thumb position
    const thumbLeft = (scrollLeft / maxScrollLeft) * maxThumbLeft;
    sliderThumb.style.left = `${thumbLeft}px`;
  };

  // handle slider changes
  const handleSliderChange = (e) => {
    const cardGrid = cardGridRef?.current;
    const slider = sliderRef?.current;
    const sliderThumb = sliderThumbRef?.current;

    // ensure all elements are available
    if (!cardGrid || !slider || !sliderThumb) return;

    const sliderRect = slider.getBoundingClientRect();
    const thumbWidth = sliderThumb.offsetWidth;
    const sliderWidth = slider.offsetWidth - thumbWidth;

    let thumbLeft = e.clientX - sliderRect.left - thumbWidth / 2;

    // constrain thumb within the slider
    if (thumbLeft < 0) thumbLeft = 0;
    if (thumbLeft > sliderWidth) thumbLeft = sliderWidth;

    // move the slider thumb
    sliderThumb.style.left = `${thumbLeft}px`;

    // calculate the corresponding scroll position for the card grid
    const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
    const scrollLeft = (thumbLeft / sliderWidth) * maxScrollLeft;

    // scroll the card grid
    cardGrid.scrollLeft = scrollLeft;
  };

  const handleDealCoupon = (deal) => {
    const dealCoupons = dealCouponFilter?.filter(
      (coupon) => coupon?.dealId === deal?.id
    );
    setDealsData({
      dealCoupon: dealCoupons,
      deal: deal,
    });

    if (!isModalOpenoffer) {
      setIsModalOpenoffer(true);
    }
  };
  const handleNavigate = () => {
    navigate(`/deal?stid=${encryptAES(String(sectionData?.id))}`);
  };
  return (
    <>
      {dealfilter?.length && isMobileView ? (
        <div
          id="top_offer_wrapper_container"
          className="top_offer_wrapper_container all-p top-pad container mx-auto"
        >
          <div className="top_offer_header">
            <h3 className={`${isMobileView ? "" : "ml-2"}`}>
              {sectionData?.text}
            </h3>
            {dealfilter?.length ? (
              <span onClick={handleNavigate}>View All</span>
            ) : (
              ""
            )}
          </div>
          <div
            className="top_offer_card_container1"
            ref={cardGridRef}
            onScroll={handleCardGridScroll}
          >
            <div className="offercard_maindiv ">
              {dealfilter?.map((deal, index) => {
                const matchingCoupon = dealCouponFilter.find(
                  (coupon) => coupon.dealId === deal.id && coupon.enabled
                );
                const isFeature = dealCouponFilter?.filter(
                  (dealCoupon) =>
                    dealCoupon?.dealId === deal?.id &&
                    dealCoupon?.offerType === "Feature"
                ).length;
                return (
                  <div
                    className={`offer_card top-card ${
                      isMobileView ? "top-width2" : "top-width"
                    } ${index === 0 && "ml-2"} ${
                      index === dealfilter?.length - 1 ? "mr-2" : ""
                    } `}
                    key={index}
                  >
                    <img
                      onClick={() => handleDealCoupon(deal)}
                      className="top_image"
                      src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${deal?.commonImage}`}
                      alt={matchingCoupon.title}
                      title={matchingCoupon.title}
                    />

                    {isFeature ? (
                      <div className="featured-badge mt-7">
                        <img src={Star} className="icon-sz" alt="star" />

                        <span className="text">FEATURED</span>
                      </div>
                    ) : (
                      ""
                    )}
                    <h4
                      onClick={() => handleDealCoupon(deal)}
                      className={`pl-3 font-medium ${isFeature ? "" : "pt-8"}`}
                    >
                      {deal?.name?.length > 12
                        ? deal?.name?.substring(0, 12) + ".."
                        : deal?.name}
                    </h4>

                    <div
                      className="card_content"
                      onClick={() => handleDealCoupon(deal)}
                    >
                      <p
                        className={`pt-1 pl-3 pr-1 ${
                          isFeature ? "heading" : "title-heading"
                        } `}
                      >
                        {matchingCoupon?.title}
                      </p>
                    </div>
                    <div className="card_footer pt-12">
                      <a onClick={() => handleDealCoupon(deal)}>Claim</a>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* <div className="flex space-x-4 scrollbar-hide">
              {dealfilter?.map((deal, index) => {
                const matchingCoupon = dealCouponFilter.find(
                  (coupon) => coupon.dealId === deal.id && coupon.enabled
                );

                const isFeature = dealCouponFilter?.filter(
                  (dealCoupon) =>
                    dealCoupon?.dealId === deal?.id &&
                    dealCoupon?.offerType === "Feature"
                ).length;

                return (
                    <div
                        key={index}
                        className="w-25 relative p-2 overflow-hidden"
                    >
                        <div className="relative">
                            <div className="top-offer-img">
                                <img
                                    onClick={() => handleDealCoupon(deal)}
                                    className="w-25 h-48 object-cover rounded-xl relative z-[1]"
                                    src={bgImg} // {`${process.env.REACT_APP_CLIENT_IMAGE_URL}${deal?.mobImage}`}
                                    alt={matchingCoupon?.title || "Offer Image"}
                                />
                                {matchingCoupon.discountText && (
                                    <div className="absolute top-2 left-2 top-offer px-4 z-[1]">
                                        {matchingCoupon.discountText}
                                    </div>
                                )}
                                {!isFeature > 0 && (
                                    <>
                                        <div className="absolute top-[12px] right-[-33px] overflow-visible z-[2]">
                                            <div className="featured-sz rotate-[45deg] px-7 py-1">
                                                FEATURED
                                            </div>
                                        </div>
                                        <div className="absolute z-[0] top-[-8px] right-[55px] w-[10px] h-[10px] bg-yellow-600"></div>
                                        <div className="absolute z-[0] top-[55px] right-[-8px] w-[10px] h-[10px] bg-yellow-600"></div>
                                    </>
                                )}
                                <div className="absolute bottom-0 left-0 right-0 w-full bg-gradient-to-t from-black/70 to-transparent px-3 py-2 z-[1]">
                                    <img
                                        src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${deal?.commonImage}`}
                                        alt={matchingCoupon.name}
                                        className="common-img"
                                    />
                                    <span
                                        className="block truncate cursor-pointer title-sz mt-2"
                                        onClick={() => handleDealCoupon(deal)}
                                    >
                                        {matchingCoupon?.title}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2">
                            <h4
                                onClick={() => handleDealCoupon(deal)}
                                className="font-medium cursor-pointer"
                            >
                                {deal?.name?.length > 8
                                    ? deal?.name.substring(0, 8) + ".."
                                    : deal?.name}
                            </h4>
                        </div>
                    </div>
                );
              })}
            </div> */}
          </div>
          {dealfilter.length > (isMobileView ? 2 : 4) && (
            <div className="slider_wrapper pt-4">
              <div
                className="slider"
                ref={sliderRef}
                onMouseMove={handleSliderChange}
              >
                <div className="slider_track"></div>
                <div className="slider_thumb" ref={sliderThumbRef}></div>
              </div>
            </div>
          )}
        </div>
      ) : dealfilter?.length && !isMobileView ? (
        <WebTopOffer
          sectionData={sectionData}
          text={sectionData?.text}
          dealfilter={dealfilter}
          dealCouponFilter={dealCouponFilter}
        />
      ) : (
        ""
      )}

      {isModalOpenoffer && dealsData?.dealCoupon?.length > 0 && (
        <CouponModal
          onCouponClose={() => setIsModalOpenoffer(false)}
          couponData={dealsData?.dealCoupon}
          dealImage={dealsData?.deal}
          isMobileView={isMobileView}
        />
      )}
    </>
  );
};

export default TopOffer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SuccessStar from "../../Assets/imgNewUI/SuceesStar.png";
import CheckMark from "../../Assets/imgNewUI/CheckMark.png";
import copyIcon from "../../Assets/img/copy_Image.png";
import { useNavigate } from "react-router-dom";
import { decryptAES } from "../../Common/Axios/encrypt";
import { useDispatch } from "react-redux";
import { onPostDealCodeReset } from "../../Store/Slices/dealCouponCodeSlice";

const BonusRedeem = ({ dealCoupon, reDirect }) => {
  const disptach = useDispatch();
  const [copied, setCopied] = useState(null);

  const handleCopy = (coupon) => {
    if (coupon) {
      navigator.clipboard.writeText(decryptAES(coupon));
      setCopied(coupon.id);
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setCopied(null);
        document.body.style.overflow = "";
      }, 3000);
    }
  };

  const navigate = useNavigate();
  const handleButtonClick = (coupon) => {
    disptach(onPostDealCodeReset());
    navigate(coupon ? "/" : -1);
  };

  // Prevent background scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className="modal-overlay">
      <div className="modal-container-1">
        <div className="success-icon">
          <img className="green-badge" src={SuccessStar} alt="Success" />
          <img className="checkmark" src={CheckMark} alt="checkMark" />
        </div>
        <h2 className="modal-title">CONGRATULATIONS</h2>
        <p className="modal-message">
          Your Welcome Bonus Coupon Has Been Successfully Redeemed!
        </p>
        <div className="coupon-details-container relative">
          {copied === dealCoupon?.id && (
            <div className="copied-message bg-green-500">
              Copied successfully!
            </div>
          )}
          <div className="coupon-details">
            <div className="coupon-row">
              <span className="span-sec">Offer Code</span>
              <span className="copy-section">
                {decryptAES(dealCoupon)}
                <button onClick={() => handleCopy(dealCoupon)}>
                  <img src={copyIcon} alt="Copy Icon" className="h-5 w-5" />
                </button>
              </span>
            </div>
          </div>
        </div>
        <button
          className="ok-button"
          onClick={() => handleButtonClick(reDirect)}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default BonusRedeem;

import React, { useEffect } from "react";
import mobile from "../../Assets/imgNewUI/nonloginbannermob.png";
import web from "../../Assets/imgNewUI/nonloginbanner.png";
const AppDownloadModal = ({
  setShowModal,
  isMobileView,
  sectionMasterData,
  sctionContentdata,
}) => {
  const sectionFilter = sectionMasterData?.find(
    (findData) => findData?.sectionType === "Non Login Configuration"
  );
  const sectionContent = sctionContentdata?.find(
    (findData) => findData?.sectionMasterId === sectionFilter?.id
  );

  useEffect(() => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    };
  }, []);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-30 flex justify-center"
      onClick={() => setShowModal(false)}
    >
      <div className="download-modal relative">
        <div className="cursor-pointer relative">
          <img
            className="w-full"
            onClick={
              isMobileView && sectionContent?.cta
                ? () => window.open(sectionContent?.cta, "_blank")
                : undefined
            }
            src={
              sectionFilter
                ? `${process.env.REACT_APP_CLIENT_IMAGE_URL}${
                    isMobileView
                      ? sectionContent?.mobImage
                      : sectionContent?.webImage
                  }`
                : isMobileView
                ? mobile
                : web
            }
            alt="not-found"
          />
          <i
            className="fa-solid fa-xmark absolute right-5 top-3 modal-cross"
            onClick={() => setShowModal(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default AppDownloadModal;

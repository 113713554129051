import React from 'react';
import { Link } from 'react-router-dom';
import TripBanner from "../../Assets/imgNewUI/TripBanner.png";

const WebTripSection = () => {
  return (
    <div className="my-10">
        <Link to="">
        <img src={TripBanner} alt="TripBanner"/>
        </Link>
    </div>
  )
}

export default WebTripSection;

import { createSlice } from "@reduxjs/toolkit";

export const SwitchAccountSlice = createSlice({
  name: "SwitchAccount",
  initialState: {
    isLoading: false,
    isError: false,
    data: [],
    error: [],
    message: "",
  },
  reducers: {
    onSwitchAccountSubmit: (state) => {
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: [],
        message: "",
      };
    },

    onSwitchAccountSuccess: (state, { payload

    }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: [...state.data, data],
        message,
        status_code
      };
    },

    onSwitchAccountError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        data,
        message,
        status_code,
        isLoading: false,
        isError: true,
      };
    },
    onSwitchAccountReset: (state) => {
      return {
        ...state,
        message:"",
        status_code:null,
        isLoading: false,
        isError: false,
      };
    },
  },
});
export const { onSwitchAccountSubmit, onSwitchAccountSuccess, onSwitchAccountError, onSwitchAccountReset } =
SwitchAccountSlice.actions;

export default SwitchAccountSlice.reducer;

/* eslint-disable use-isnan */
import React, { useRef } from "react";

import {
  CustomFormatNumber,
  handleRedirectWithLoading,
  ModalOrSso,
  ProductContentData,
  SectionContentMasterData,
  UtilityReducer,
} from "../../Utility/Utility";
import GiftCard from "../GiftCard/GiftCard";
import { Link } from "react-router-dom";

const SpecialSection = ({
  sectionData,
  login,
  setShowModal,
  setIsLoading,
  isMobileView,
}) => {
  const card = UtilityReducer();
  const isModal = ModalOrSso();

  const currentPoints = Number(card?.currentPoints);
  const { productContentData } = ProductContentData();
  const { sctionContentdata } = SectionContentMasterData();
  const filtersData = sctionContentdata?.filter(
    (filterData) => filterData?.sectionMasterId === sectionData?.id
  );

  const filteredProducts = filtersData
    .map(
      (filterData) =>
        Array.isArray(productContentData) &&
        productContentData.filter(
          (product) => product.productId === filterData.linkedMasterId
        )
    )
    .flat();

  const calculateDiscountPercentage = (price, mrp) => {
    let discountPercentage = -((mrp - price) / mrp) * 100;
    return Math.floor(discountPercentage);
  };

  const cardGridRef = useRef(null);
  const sliderThumbRef = useRef(null);
  const sliderRef = useRef(null);

  // Update slider thumb when the card grid is scrolled
  const handleCardGridScroll = () => {
    if (sliderThumbRef.current && sliderRef.current && cardGridRef.current) {
      const cardGrid = cardGridRef.current;
      const scrollLeft = cardGrid.scrollLeft;
      const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
      const sliderWidth = sliderRef.current.offsetWidth;
      const thumbWidth = sliderThumbRef.current.offsetWidth;
      const maxThumbLeft = sliderWidth - thumbWidth;

      // Calculate the slider thumb position
      const thumbLeft = (scrollLeft / maxScrollLeft) * maxThumbLeft;
      sliderThumbRef.current.style.left = `${thumbLeft}px`;
    }
  };

  // Handle slider changes
  const handleSliderChange = (e) => {
    if (sliderRef.current && sliderThumbRef.current && cardGridRef.current) {
      const slider = sliderRef.current;
      const sliderRect = slider.getBoundingClientRect();
      const thumbWidth = sliderThumbRef.current.offsetWidth;
      const sliderWidth = slider.offsetWidth - thumbWidth;
      let thumbLeft = e.clientX - sliderRect.left - thumbWidth / 2;

      // Constrain thumb within the slider
      if (thumbLeft < 0) thumbLeft = 0;
      if (thumbLeft > sliderWidth) thumbLeft = sliderWidth;

      // Move the slider thumb
      sliderThumbRef.current.style.left = `${thumbLeft}px`;

      // Calculate the corresponding scroll position for the card grid
      const cardGrid = cardGridRef.current;
      const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
      const scrollLeft = (thumbLeft / sliderWidth) * maxScrollLeft;

      // Scroll the card grid
      cardGrid.scrollLeft = scrollLeft;
    }
  };
  const formatProductName = (name) => {
    if (name === name.toUpperCase()) {
      return name
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return name;
  };
  // for future scope
  // const cashValue = (currentPoints, spendPoints, pprice) => {
  //   const remaingPoint = parseInt(spendPoints) - parseInt(currentPoints);
  //   const remaing = (remaingPoint / parseInt(spendPoints)).toFixed(3);
  //   const price = ((remaing * 100.0) / 100.0) * pprice;
  //   return Math.floor(price);
  // };
  return (
    <>
      <div
        className={`all-p ${
          filteredProducts?.length ? "best_seller_wrapper " : ""
        } container mx-auto  ${isMobileView ? "" : "mt-12"}`}
      >
        {filteredProducts?.length ? (
          <>
            {sectionData?.text && (
              <div className={`view-all-sp ${!isMobileView ? "mt-5" : "mt-3"}`}>
                <h3 className="home-head ml-sp px-3">{sectionData?.text}</h3>
                {!isMobileView && filteredProducts?.length > 6 && (
                  <Link
                    onClick={() => setShowModal(true)}
                    className="view-a pt-1"
                  >
                    View All
                  </Link>
                )}
              </div>
            )}
          </>
        ) : (
          ""
        )}

        <div
          className={`${
            filteredProducts?.length ? "best_seller_card_grid mt-5" : ""
          }`}
          ref={cardGridRef}
          onScroll={handleCardGridScroll}
        >
          {/* Map and display products */}
          {filteredProducts
            .filter((product) => product?.productType === "Products")
            .map((product, index) => (
              <div
                key={index}
                className={`p-2 mb-1 special-card ml-sp ${
                  index === 0 ? "ml-3" : "ml-3"
                } ${index === filteredProducts?.length - 1 ? "mr-2" : ""}`}
              >
                {/* Earn Section */}
                <div className="flex justify-center items-center earn-bg w-fit mx-auto">
                  <span>Earn</span>
                  <span className="ml-1">{product?.earnPoints} Pts</span>
                </div>

                {/* Product Image */}
                <div className="flex justify-center image2 ">
                  <img
                    onClick={() =>
                      handleRedirectWithLoading(
                        setIsLoading,
                        product?.productUrl,
                        login,
                        setShowModal,
                        isModal
                      )
                    }
                    src={product?.image}
                    alt={product?.name}
                    title={product?.name}
                    className=""
                  />
                </div>

                <h3
                  className=" text-start titl-colr"
                  onClick={() =>
                    handleRedirectWithLoading(
                      setIsLoading,
                      product?.productUrl,
                      login,
                      setShowModal,
                      isModal
                    )
                  }
                >
                  {product?.name.length > 32
                    ? formatProductName(product?.name).substring(0, 32) + "..."
                    : formatProductName(product?.name)}
                </h3>
                <div className="text-center">
                  <div className="flex justify-between items-center relative top-3.5 w-full">
                    <div className="flex gap-1 pb-2">
                      <span className="block discount_percent">
                        {calculateDiscountPercentage(
                          product?.price,
                          product.mrp
                        ) !== 0
                          ? calculateDiscountPercentage(
                              product?.price,
                              product.mrp
                            ) + "%"
                          : "MRP"}
                      </span>
                      <span className="pro-price">
                        {"₹" + CustomFormatNumber(Math.floor(product?.price))}/-
                      </span>
                    </div>

                    <div className="flex justify-end">
                      <button
                        onClick={() =>
                          handleRedirectWithLoading(
                            setIsLoading,
                            product?.productUrl,
                            login,
                            setShowModal,
                            isModal
                          )
                        }
                        className="py-2 buy-btn ml-2"
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>

                {calculateDiscountPercentage(product?.price, product.mrp) !==
                  0 && (
                  <span className="discount-price">
                    MRP
                    <span className="line-through">
                      {" "}
                      ₹{CustomFormatNumber(Math.floor(product?.mrp))}
                    </span>
                  </span>
                )}
                {login && (
                  <div
                    className={`text-start er-pt relative ${
                      calculateDiscountPercentage(
                        product?.price,
                        product.mrp
                      ) === 0
                        ? "top-6"
                        : ""
                    } `}
                  >
                    {/* {currentPoints !== 0 && !isNaN(currentPoints) ? (
                      currentPoints >= Math.floor(product.spendPoints) ? (
                        <span>
                          <span className="mr-1.5">Or</span>
                          {CustomFormatNumber(
                            Math.floor(product.spendPoints)
                          )}{" "}
                          Pts
                        </span>
                      ) : (
                        <span>
                          <span className="or-text mr-2">Or</span>
                          {CustomFormatNumber(Math.floor(currentPoints))} Pts +
                          ₹{" "}
                          {cashValue(
                            currentPoints,
                            product.spendPoints,
                            product?.price
                          )}
                        </span>
                      )
                    ) : (
                      ""
                    )} */}
                  </div>
                )}
              </div>
            ))}

          {filteredProducts
            .filter((product) => product?.productType === "Gift Cards")
            .map((giftCardProduct, index) => (
              <GiftCard
                key={index}
                product={giftCardProduct}
                currentPoints={currentPoints}
              />
            ))}
        </div>

        {filteredProducts?.length > (isMobileView ? 2 : 6) && (
          <div className="slider_wrapper mt-4 mb-1">
            <div
              className="slider"
              ref={sliderRef}
              onMouseMove={handleSliderChange}
            >
              <div className="slider_track"></div>
              <div className="slider_thumb" ref={sliderThumbRef}></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SpecialSection;

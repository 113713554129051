import React from "react";

const NoRecord = ({ filteredDeals }) => {
  return (
    <div
      className={`flex flex-col items-center min-h-screen bg-white  ${
        filteredDeals?.length ? "justify-center" : ""
      }`}
    >
      {/* Icon section */}
      <div className="text-center">
        <div className="flex items-center justify-center mb-4">
          {/* Neutral face icon */}
          <svg
            className="w-20 h-20 text-teal-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
            <circle cx="9" cy="10" r="1.5" fill="currentColor" />
            <circle cx="15" cy="10" r="1.5" fill="currentColor" />
            <path
              d="M8 15c1.5 1 3 1 4 0 1-1 3-1 4 0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>

        {/* Text section */}
        <h1 className="text-2xl font-semibold text-gray-700">No Data Found</h1>
        <p className="text-gray-500 mt-2">
          There are currently no items to display. Please check again later.
        </p>
      </div>
    </div>
  );
};

export default NoRecord;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import AppDownloadModal from "../../Components/GetApp/AppDownloadModal";
import { onGetNavConfigure } from "../../Store/Slices/NavConfigurationSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import {
  DealCategoryData,
  DealCouponCodeData,
  DealCouponData,
  DealCouponFreqData,
  DealData,
  ModalOrSso,
  SectionContentMasterData,
  SectionMasterData,
} from "../Utility";
import GetApp from "../../Components/GetApp/GetApp";
import Footer from "../../Components/Footer/Footer";

const Layout = (props) => {
  const {
    Component,
    showModal,
    setShowModal,
    Loading,
    setIsLoading,
    setErrorApi,
    errorApi,
    ...rest
  } = props;
  const login = sessionStorage.getItem("login");
  const isModal = ModalOrSso();
  const { isSectionLoading, sectionMasterData } = SectionMasterData(login);
  const { sctionContentdata } = SectionContentMasterData();
  const dispatch = useDispatch();
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const { isDealCouponLoading } = DealCouponData();
  const { isDealCategoryLoading } = DealCategoryData();
  const { isDealFreqLoading } = DealCouponFreqData();
  const { isDealCouponCodeLoading } = DealCouponCodeData();
  const { isDealLoading } = DealData();

  // State to track the screen width
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  // Function to update the view based on screen size
  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 769);
  };

  useEffect(() => {
    if (loginAuthData?.status_code === "200") {
      dispatch(onGetNavConfigure());
    }
  }, []);

  useEffect(() => {
    if (!login && location.pathname === "/order-history") {
      navigate("/");
    }
  }, [login]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Header
        path={location.pathname}
        isMobileView={isMobileView}
        setShowModal={setShowModal}
        setIsLoading={setIsLoading}
      />
      {!login && isModal && isMobileView && location.pathname === "/" && (
        <GetApp
          setShowModal={setShowModal}
          sectionMasterData={sectionMasterData}
          sctionContentdata={sctionContentdata}
        />
      )}
      <div>

        {!Loading && (
          <>
            <div className="component min-h-[100vh]">
              <Component
                {...rest}
                errorApi={errorApi}
                setErrorApi={setErrorApi}
                setShowModal={setShowModal}
                setIsLoading={setIsLoading}
                Loading={Loading}
                isMobileView={isMobileView}
              />
            </div>
          </>
        )}
        {(Loading || isSectionLoading) && <Loader />}
        {!isSectionLoading && showModal && (
          <AppDownloadModal
            sectionMasterData={sectionMasterData}
            sctionContentdata={sctionContentdata}
            setShowModal={setShowModal}
            isMobileView={isMobileView}
          />
        )}
      </div>

      {!Loading &&
        !isDealLoading &&
        !isDealCategoryLoading &&
        !isDealCouponLoading &&
        !isDealFreqLoading &&
        !isDealCouponCodeLoading &&
        location.pathname !== "/side-navbar" && (
          <Footer
            isMobileView={isMobileView}
            sectionMasterData={sectionMasterData}
            sctionContentdata={sctionContentdata}
          />
        )}
    </>
  );
};

export default Layout;

import { call, put, takeLatest } from "redux-saga/effects";
import { onGetDealCategory, onGetDealCategoryError, onGetDealCategorySuccess } from "../Store/Slices/dealCategorySlice";
import { callDealCategoryGetApi } from "../Context/dealCategoryApi";
function* GetDealCategory() {
  try {
    const getDealCategoryResponse = yield call(callDealCategoryGetApi);
    if (getDealCategoryResponse.responseCode === "200") {
      yield put(
        onGetDealCategorySuccess({
          data: getDealCategoryResponse.response,
          message: getDealCategoryResponse.responseMessage,
          status_code:getDealCategoryResponse.responseCode
        })
      );
    } else {
      yield put(
        onGetDealCategoryError({
          data: getDealCategoryResponse.response,
          message: getDealCategoryResponse.response.message,
          status_code:getDealCategoryResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetDealCategoryError({ data: [], message, status_code: 400 }));
  }
}
export default function* dealCategorySaga() {
  yield takeLatest(onGetDealCategory.type, GetDealCategory);
}

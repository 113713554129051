import API from "../Common/Endpoint/serviceConstrants";
import axiosIntegration from "../Common/Axios/axiosIntegration";
export const validateLoginTokenApi = async (payload) => {
    const { data = {} } = await axiosIntegration.post(API.validate_login_token, payload,{
      headers:{
        clientCode:payload?.clientCode,
        partnerCode:payload?.partnerCode
      }
    });
  return data;
};

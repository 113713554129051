import React, { useState } from "react";

const GetApp = ({ setShowModal, sectionMasterData, sctionContentdata }) => {
  const [isVisible, setIsVisible] = useState(true);
  const sectionFilter = sectionMasterData?.find(
    (findData) => findData?.sectionType === "Get App Configuration"
  );
  const sectionContent = sctionContentdata?.find(
    (findData) => findData?.sectionMasterId === sectionFilter?.id
  );

  if (!isVisible) return null;
  return (
    <>
      {sectionContent ? (
        <div className="flex items-center justify-between bg-white p-4 rounded-md">
          <div className="flex items-center gap-4">
            <button
              className="w-6 h-6 flex items-center justify-center cross-btn"
              onClick={() => setIsVisible(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-4 h-4 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="flex items-center gap-2">
              <img
                src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${sectionContent?.mobImage}`}
                alt="IDFC Logo"
                className="h-10 w-10"
              />
              <div
                dangerouslySetInnerHTML={{ __html: sectionContent?.text }}
              ></div>
            </div>
          </div>

          <div>
            <button
              className="get-app-box px-4 py-3"
              onClick={() => setShowModal(true)}
            >
              {sectionContent?.textForElement}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default GetApp;

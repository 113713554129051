import { createSlice } from "@reduxjs/toolkit";

export const userCardBaseSlice = createSlice({
  name: "calim-bonus",
  initialState: {
    isLoading: false,
    isError: false,
    userCardBaseData: [],
    message: "",
  },
  reducers: {
    onGetUserCardBase: (state) => {
      return {
        ...state,
        isgetLoading: true,
        userCardBaseData: [],
        getmessage: "",
      };
    },

    onGetUserCardBaseSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        userCardBaseData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetUserCardBaseError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        userCardBaseData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
    onGetUserCardBaseHead: (state) => {
      return {
        ...state,
        isgetLoading: true,
        userCardBaseData: [],
        getmessage: "",
      };
    },

    onGetUserCardBaseHeadSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        userCardBaseData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetUserCardBaseHeadError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        userCardBaseData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
    onPostUserCardBase: (state) => {
      return {
        ...state,
        isPostLoading: true,
        userCardBaseData: [],
      };
    },

    onPostUserCardBaseSuccess: (state, { payload }) => {
      const { postData = [], message = "", status_code =  "200" } = payload;
      return {
        ...state,
        isPostLoading: false,
        userCardBaseData: postData,
        postMessage: message,
        post_status_code: status_code,
      };
    },

    onPostUserCardBaseError: (state, { payload }) => {
      const { postData = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        userCardBaseData: postData,
        postMessage: message,
        post_status_code: status_code,
        isPostLoading: false,
      };
    },
    onPostUserCardBaseReset: (state) => {
      return {
        ...state,
        userCardBaseData: [],
        postMessage: "",
        post_status_code: null,
      };
    },
  }
});

export const {
  onGetUserCardBase,
  onGetUserCardBaseSuccess,
  onGetUserCardBaseError,
  onGetUserCardBaseHead,
  onGetUserCardBaseHeadSuccess,
  onGetUserCardBaseHeadError,
  onPostUserCardBase,
  onPostUserCardBaseSuccess,
  onPostUserCardBaseError,
  onPostUserCardBaseReset
} = userCardBaseSlice.actions;

export default userCardBaseSlice.reducer;

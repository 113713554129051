const MyIcon = ({ color = "red" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.92664 3.38628C9.34664 3.21294 8.70664 3.09961 7.99997 3.09961C4.80664 3.09961 2.21997 5.68628 2.21997 8.87961C2.21997 12.0796 4.80664 14.6663 7.99997 14.6663C11.1933 14.6663 13.78 12.0796 13.78 8.88628C13.78 7.69961 13.42 6.59294 12.8066 5.67294"
      stroke={color}
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7533 3.54634L8.82666 1.33301"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7534 3.54688L8.50671 5.18687"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default MyIcon;

import React, { useEffect, useState } from "react";
import { SectionContentMasterData, ValidateLoginToken } from "../../Utility/Utility";
import { useDispatch, useSelector } from "react-redux";
import {
  onGetMemberData,
  onPostMember,
  onPostMemberReset,
} from "../../Store/Slices/getMemberSlice";

const PromoMessage = ({ sectionId, login }) => {
  const memberDataReducer = useSelector((state) => state.getMemberSliceReducer);
  const memberData = memberDataReducer?.MemberData?.memberProfile;
  const showMessage = memberDataReducer?.getMemberData;
  const token = ValidateLoginToken();

  const [visibilityState, setVisibilityState] = useState({});
  const dispatch = useDispatch();
  const { sctionContentdata } = SectionContentMasterData();

  const promoMessage = sctionContentdata?.filter(
    (content) => content.sectionMasterId === sectionId
  );

  const handleClose = (data) => {
    setVisibilityState((prevState) => ({
      ...prevState,
      [data.id]: false,
    }));

    const payload = {
      enabled: true,
      deleted: false,
      createdBy: 23,
      memberId:token?.memberIdentifier,
      firstName: memberData?.firstName,
      lastName: memberData?.lastName,
      emailId: memberData?.emailId,
      phoneNumber: memberData?.mobileNumber,
      dob: memberData?.dob,
      gender: memberData?.gender === 1 ? "Male" : "Female",
      sectionMasterIds: showMessage?.length
        ? [...showMessage[0]?.sectionMasterIds, data?.sectionMasterId]
        : [data?.sectionMasterId],
      sectionContentMasterIds: showMessage?.length
        ? [...showMessage[0]?.sectionContentMasterIds, data?.id]
        : [data?.id],
      ...(showMessage?.length && { id: showMessage?.[0]?.id }),
      ...(showMessage?.length && { updatedBy: showMessage?.[0]?.id }),
    };

    if (login) {
      dispatch(onPostMember(payload));
    }
  };

  useEffect(() => {
    if (memberDataReducer?.post_status_code === "200") {
      dispatch(onPostMemberReset());
      dispatch(onGetMemberData({ memberId: token?.memberIdentifier }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberDataReducer, memberData?.mobileNumber]);

  const filteredArray = showMessage
    ? promoMessage?.filter((item) => {
        return !showMessage?.some(
          (user) =>
            user.sectionMasterIds?.includes(item.sectionMasterId) &&
            user.sectionContentMasterIds?.includes(item.id)
        );
      })
    : promoMessage;

  return (
    <>
      {filteredArray?.map(
        (message, index) =>
          visibilityState[message.id] !== false && (
            <div
              key={index}
              className={`text-white ${
                filteredArray.length ? "p-3" : ""
              } relative bg-gradient`}
            >
              <div className="absolute top-0 right-2">
                <button
                  className="text-3xl p-1 font-thin"
                  onClick={() => handleClose(message)}
                >
                  ×
                </button>
              </div>
              <div
                className="promo-content"
                dangerouslySetInnerHTML={{ __html: message?.text }}
              ></div>
            </div>
          )
      )}
    </>
  );
};

export default PromoMessage;

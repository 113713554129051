import axiosInstanceClient from "../Common/Axios/axiosInstanceClient";
import API from "../Common/Endpoint/serviceConstrants";
export const callVoucherHistoryGetApi = async (payload) => {

  const { data = {} } = await axiosInstanceClient.get(API.poshvinedata, {
    headers: {
      "Customer-Id": payload?.customerId,
    },
  });
  return data;
};

import API from "../Common/Endpoint/serviceConstrants";
import axiosService from "../Common/Axios/axiosService";
export const callSegmentApi = async (payload) => {
  const { data = {} } = await axiosService.get(API.segmentApi, {
    params: {
      memberIdentifier: payload?.loginToken,
    },
    headers: {
      clientCode: payload?.clientCode,
      partnerCode: payload?.partnerCode,
    },
  });
  return data;
};

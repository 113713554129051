// src/hooks/useClientId.js
import { useSelector } from "react-redux";
import { onGetsectionMaster } from "../Store/Slices/sectionMasterSlice";
import { onGetSectionContentMaster } from "../Store/Slices/sectionContentMasterSlice";
import { onGetDeal } from "../Store/Slices/dealSlice";
import { onGetDealCouponFreq } from "../Store/Slices/dealCouponFreqSlice";
import { onGetDealCoupon } from "../Store/Slices/dealCouponSlice";
import { onGetDealCategory } from "../Store/Slices/dealCategorySlice";

const ClientId = () => {
  return useSelector(
    (state) =>
      state.clientMasterReducer?.clientMasterData?.[0]?.clientId || null
  );
};
const ModalOrSso = () => {
  return useSelector(
    (state) => state.clientMasterReducer?.clientMasterData?.[0]?.isModal
  );
};

const UserId = () => {
  return useSelector((state) => state.loginReducer?.data?.[0]?.userId || null);
};

const SectionMasterData = (login) => {
  return useSelector((state) => {
    const data = state?.sectionMasterReducer?.getsectionMasterData || [];
    const isSectionLoading = state?.sectionMasterReducer?.isgetLoading;
    return {
      sectionMasterData: data
        .filter((filterData) => filterData?.enabled)
        .sort((a, b) =>
          !login
            ? a.nonLoginDisplayOrder - b.nonLoginDisplayOrder
            : a.displayOrder - b.displayOrder
        ),
      isSectionLoading,
    };
  });
};

const SectionContentMasterData = () => {
  return useSelector((state) => {
    const data =
      state?.sectionContentMasterReducer?.getSectionContentMasterData || [];
    const isLoading = state?.sectionContentMasterReducer?.isgetLoading;
    return {
      sctionContentdata: data
        .filter((filterData) => filterData?.enabled)
        .sort((a, b) => a.displayOrder - b.displayOrder),
      isLoading,
    };
  });
};
const ProductContentData = () => {
  return useSelector((state) => {
    const data = state?.productContentReducer?.data || [];
    const isProductLoading = state?.productContentReducer?.isLoading;
    return {
      productContentData: data,
      isProductLoading,
    };
  });
};

const DealCategoryData = () => {
  return useSelector((state) => {
    const data = state?.dealCategoryReducer?.getDealCategoryData || [];
    const isDealCategoryLoading = state?.dealCategoryReducer?.isgetLoading;
    return {
      dealCategoryData: data
        .filter((filterData) => filterData?.enabled)
        .sort((a, b) => a.displayOrder - b.displayOrder),
      isDealCategoryLoading,
    };
  });
};

const DealData = () => {
  return useSelector((state) => {
    const data = state?.dealReducer?.getDealData || [];
    const isDealLoading = state?.dealReducer?.isgetLoading;
    return {
      dealData: data
        .filter((filterData) => filterData?.enabled)
        .sort((a, b) => a.displayOrder - b.displayOrder),
      isDealLoading,
    };
  });
};

const DealCouponData = () => {
  return useSelector((state) => {
    const data = state?.dealCouponReducer?.getDealCouponData || [];
    const isDealCouponLoading = state?.dealCouponReducer?.isgetLoading;
    return {
      dealCouponData: data.filter((filterData) => filterData?.enabled),
      isDealCouponLoading,
    };
  });
};

const DealCouponFreqData = () => {
  return useSelector((state) => {
    const data = state?.dealCouponFreqReducer?.getDealCouponFreqData || [];
    const isDealFreqLoading = state?.dealCouponFreqReducer?.isgetLoading;
    return {
      dealCouponFreqData: data.filter((filterData) => filterData?.enabled),
      isDealFreqLoading,
    };
  });
};

const DealCouponCodeData = () => {
  return useSelector((state) => {
    const data = state?.dealCouponCodeReducer?.getDealCouponCodeData || [];
    const isDealCouponCodeLoading = state?.dealCouponCodeReducer?.isgetLoading;
    const isDealCouponCodePostLoading =
      state?.dealCouponCodeReducer?.isPostLoading;

    return {
      dealCouponCodeData: data.filter((filterData) => filterData?.enabled),
      isDealCouponCodeLoading,
      isDealCouponCodePostLoading,
    };
  });
};

const GetMemberData = () => {
  return useSelector((state) => state.getMemberSliceReducer);
};
const GetSegment = () => {
  return useSelector((state) => state.SegmentSliceReducer);
};
const GetSwitchAccount = () => {
  return useSelector((state) => state.switchAccountSliceReducer);
};
const PointPerPrice = () => {
  return useSelector(
    (state) => state.clientMasterReducer?.clientMasterData?.[0]?.pointPerRs
  );
};
const AppTitle = () => {
  return useSelector(
    (state) => state.clientMasterReducer?.clientMasterData?.[0]?.title
  );
};
const AppLogo = () => {
  return useSelector(
    (state) => state.clientMasterReducer?.clientMasterData?.[0]?.logoUrl
  );
};
const NavConfigureData = () => {
  return useSelector((state) => {
    const data = state?.navConfigurationReducer?.getNavConfigureData || [];
    const isNavConfigureLoading = state?.navConfigurationReducer?.isgetLoading;
    return {
      navConfigureData: data
        .filter((filterData) => filterData?.enabled)
        .sort((a, b) => a.displayOrder - b.displayOrder),
      isNavConfigureLoading,
    };
  });
};
const ApiConfigData = () => {
  return useSelector((state) => state?.apiConfigReducer?.getApiConfigData);
};
const ApiConfigDataLoading = () => {
  return useSelector((state) => state?.apiConfigReducer?.isgetLoading);
};
const ThemeData = () => {
  return useSelector(
    (state) => state?.themeMasterReducer?.getThemeMasterData?.[0]
  );
};
const ThemeDataLoading = () => {
  return useSelector((state) => state?.themeMasterReducer?.isgetLoading);
};
const ValidateLoginToken = () => {
  return useSelector((state) => state?.validateLoginTokenSliceReducer?.data);
};
const UtilityReducer = () => {
  return useSelector((state) => state?.utilityReducer);
};
const UserCardBase = () => {
  return useSelector((state) => state?.userCardBaseReducer);
};
const handleRedirectWithLoading = (
  setIsLoading,
  url,
  login,
  setShowModal,
  isModal,
  navigate
) => {
  if (!login === true && isModal) {
    setShowModal(true);
    navigate && navigate("/");
  } else {
    setIsLoading(true);
    window.open(url, "_self");
    setTimeout(() => setIsLoading(false), 10000);
  }
};
const FormatDate = (date) => {
  if (!date) return null;
  const d = new Date(date);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = d.getDate().toString().padStart(2, "0");
  const month = months[d.getMonth()];
  const year = d.getFullYear();

  return `${day} ${month} ${year}`;
};
const VoucherHistoryReducer = () => {
  return useSelector((state) => state?.voucherHistoryReducer);
};
const CustomFormatNumber = (input) => {
  let str = typeof input === "string" ? input : input?.toString();
  if (str?.length <= 3) return str;

  let result = [str?.slice(-3)];
  let remaining = str?.slice(0, -3);

  while (remaining?.length) {
    result?.push(remaining?.slice(-2));
    remaining = remaining?.slice(0, -2);
  }

  return result?.reverse().join(",");
};
const FetchDealData = (dispatch) => {
  dispatch(onGetsectionMaster());
  dispatch(onGetSectionContentMaster());
  dispatch(onGetDeal());
  dispatch(onGetDealCouponFreq());
  dispatch(onGetDealCoupon());
  dispatch(onGetDealCategory());
};
const isValidColor = (color) => {
  const s = new Option().style;
  s.color = color;
  return s.color !== "";
};
const ApplyThemeStyles = (themeData) => {
  const setStyle = (variable, color, fallback) => {
    document.documentElement.style.setProperty(
      variable,
      isValidColor(color) ? color : fallback
    );
  };

  setStyle("--bs-white-2", themeData?.commonhgElementSurfaceColor, "#FCF1F2");
  setStyle("--bs-light-1-black", themeData?.headerTextColor, "#222222");
  setStyle("--bs-white", themeData?.headerBackgroundColor, "#fff");
  setStyle("--bs-black-3", themeData?.headerBorderColor, "#ffffff");
  setStyle("--bs-white-7", themeData?.primaryBgColor, "#fff");
  setStyle("--bs-light-2-black", themeData?.primaryText, "#222222");
  setStyle("--bs-light-4-black", themeData?.secondaryText, "#222222");
  setStyle("--primary-green", themeData?.earnElementColor, "#00AE4E");
  setStyle("--earn-bg", themeData?.earnSurfaceColor, "#E5F7ED");
  setStyle("--bs-card-name", themeData?.cardNameTextColor, "#E5F7ED");
  setStyle("--bs-white-7", themeData?.commonElementBackgroundColor, "#fff");
  setStyle("--bs-red-2", themeData?.commonElementBorderColor, "#9a1b24");
  setStyle("--bs-red-2", themeData?.commonElementTextColor, "#9a1b24");
};

export {
  ClientId,
  UserId,
  SectionMasterData,
  SectionContentMasterData,
  DealCategoryData,
  DealData,
  DealCouponData,
  ProductContentData,
  DealCouponFreqData,
  DealCouponCodeData,
  NavConfigureData,
  GetMemberData,
  GetSegment,
  GetSwitchAccount,
  PointPerPrice,
  ApiConfigData,
  ThemeData,
  ApiConfigDataLoading,
  ThemeDataLoading,
  AppTitle,
  AppLogo,
  handleRedirectWithLoading,
  ModalOrSso,
  UtilityReducer,
  FormatDate,
  VoucherHistoryReducer,
  CustomFormatNumber,
  ValidateLoginToken,
  UserCardBase,
  FetchDealData,
  ApplyThemeStyles
};

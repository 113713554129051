import React from "react";
import {
  ApiConfigData,
  handleRedirectWithLoading,
  ModalOrSso,
  SectionContentMasterData,
} from "../../Utility/Utility";
import { useNavigate } from "react-router-dom";

const CustomerMenu = ({
  text,
  sectionId,
  login,
  setShowModal,
  setIsLoading,
  isMobileView,
}) => {
  const navigate = useNavigate();
  const { sctionContentdata } = SectionContentMasterData();
  const customerMenuDataContent = sctionContentdata?.filter(
    (filterData) => filterData?.sectionMasterId === sectionId
  );
  const apiConfigData = ApiConfigData();
  const integrationId = apiConfigData?.find(
    (data) => data?.paramsKey === "integrationId"
  )?.paramsValue;

  const loginUrl = `${process.env.REACT_APP_INTEGRATION_LOGIN_URL}Login/${integrationId}`;
  const isModal = ModalOrSso();

  const handleClick = (e, data) => {
    e.preventDefault();
    if (!data.cta) return;

    if (data?.isLogin) {
      if (login) {
        if (/^https?:\/\//.test(data.cta)) {
          return handleRedirectWithLoading(
            setIsLoading,
            data.cta,
            login,
            setShowModal,
            isModal
          );
        }

        navigate(data.cta);
      } else {
        isModal ? setShowModal(true) : window.open(loginUrl, "_self");
      }
    } else {
      if (/^https?:\/\//.test(data.cta)) {
        return handleRedirectWithLoading(
          setIsLoading,
          data.cta,
          login,
          setShowModal,
          isModal
        );
      } else {
        navigate(data.cta);
      }
    }
  };

  return (
    <>
      {customerMenuDataContent.length ? (
        <div className="all-p flex flex-col explore-card container mx-auto">
          <h1
            className={`pt-4 ${!isMobileView && " text-center pb-8"} home-head pl-4 `}
          >
            {text}
          </h1>
          <div
            className={`flex overflow-x-auto py-7 scrollbar-hide explore-section ${
              !isMobileView && " justify-center"
            }`}
            style={{ scrollSnapType: "x mandatory" }}
          >
            {customerMenuDataContent.map((contentData, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center custom-img-bg shrink-0"
                style={{
                  width: isMobileView ? "25%" : "",
                  scrollSnapAlign: "start",
                }}
              >
                <div className="relative flex items-center justify-center cust-product">
                  {contentData?.textElementFlag &&
                    contentData?.textElementStatus && (
                      <div
                        className={`absolute custm-points ${
                          isMobileView && "text-xs"
                        } font-bold text-white`}
                        style={{ background: contentData?.textbgColor }}
                      >
                        <span style={{ color: contentData?.textColor }}>
                          {contentData?.textForElement?.length > 8
                            ? `${contentData.textForElement.slice(0, 8)}..`
                            : contentData.textForElement}
                        </span>
                      </div>
                    )}
                  <img
                    onClick={(e) => handleClick(e, contentData)}
                    className={`${
                      isMobileView ? "w-16 h-16" : ""
                    } rounded-full object-cover`}
                    alt={ contentData?.text}
                    title={ contentData?.text}
                    src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${
                      isMobileView ? contentData.mobImage : contentData.webImage
                    }`}
                  />
                </div>
                <span className="mt-2 text-sm font-medium">
                  {contentData.text.length > (isMobileView ? 9 : 10)
                    ? contentData?.text.substring(0, isMobileView ? 8 : 10) +
                      ".."
                    : contentData?.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CustomerMenu;

import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  ApiConfigData,
  GetMemberData,
  handleRedirectWithLoading,
  ModalOrSso,
  NavConfigureData,
  AppLogo,
} from "../../Utility/Utility";
import Trip from "../../Assets/imgNewUI/Trip.png";
import Shop from "../../Assets/imgNewUI/Shop.png";
import Gift from "../../Assets/imgNewUI/Gift.png";
import Offer from "../../Assets/imgNewUI/Offer.png";

const Header = ({ path, isMobileView, setShowModal, setIsLoading }) => {
  const memberData = GetMemberData();
  const logo = AppLogo();
  const location = useLocation();
  const userName = memberData?.MemberData?.memberProfile;
  const isModal = ModalOrSso();

  const initials = (
    (userName?.firstName?.[0] || "N") + (userName?.lastName?.[0] || "A")
  ).toUpperCase();
  const apiConfigData = ApiConfigData();
  const { navConfigureData } = NavConfigureData();
  const navigate = useNavigate();

  const iconMap = {
    tripstacc: { src: Trip, alt: "Trip" },
    shopstacc: { src: Shop, alt: "Shop" },
    offers: { src: Offer, alt: "Offer" },
    giftstacc: { src: Gift, alt: "Gift" },
  };

  const navItems = navConfigureData?.map((item) => {
    const name = item?.navigationMenuName?.toLowerCase();
    const iconData = iconMap[name];

    return {
      loginRequired: item?.loginRequired,
      cta: item?.cta,
      navigationMenuName: item?.navigationMenuName,
      icon: iconData ? <img src={iconData.src} alt={iconData.alt} /> : null,
    };
  });

  const isLoggedIn = Boolean(sessionStorage.getItem("login"));
  const integrationId = apiConfigData?.find(
    (data) => data?.paramsKey === "integrationId"
  )?.paramsValue;
  const handleLogin = (e, data) => {
    e.preventDefault();
    if (data?.loginRequired && !isLoggedIn && isModal) {
      navigate("/");
      setShowModal(true);
    } else {
      if (!/^https?:\/\//.test(data?.cta)) {
        navigate(data?.cta);
      } else {
        if (data.cta) {
          handleRedirectWithLoading(
            setIsLoading,
            data.cta,
            isLoggedIn,
            setShowModal,
            isModal
          );
        }
      }
    }
  };
  const loginUrl = `${process.env.REACT_APP_INTEGRATION_LOGIN_URL}Login/${integrationId}`;
  const defaultHeader = (
    <header className="bg-white p-3 flex justify-between items-center shadow">
      <div className="flex items-center space-x-4">
        {isMobileView && (
          <Link to="/side-navbar">
            <button
              className="text-xl menu-head"
              aria-label="Open navigation menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </Link>
        )}
        {!isLoggedIn &&
          (logo ? (
            <Link to="/">
              <img
                src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${logo}`}
                alt="Logo"
                className={`h-8 w-auto ${!isMobileView ? "ml-6" : ""}`}
              />
            </Link>
          ) : (
            ""
          ))}

      </div>
      {!isMobileView && (
        <nav className="flex items-center space-x-6">
          {navItems.map((item, index) => (
            <Link
              key={index}
              onClick={(e) => handleLogin(e, item)}
              className={`nav-item ${
                path === item?.cta ? "active" : ""
              } flex items-center space-x-2`}
            >
              <span className="h-5 w-5">{item?.icon}</span>
              <span>{item.navigationMenuName}</span>
            </Link>
          ))}
        </nav>
      )}
      {isLoggedIn && <h1 className="font-semibold line-hi">Rewards & Deals</h1>}
      <div>
        {isLoggedIn ? (
          <div
            className="pro-nm rounded-full p-4 flex items-center justify-center w-8 h-8"
            aria-label="User profile"
          >
            <Link>{initials}</Link>
          </div>
        ) : integrationId ? (
          <button
            disabled={isLoggedIn && !integrationId}
            onClick={() => {
              handleRedirectWithLoading(
                setIsLoading,
                loginUrl,
                isLoggedIn,
                setShowModal,
                isModal
              );
            }}
            className={`login-ft px-4 py-2 disabled:cursor-not-allowed ${
              !isMobileView ? "mr-8" : ""
            }`}
            aria-label="Login"
          >
            Login
          </button>
        ) : (
          ""
        )}
      </div>
    </header>
  );

  const leftArrowHeader = (
    <header className="bg-white p-4 border-b-2 border-grey flex items-center">
      <div className="flex items-center space-x-4">
        <Link onClick={() => navigate(-1)}>
          <button className="text-xl menu-head" aria-label="Go back">
            {path === "/order-history" || path === "/voucher-history" ? (
              <i className="fa fa-angle-left"></i>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-8 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10 19l-7-7 7-7"
                />
                <line
                  x1="3"
                  y1="12"
                  x2="21"
                  y2="12"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            )}
          </button>
        </Link>
      </div>

      {(path === "/contact-us" ||
        path === "/privacy-policy" ||
        path === "/terms-conditions") &&
      isMobileView ? (
        <h1 className="font-semibold line-hi absolute left-1/2 transform -translate-x-1/2">
          {location?.state?.commonComponent
            ? location?.state?.Head
            : "Contact Us"}
        </h1>
      ) : path === "/order-history" ? (
        <h1 className="font-semibold line-hi absolute left-1/2 transform -translate-x-1/2">
          My Offers & Benefits
        </h1>
      ) : path === "/voucher-history" ? (
        <h1 className="font-semibold line-hi absolute left-1/2 transform -translate-x-1/2">
          Voucher History
        </h1>
      ) : (
        ""
      )}
    </header>
  );
  if (path === "/contact-us" && !isMobileView) {
    return defaultHeader;
  }
  if (path === "/deal" && !isMobileView) {
    return defaultHeader;
  } else if (path === "/deal") {
    return null;
  }

  return path === "/" ||
    path === "/Offers" ||
    (!isMobileView && path === "/contact-us") ||
    (!isMobileView && path === "/privacy-policy") ||
    (!isMobileView && path === "/terms-conditions")
    ? defaultHeader
    : leftArrowHeader;
};

export default Header;

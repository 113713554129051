import axiosInstanceAdmin from '../Common/Axios/axiosInstanceAdmin';
import API from '../Common/Endpoint/serviceConstrants';

export const callClientMasterGetApi = async (payload) => {
  const { data = [] } = await axiosInstanceAdmin.get(API.clientmaster, {
    params: {
      PlatformDomainUrl: payload?.PlatformDomainUrl
    },
    headers: {
      Userid: process.env.REACT_APP_USER_ID,
      Password: process.env.REACT_APP_PASSWORD,
      Encryption:1
    }
  });
  return data;
};
import React, { useRef } from "react";
import ACImage from "../../Assets/imgNewUI/ACImage.png";

const WebSummerSpecials = () => {
  const cards = [
    {
      id: 1,
      title: "Shop from top AC Brands and Earn Points",
      buttonText: "Buy Now",
    },
    {
      id: 2,
      title: "Shop from top AC Brands and Earn Points",
      buttonText: "Buy Now",
    },
    {
      id: 3,
      title: "Shop from top AC Brands and Earn Points",
      buttonText: "Buy Now",
    },
    {
      id: 4,
      title: "Shop from top AC Brands and Earn Points",
      buttonText: "Buy Now",
    },
    {
      id: 5,
      title: "Shop from top AC Brands and Earn Points",
      buttonText: "Buy Now",
    },
    {
      id: 6,
      title: "Shop from top AC Brands and Earn Points",
      buttonText: "Buy Now",
    },
  ];
  const cardGridRef = useRef(null);
  const sliderThumbRef = useRef(null);
  const sliderRef = useRef(null);
  // Handle left button click
  const handleScrollLeft = () => {
    const cardGrid = cardGridRef.current;
    cardGrid.scrollBy({
      left: -300, // Adjust the value as per your card width
      behavior: "smooth",
    });
  };

  // Handle right button click
  const handleScrollRight = () => {
    const cardGrid = cardGridRef.current;
    cardGrid.scrollBy({
      left: 300, // Adjust the value as per your card width
      behavior: "smooth",
    });
  };
  // Handle slider changes
  const handleSliderChange = (e) => {
    const slider = sliderRef.current;
    const sliderRect = slider?.getBoundingClientRect();
    const thumbWidth = sliderThumbRef.current.offsetWidth;
    const sliderWidth = slider?.offsetWidth - thumbWidth;
    let thumbLeft = e.clientX - sliderRect.left - thumbWidth / 2;

    // Constrain thumb within the slider
    if (thumbLeft < 0) thumbLeft = 0;
    if (thumbLeft > sliderWidth) thumbLeft = sliderWidth;

    // Move the slider thumb
    sliderThumbRef.current.style.left = `${thumbLeft}px`;

    // Calculate the corresponding scroll position for the card grid
    const cardGrid = cardGridRef.current;
    const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
    const scrollLeft = (thumbLeft / sliderWidth) * maxScrollLeft;

    // Scroll the card grid
    cardGrid.scrollLeft = scrollLeft;
  };
  // Update slider thumb when the card grid is scrolled
  const handleCardGridScroll = () => {
    const cardGrid = cardGridRef?.current;
    const scrollLeft = cardGrid?.scrollLeft;
    const maxScrollLeft = cardGrid?.scrollWidth - cardGrid?.clientWidth;
    const sliderWidth = sliderRef?.current?.offsetWidth;
    const thumbWidth = sliderThumbRef?.current?.offsetWidth;
    const maxThumbLeft = sliderWidth - thumbWidth;

    // Calculate the slider thumb position
    const thumbLeft = (scrollLeft / maxScrollLeft) * maxThumbLeft;
    sliderThumbRef.current.style.left = `${thumbLeft}px`;
  };

  return (
    <div className="max-w-screen-xl mx-auto py-8 px-4">
      <h2 className="w-head mb-4">Summer Specials</h2>
      <div className="relative">
        <button
          className="absolute left-[-20px] top-[40%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 flex items-center justify-center w-10 h-10"
          aria-label="Scroll Left"
          onClick={handleScrollLeft}
        >
          <i class="fa-solid fa-arrow-left"></i>
        </button>
        <div
          className="flex overflow-x-scroll scrollbar-hide gap-4"
          ref={cardGridRef}
          onScroll={handleCardGridScroll}
        >
          {cards.map((card) => (
            <div
              key={card.id}
              className="flex-shrink-0 w-72 card-line overflow-hidden bg-white"
            >
              <img src={ACImage} alt="AC" className="object-cover" />
              <div className="text-center mt-4">
                <span className="card-span px-1">{card.title}</span>
                <button className="mt-4 py-2 px-4 summer-buynow mb-4">
                  {card.buttonText}
                </button>
              </div>
            </div>
          ))}
        </div>

        <button
          className="absolute right-[-20px] top-[40%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 flex items-center justify-center w-10 h-10"
          aria-label="Scroll Left"
          onClick={handleScrollRight}
        >
          <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
      {cards.length > 2 && (
        <div className="slider_wrapper pt-4">
          <div
            className="slider"
            ref={sliderRef}
            onMouseMove={handleSliderChange}
          >
            <div className="slider_track"></div>
            <div className="slider_thumb" ref={sliderThumbRef}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebSummerSpecials;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { onLoginAuthSubmit } from "../../Store/Slices/loginAuthSlice";
import PageError500 from "../../Utility/PageError/PageError";
import { useDispatch, useSelector } from "react-redux";
import RouteConfiq from "../../Routing/routeConfig";
import Loader from "../../Utility/Loader/Loader";
import axiosInstanceAdmin from "../../Common/Axios/axiosInstanceAdmin";
import axiosInstanceClient from "../../Common/Axios/axiosInstanceClient";
import {
  onGetClientMaster,
  onGetClientMasterReset,
} from "../../Store/Slices/clientMasterSlice";
import { onGetThemeMaster } from "../../Store/Slices/themeMasterSlice";
import { onGetApiConfig } from "../../Store/Slices/apiConfigSlice";

const Auth = () => {
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const [pageError, setPageError] = useState({
    StatusCode: "",
    ErrorName: "",
    ErrorDesription: "",
    url: "",
    buttonText: "",
  });

  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

  const clientMasterData = useSelector((state) => state.clientMasterReducer);
  const clientData = useSelector(
    (state) => state.clientMasterReducer?.clientMasterData
  );
  // to get data from redux store
  const translationData = useSelector((state) => state.translationReducer);
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  const currentUrl = window.location.origin;

  //fetch module master data on mount
  useEffect(() => {
    if (!clientData?.length) {
      dispatch(
        onGetClientMaster({
          PlatformDomainUrl: currentUrl,
        })
      );
    }
  }, [currentUrl]);
  useEffect(() => {
    if (clientMasterData?.get_status_code === "200") {
      if (
        clientData?.[0]?.clientCode &&
        clientData?.[0]?.userType === "UIMasterClient"
      ) {
        dispatch(
          onLoginAuthSubmit({
            accessKey: ACCESS_KEY,
            secretkey: SECRET_KEY,
          })
        );
      } else {
        setShowError(true);
        setPageError({
          StatusCode: clientMasterData?.get_status_code,
          ErrorName: "Internal Server Error",
          ErrorDescription: "You do not have permission. Please contact admin.",
          url: "/",
          buttonText: "Back to Home",
        });
      }
      dispatch(onGetClientMasterReset());
    } else if (clientMasterData?.get_status_code) {
      setShowError(true);
      setPageError({
        StatusCode: clientMasterData?.get_status_code,
        ErrorName: "Internal Server Error",
        ErrorDescription: "You do not have permission. Please contact admin.",
        url: "/",
        buttonText: "Back to Home",
      });
    }
  }, [clientMasterData, clientData]);

  axiosInstanceClient.defaults.headers.Authorization = `Bearer ${loginAuthData?.data?.[0]?.token}`;
  axiosInstanceAdmin.defaults.headers.Authorization = `Bearer ${loginAuthData?.data?.[0]?.token}`;
  axiosInstanceAdmin.defaults.headers["Client-Code"] =
    clientData?.[0]?.clientCode;
  axiosInstanceClient.defaults.headers["Client-Code"] =
    clientData?.[0]?.clientCode;
  axiosInstanceClient.defaults.headers["Encryption"] = 2;
  axiosInstanceAdmin.defaults.headers["Encryption"] = 2;
  axiosInstanceAdmin.defaults.headers["Partner-Code"] =
    clientData?.[0]?.userType;
  axiosInstanceClient.defaults.headers["Partner-Code"] =
    clientData?.[0]?.userType;
  useEffect(() => {
    if (loginAuthData?.status_code === "200") {
      if (parseInt(clientData?.[0]?.themeId)) {
        dispatch(onGetThemeMaster({ id: parseInt(clientData?.[0]?.themeId) }));
      }
      const hasRun = sessionStorage.getItem("setCall");
      if (!hasRun) {
        dispatch(onGetApiConfig({ serviceName: "v3" }));
        sessionStorage.setItem("setCall", "true");
      }
      setPageError(false);
    } else if (loginAuthData?.status_code) {
      setPageError(true);
    }
  }, [loginAuthData]);
  return (
    <>
      {clientMasterData?.isgetLoading ||
      translationData?.isLoading ||
      loginAuthData?.isLoading ? (
        <Loader />
      ) : (
        <>
          {showError ? <PageError500 pageError={pageError} /> : <RouteConfiq />}
        </>
      )}
    </>
  );
};

export default Auth;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import menu1icon from "../../Assets/imgNewUI/4.svg";
import menu2icon from "../../Assets/imgNewUI/5.svg";
import { Link } from "react-router-dom";
import { handleRedirectWithLoading, ModalOrSso } from "../../Utility/Utility";

const HistoryMenu = ({
  title,
  onClose,
  setIsLoading,
  apiconfig,
  login,
  setShowModal,
}) => {
  const isModal = ModalOrSso();

  const tripStaccUrl = apiconfig?.find(
    (data) => data?.paramsKey === "tripStaccUrl"
  )?.paramsValue;
  const shopStaccUrl = apiconfig?.find(
    (data) => data?.paramsKey === "shopStaccUrl"
  )?.paramsValue;
  const handleRedirect = (props) => {
    onClose();
    handleRedirectWithLoading(
      setIsLoading,
      props === "trip" ? tripStaccUrl : shopStaccUrl,
      login,
      setShowModal,
      isModal
    );
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
      <div className="fixed bottom-0 left-0 right-0 w-full bg-white rounded-t-3xl shadow-lg reward-menu-h overflow-hidden z-50">
        <div className="bg-white rounded-t-2xl w-full h-full p-4 shadow-lg flex flex-col overflow-hidden">
          <div className="items-center sticky top-0 bg-white z-10 text-end">
            <button className="text-gray-600 text-2xl" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="overflow-y-auto h-full ">
            <h2 className="reward-history-head font-semibold">{title}</h2>
            <div className="reward-link">
              <ul>
                <li onClick={() => handleRedirect("trip")}>
                  <div className="link">
                    <img src={menu1icon} alt="not-found" /> Travel Bookings
                  </div>
                  <i
                    className="fa-solid fa-angle-right mr-2"
                    onClick={() => handleRedirect("trip")}
                  ></i>
                </li>
                <li>
                  <Link onClick={() => handleRedirect("shop")}>
                    <div className="link">
                      <img src={menu2icon} alt="not-found" /> Shopping Orders
                    </div>
                  </Link>
                  <i
                    className="fa-solid fa-angle-right mr-2"
                    onClick={() => handleRedirect("shop")}
                  ></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryMenu;

import { createSlice } from "@reduxjs/toolkit";

export const dealCouponSlice = createSlice({
  name: "deal-coupon",
  initialState: {
    isLoading: false,
    isError: false,
    dealCouponData: [],
    message: "",
  },
  reducers: {
    onGetDealCoupon: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getDealCouponData: [],
        getmessage: "",
      };
    },

    onGetDealCouponSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getDealCouponData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetDealCouponError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getDealCouponData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
  },
});

export const {
 onGetDealCoupon,
 onGetDealCouponSuccess,
 onGetDealCouponError,
} = dealCouponSlice.actions;

export default dealCouponSlice.reducer;

import { call, put, takeLatest } from "redux-saga/effects";
import { callThemeMasterGetApi } from "../Context/themeMasterApi";
import { onGetThemeMaster, onGetThemeMasterError, onGetThemeMasterSuccess } from "../Store/Slices/themeMasterSlice";
function* GetThemeMaster({payload}) {
  try {
    const getThemeMasterResponse = yield call(callThemeMasterGetApi,payload);
    if (getThemeMasterResponse.responseCode === "200") {
      yield put(
        onGetThemeMasterSuccess({
          data: getThemeMasterResponse.response,
          message: getThemeMasterResponse.responseMessage,
          status_code:getThemeMasterResponse.responseCode
        })
      );
    } else {
      yield put(

        onGetThemeMasterError({

          data: getThemeMasterResponse.response,
          message: getThemeMasterResponse.response.message,
          status_code:getThemeMasterResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onGetThemeMasterError({ data: [], message, status_code: 400 }));
  }
}

export default function* themeMasterSaga() {
  yield takeLatest(onGetThemeMaster.type, GetThemeMaster);

}

import { combineReducers } from 'redux';
import translationReducer from "./Slices/translationSlice";
import loginAuthReducer from "./Slices/loginAuthSlice";
import  sectionMasterReducer  from './Slices/sectionMasterSlice';
import sectionContentMasterReducer from './Slices/sectionContentMasterSlice';
import dealCategoryReducer from './Slices/dealCategorySlice';
import dealReducer from './Slices/dealSlice';
import dealCouponReducer from './Slices/dealCouponSlice';
import dealCouponCodeReducer from './Slices/dealCouponCodeSlice';
import uploadReducer from './Slices/uploadSlice';
import navConfigurationReducer from './Slices/NavConfigurationSlice';
import dealCouponFreqReducer from './Slices/dealCouponFreqSlice';
import customerSegmentReducer from './Slices/customerSegmentSlice';
import productContentReducer from './Slices/productContentSlice';
import clientMasterReducer from './Slices/clientMasterSlice';
import metaDataReducer from './Slices/metaDataSlice';
import generateTokenSliceReducer from './Slices/generateTokenSlice';
import validateLoginTokenSliceReducer from './Slices/validateLoginTokenSlice';
import getMemberSliceReducer from './Slices/getMemberSlice';
import switchAccountSliceReducer from './Slices/switchAccountSlice';
import SegmentSliceReducer from './Slices/SegmentSlice';
import apiConfigReducer from './Slices/apiConfigSlice';
import themeMasterReducer  from './Slices/themeMasterSlice';
import unlockSectionReducer from './Slices/unlockSectionSlice';
import utilityReducer from './Slices/utilityReducer';
import sendMailReducer from './Slices/sendMailSlice';
import offerBenefitsReducer from './Slices/offerNdBenefitsSlice';
import voucherHistoryReducer from './Slices/voucherHistorySlice';
import userCardBaseReducer from './Slices/userCardBaseSlice';

const reducers = combineReducers({
    translationReducer: translationReducer,
    loginAuthReducer: loginAuthReducer,
    sectionMasterReducer:sectionMasterReducer,
    sectionContentMasterReducer:sectionContentMasterReducer,
    dealCategoryReducer:dealCategoryReducer,
    dealReducer:dealReducer,
    dealCouponReducer:dealCouponReducer,
    dealCouponCodeReducer:dealCouponCodeReducer,
    uploadReducer:uploadReducer,
    navConfigurationReducer:navConfigurationReducer,
    dealCouponFreqReducer:dealCouponFreqReducer,
    customerSegmentReducer:customerSegmentReducer,
    productContentReducer:productContentReducer,
    clientMasterReducer:clientMasterReducer,
    metaDataReducer:metaDataReducer,
    generateTokenSliceReducer: generateTokenSliceReducer,
    validateLoginTokenSliceReducer:validateLoginTokenSliceReducer,
    getMemberSliceReducer:getMemberSliceReducer,
    switchAccountSliceReducer:switchAccountSliceReducer,
    SegmentSliceReducer:SegmentSliceReducer,
    apiConfigReducer:apiConfigReducer,
    themeMasterReducer:themeMasterReducer,
    unlockSectionReducer:unlockSectionReducer,
    utilityReducer:utilityReducer,
    sendMailReducer:sendMailReducer,
    offerBenefitsReducer:offerBenefitsReducer,
    voucherHistoryReducer:voucherHistoryReducer,
    userCardBaseReducer : userCardBaseReducer,
});

export default reducers;
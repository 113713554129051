import { all } from "redux-saga/effects";
import translationSaga from "./translationSaga";
import loginAuthSaga from "./loginAuthSaga";
import sectionMasterSaga from "./sectionMasterSaga";
import SectionContentMasterSaga from "./sectionContentMasterSaga";
import dealCategorySaga from "./dealCategorySaga";
import dealSaga from "./dealSaga";
import dealCouponSaga from "./dealCouponSaga";
import dealCouponCodeSaga from "./dealCouponCodeSaga";
import uploadSaga from "./uploadSaga";
import navConfigureSaga from "./navConfigureSaga";
import dealCouponFreqSaga from "./dealCouponFreqSaga";
import customerSegmentSaga from "./customerSegmentSaga";
import productContentSaga from "./productContentSaga";
import clientMasterSaga from "./clientMasterSaga";
import metaDataSaga from "./metaDataSaga";
import generateTokenSaga from "./generateTokenSaga";
import validateLoginTokenSaga from "./validateLoginTokenSaga";
import getMemberSaga from "./getMemberSaga";
import SwitchAccountSaga from "./switchAccountSaga";
import SegmentSaga from "./segmentSaga";
import apiConfigSaga from "./apiConfigSaga";
import themeMasterSaga from "./themeMasterSaga";
import unlockSectionSaga from "./unlockSectionSaga";
import sendMailSaga from "./sendMailSaga";
import offerNdBenefitsSaga from "./offerNdBenefitsSaga";
import voucherHistorySaga from "./voucherHistorySaga";
import userCardBaseSaga from "./userCardBaseSaga";

export default function* rootSaga() {
  yield all([
    loginAuthSaga(),
    translationSaga(),
    sectionMasterSaga(),
    SectionContentMasterSaga(),
    dealCategorySaga(),
    dealSaga(),
    dealCouponSaga(),
    dealCouponCodeSaga(),
    uploadSaga(),
    navConfigureSaga(),
    dealCouponFreqSaga(),
    customerSegmentSaga(),
    productContentSaga(),
    clientMasterSaga(),
    metaDataSaga(),
    generateTokenSaga(),
    validateLoginTokenSaga(),
    getMemberSaga(),
    SwitchAccountSaga(),
    SegmentSaga(),
    apiConfigSaga(),
    themeMasterSaga(),
    unlockSectionSaga(),
    sendMailSaga(),
    offerNdBenefitsSaga(),
    voucherHistorySaga(),
    userCardBaseSaga(),
  ]);
}

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  handleRedirectWithLoading,
  ModalOrSso,
  NavConfigureData,
} from "../../Utility/Utility";
import contactus from "../../Assets/imgNewUI/Contact Us.png";
import Loader from "../../Utility/Loader/Loader";

const SideNavbar = ({ login, setShowModal, setIsLoading, Loading }) => {
  const { navConfigureData } = NavConfigureData();
  const isModal = ModalOrSso();
  const navigate = useNavigate();

  const handleLogin = (e, data) => {
    e.preventDefault();
    if (data?.loginRequired && !login) {
      handleRedirectWithLoading(
        setIsLoading,
        data.cta,
        login,
        setShowModal,
        isModal,
        navigate
      );
    } else {
      if (!/^https?:\/\//.test(data?.cta)) {
        navigate(data?.cta);
      } else {
        if (data.cta) {
          handleRedirectWithLoading(
            setIsLoading,
            data.cta,
            login,
            setShowModal,
            isModal
          );
        }
      }
    }
  };
  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div
          id="top_offer_main_wrapper_container"
          className=" top_offer_main_wrapper_container"
        >
          <div className="grid grid-cols-2 gap-4 pt-4 px-4">
            {navConfigureData?.map((navData, index) => (
              <div className="rounded-2xl" key={index}>
                <Link onClick={(e) => handleLogin(e, navData)}>
                  <img
                    src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${navData?.icon}`}
                    alt={navData?.navigationMenuName}
                    title={navData?.navigationMenuName}
                    className="cursor-pointer"
                  />
                </Link>
              </div>
            ))}
          </div>

          <div className="mt-6 px-4">
            <div className="box-wth rounded-2xl border border-gray-300 bg-white">
              <Link
                to="/contact-us"
                className="flex items-center justify-between px-4 py-3 box-h hover:bg-gray-100"
              >
                <div className="flex items-center space-x-3">
                  <img
                    src={contactus}
                    alt="contact-us"
                    className="contact-us"
                  />
                  <span className="cont-sz">Contact Us</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-5 h-5 text-black cont-sz"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SideNavbar;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import CardSection from "../Components/CardSection/CardSection";
import PromoBanner from "../Components/PromoBanner/PromoBanner";
import CustomerMenu from "../Components/CustomerMenu/CustomerMenu";
import SupportingBanner from "../Components/SupportingBanner/SupportingBanner";
import SpecialSection from "../Components/SpecialSection/SpecialSection";
import TopOffer from "../Components/TopOffer/TopOffer";
import GiftCardSection from "../Components/GiftCardSection/GiftCardSection";
import SpecialCartBanner from "../Components/SpecialCartBanner/SpecialCartBanner";
import ScrollToTop from "../Utility/ScrollToTop/ScrollToTop";
import PromoMessage from "../Components/PromoMessage/PromoMessage";
import axiosIntegration from "../Common/Axios/axiosIntegration";
import {
  ApiConfigData,
  AppTitle,
  GetSegment,
  SectionContentMasterData,
  SectionMasterData,
  ThemeData,
  UtilityReducer,
  ValidateLoginToken,
} from "../Utility/Utility";
import { useDispatch, useSelector } from "react-redux";
import { onGetsectionMaster } from "../Store/Slices/sectionMasterSlice";
import { onGetSectionContentMaster } from "../Store/Slices/sectionContentMasterSlice";
import { onGetProductContent } from "../Store/Slices/productContentSlice";
import { onGetDeal } from "../Store/Slices/dealSlice";
import { onGetDealCoupon } from "../Store/Slices/dealCouponSlice";
import { onGetDealCouponFreq } from "../Store/Slices/dealCouponFreqSlice";
import { onGetDealCategory } from "../Store/Slices/dealCategorySlice";
import Loader from "../Utility/Loader/Loader";

import Modal from "../Components/Modal/Modal";
import { useState } from "react";

import { onGetMemberData } from "../Store/Slices/getMemberSlice";
import { onSaveIamge } from "../Store/Slices/utilityReducer";
import { onGetUnlockSection } from "../Store/Slices/unlockSectionSlice";

import API from "../Common/Endpoint/serviceConstrants";
import { useNavigate } from "react-router-dom";
import { onValidateLoginTokenResetNull } from "../Store/Slices/validateLoginTokenSlice";

const HomePage = ({
  setIsModalOpenoffer,
  isModalOpenoffer,
  setShowModal,
  setIsLoading,
  Loading,
  showModal,
  isMobileView,
}) => {
  const title = AppTitle();
  const [sessionOut, setSetSessionOut] = useState(false);
  const card = UtilityReducer();
  const creditCards = card?.creditCards;
  const debitCards = card?.debitCards;
  const themeData = ThemeData();
  const loginToken = useSelector(
    (state) => state.validateLoginTokenSliceReducer
  );
  const defaultCard = loginToken?.data?.subIdentifier;

  const unlockData = useSelector((state) => state?.unlockSectionReducer);
  const generateToken = useSelector((state) => state.generateTokenSliceReducer);
  const apiconfig = ApiConfigData();
  const navigate = useNavigate();

  const partnerCode = apiconfig?.find(
    (data) => data?.paramsKey === "partnerCode"
  )?.paramsValue;
  const clientCode = apiconfig?.find(
    (data) => data?.paramsKey === "clientCode"
  )?.paramsValue;
  const login = sessionStorage.getItem("login");
  const segmentData = GetSegment();
  const segments = segmentData?.data?.[0]?.mappedSegment;
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  const dispatch = useDispatch();
  const { isSectionLoading, sectionMasterData } = SectionMasterData(login);
  const token = ValidateLoginToken();

  const { isLoading, sctionContentdata } = SectionContentMasterData();
  const getSection = (sectionData, isLogin) => {
    const isSpecialSection = sectionData?.sectionType === "Special Section";
    const filteredData = isSpecialSection
      ? sctionContentdata?.filter(
          (filterData) => filterData.sectionMasterId === sectionData.id
        )
      : [];

    switch (sectionData?.sectionType) {
      case "Promo Message":
        return <PromoMessage sectionId={sectionData?.id} login={login} />;

      case "Customer Cards":
        return isLogin && creditCards?.length ? (
          <CardSection
            card={card}
            debitCards={debitCards}
            creditCards={creditCards}
            axiosIntegration={axiosIntegration}
            generateToken={generateToken}
            API={API}
            clientCode={clientCode}
            partnerCode={partnerCode}
          />
        ) : (
          ""
        );
      case "Promo Banner":
        return (
          <PromoBanner
            text={sectionData?.text}
            sectionId={sectionData?.id}
            setShowModal={setShowModal}
            login={login}
            unlockData={unlockData}
            creditCards={creditCards}
            setIsLoading={setIsLoading}
            isMobileView={isMobileView}
          />
        );

      case "Customer Menu":
        return (
          <CustomerMenu
            isMobileView={isMobileView}
            text={sectionData?.text}
            sectionId={sectionData?.id}
            setShowModal={setShowModal}
            login={login}
            setIsLoading={setIsLoading}
          />
        );

      case "Supporting Banner":
        return (
          <SupportingBanner
            isMobileView={isMobileView}
            text={sectionData?.text}
            sectionId={sectionData?.id}
            setShowModal={setShowModal}
            login={login}
            setIsLoading={setIsLoading}
          />
        );

      case "Special Section":
        const hasProducts = filteredData?.some(
          (item) => item.contentSourceType === "Products"
        );
        const hasGiftCards = filteredData?.some(
          (item) => item.contentSourceType === "Gift Cards"
        );

        if (hasProducts) {
          return (
            <SpecialSection
              setShowModal={setShowModal}
              login={login}
              sectionData={sectionData}
              setIsLoading={setIsLoading}
              isMobileView={isMobileView}
            />
          );
        }
        if (!hasProducts && hasGiftCards) {
          return (
            <GiftCardSection
              isMobileView={isMobileView}
              sectionData={sectionData}
              setShowModal={setShowModal}
              login={login}
              setIsLoading={setIsLoading}
            />
          );
        }

        if (filteredData?.some((item) => item.contentSourceType === "Deals")) {
          return (
            <TopOffer
              login={login}
              setShowModal={setShowModal}
              sectionData={sectionData}
              setIsModalOpenoffer={setIsModalOpenoffer}
              isModalOpenoffer={isModalOpenoffer}
              creditCards={creditCards}
              isMobileView={isMobileView}
            />
          );
        }
        return null;

      case "Special Cart Banner":
        return (
          <SpecialCartBanner
            setShowModal={setShowModal}
            login={login}
            text={sectionData?.text}
            sectionId={sectionData?.id}
            setIsLoading={setIsLoading}
            isMobileView={isMobileView}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (loginAuthData?.status_code === "200") {
      let customerSegmentId = "";

      if (segments?.length === 1) {
        customerSegmentId = segments[0]?.segmentId;
      } else if (segments?.length > 1) {
        customerSegmentId = segments
          .map((element) => element?.segmentId)
          .join(",");
      }

      if (customerSegmentId) {
        dispatch(onGetDealCoupon({ customerSegmentId }));
        dispatch(onGetsectionMaster({ customerSegmentId }));
        dispatch(
          onGetSectionContentMaster({
            customerSegmentId,
          })
        );
      } else {
        dispatch(onGetsectionMaster());
        dispatch(onGetSectionContentMaster());
        dispatch(onGetDealCoupon());
      }
      if (token?.memberIdentifier) {
        dispatch(onGetMemberData({ memberId: token?.memberIdentifier }));
      }
      dispatch(onGetProductContent());
      dispatch(onGetDealCategory());
      dispatch(onGetDeal());
      dispatch(onGetDealCouponFreq());
      dispatch(
        onGetUnlockSection({ memberIdentifier: token?.memberIdentifier })
      );
    }

    setIsLoading(false);
    setIsModalOpenoffer(false);
  }, [segments]);

  const isValidColor = (color) => {
    const newStyle = new Option().style;
    newStyle.color = color;
    return newStyle.color !== "";
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--bs-white-2",
      isValidColor(themeData?.commonhgElementSurfaceColor)
        ? themeData.commonhgElementSurfaceColor
        : "#FCF1F2"
    );
    document.documentElement.style.setProperty(
      "--bs-light-1-black",
      isValidColor(themeData?.headerTextColor)
        ? themeData.headerTextColor
        : "#222222"
    );
    document.documentElement.style.setProperty(
      "--bs-white",
      isValidColor(themeData?.headerBackgroundColor)
        ? themeData.headerBackgroundColor
        : "#fff"
    );
    document.documentElement.style.setProperty(
      "--bs-black-3",
      isValidColor(themeData?.headerBorderColor)
        ? themeData.headerBorderColor
        : "#ffffff"
    );

    document.documentElement.style.setProperty(
      "--bs-white-7",
      isValidColor(themeData?.primaryBgColor)
        ? themeData.primaryBgColor
        : "#fff"
    );
    document.documentElement.style.setProperty(
      "--bs-light-2-black",
      isValidColor(themeData?.primaryText) ? themeData.primaryText : "#222222"
    );
    document.documentElement.style.setProperty(
      "--bs-light-4-black",
      isValidColor(themeData?.secondaryText)
        ? themeData.secondaryText
        : "#222222"
    );
    document.documentElement.style.setProperty(
      "--primary-green",
      isValidColor(themeData?.earnElementColor)
        ? themeData.earnElementColor
        : "#00AE4E"
    );
    document.documentElement.style.setProperty(
      "--earn-bg",
      isValidColor(themeData?.earnSurfaceColor)
        ? themeData.earnSurfaceColor
        : "#E5F7ED"
    );
    document.documentElement.style.setProperty(
      "--bs-card-name",
      isValidColor(themeData?.cardNameTextColor)
        ? themeData.cardNameTextColor
        : "#E5F7ED"
    );
    document.documentElement.style.setProperty(
      "--bs-white-7",
      isValidColor(themeData?.commonElementBackgroundColor)
        ? themeData.commonElementBackgroundColor
        : "#fff"
    );
    document.documentElement.style.setProperty(
      "--bs-red-2",
      isValidColor(themeData?.commonElementBorderColor)
        ? themeData.commonElementBorderColor
        : "#9a1b24"
    );
    document.documentElement.style.setProperty(
      "--bs-red-2",
      isValidColor(themeData?.commonElementTextColor)
        ? themeData.commonElementTextColor
        : "#9a1b24"
    );
    document.documentElement.style.setProperty(
      "--bs-dark-grey-2",
      isValidColor(themeData?.cardNumberTextColor)
        ? themeData?.cardNumberTextColor
        : "#9D1D27"
    );
    document.documentElement.style.setProperty(
      "card-points",
      isValidColor(themeData?.cardPointTextColor)
        ? themeData?.cardPointTextColor
        : "#ff496a"
    );
  }, [themeData]);

  useEffect(() => {
    const handleBodyOverflow = () => {
      const isModalOpen = isModalOpenoffer;
      document.body.style.overflow = isModalOpen ? "hidden" : "auto";
    };

    handleBodyOverflow();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);
  useEffect(() => {
    const hasRun = sessionStorage.getItem("setCard");
    if (login) {
      if (!hasRun) {
        if (creditCards?.length) {
          const finalCredicards = creditCards?.filter(
            (card) => card?.identifiertype === "1002"
          );
          const finalDebitcards = creditCards?.filter(
            (card) => card?.identifiertype === "1001"
          );
          dispatch(
            onSaveIamge({
              currentBg:
                defaultCard !== null
                  ? finalCredicards?.find(
                      (card) => card?.identifier === defaultCard
                    )?.imagelink
                  : finalCredicards.length
                  ? finalCredicards[0]?.imagelink
                  : finalDebitcards[0]?.imagelink,
              currentPoints:
                defaultCard !== null
                  ? finalCredicards?.find(
                      (card) => card?.identifier === defaultCard
                    )?.pointBalance
                  : finalCredicards.length
                  ? finalCredicards[0]?.pointBalance
                  : finalDebitcards[0]?.pointBalance,
              cardName:
                defaultCard !== null
                  ? finalCredicards?.find(
                      (card) => card?.identifier === defaultCard
                    )?.CardTypeName
                  : finalCredicards.length
                  ? finalCredicards[0]?.CardTypeName
                  : finalDebitcards[0]?.CardTypeName,
              totalPointBalance:
                defaultCard !== null
                  ? finalCredicards?.find(
                      (card) => card?.identifier === defaultCard
                    )?.totalPointBalance
                  : finalCredicards.length
                  ? finalCredicards[0]?.totalPointBalance
                  : finalDebitcards[0]?.totalPointBalance,
              showTryAgain: false,
              showViewPoint: false,
            })
          );
        }

        sessionStorage.setItem("setCard", "true");
      }
    }
  }, [login, card]);
  useEffect(() => {
    if (!loginToken?.isUsed && login) {
      let timeoutId;

      const payload = {
        sessionToken: loginToken?.data?.partnerSessinToken,
      };

      const callKeepSessionAlive = async (payloads) => {
        axiosIntegration.defaults.headers[
          "access-token"
        ] = `${generateToken?.data?.accessToken}`;

        try {
          const response = await axiosIntegration.post(
            API.keepAlive,
            payloads,
            {
              headers: {
                clientCode: clientCode,
                partnerCode: partnerCode,
              },
            }
          );

          if (response.status === 200) {
            timeoutId = setTimeout(
              () => callKeepSessionAlive(payloads),
              240000
            );
          }

          return response.data;
        } catch (error) {
          if (error.response && error.response.status === 401) {
            sessionStorage.clear();
            localStorage.clear();
            setSetSessionOut(true);
            navigate("/");
          }
          console.error("API call failed:", error);
        }
      };

      if (loginToken && loginToken.data.partnerSessinToken) {
        dispatch(onValidateLoginTokenResetNull());
        callKeepSessionAlive(payload);
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
  }, [loginToken]);

  useEffect(() => {
    document.title = title ?? "First Rewards";
  }, []);
  return (
    <>
      <ScrollToTop />
      <div>
        <>
          {sessionOut && !login && (
            <Modal
              title="For security purposes, your session has timed out. Please click OK to log in again"
              button={true}
            />
          )}

          <div>
            {isLoading ||
            unlockData?.isgetLoading ||
            Loading ||
            isSectionLoading ? (
              <Loader />
            ) : (
              sectionMasterData.map((sectionData, index) => (
                <React.Fragment key={index}>
                  {getSection(sectionData, login)}
                </React.Fragment>
              ))
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default HomePage;

import { call, put, takeLatest } from "redux-saga/effects";
import { callOfferNdBenefitsGetApi, callOfferNdBenefitsPostApi } from "../Context/offerBenefitsApi";
import { onGetOfferBenefits, onGetOfferBenefitsError, onGetOfferBenefitsSuccess, onPostOfferBenefits, onPostOfferBenefitsError, onPostOfferBenefitsSuccess } from "../Store/Slices/offerNdBenefitsSlice";


function* GetOfferBenefits({payload}) {
  try {
    const getOfferNdBenefitsResponse = yield call(callOfferNdBenefitsGetApi,payload);
    if (getOfferNdBenefitsResponse.responseCode === "200") {
      yield put(
        onGetOfferBenefitsSuccess({
          data: getOfferNdBenefitsResponse.response,
          message: getOfferNdBenefitsResponse.responseMessage,
          status_code:getOfferNdBenefitsResponse.responseCode
        })
      );
    } else {
      yield put(
        onGetOfferBenefitsError({
          data: getOfferNdBenefitsResponse.response,
          message: getOfferNdBenefitsResponse.response.message,
          status_code:getOfferNdBenefitsResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetOfferBenefitsError({ data: [], message, status_code: 400 }));
  }
}
function* PostOfferBenefits({ payload }) { 
  try {
    const postOfferNdBenefitsResponse = yield call(callOfferNdBenefitsPostApi, payload);
    if (postOfferNdBenefitsResponse.responseCode === "200") {
      yield put(
        onPostOfferBenefitsSuccess({
          postData: postOfferNdBenefitsResponse.response,
          message: postOfferNdBenefitsResponse.responseMessage,
          status_code: postOfferNdBenefitsResponse.responseCode,
        })
      );
    } else {
      yield put(
        onPostOfferBenefitsError({
          data: postOfferNdBenefitsResponse.response,
          message: postOfferNdBenefitsResponse.responseMessage,
          status_code:postOfferNdBenefitsResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onPostOfferBenefitsError({ data: [], message, status_code: 400 }));
  }
}
export default function* offerNdBenefitsSaga() {
  yield takeLatest(onGetOfferBenefits.type, GetOfferBenefits);
  yield takeLatest(onPostOfferBenefits.type, PostOfferBenefits);
}

import React from "react";
import QRImg from "../../Assets/imgNewUI/QRImg.png";
import QrRightImg from "../../Assets/imgNewUI/QrRightImg.png";

const WebDownloadApp = () => {
  return (
    <div className="flex items-center justify-between m-2 qr-bg p-6">
      <div className="flex flex-col d-width-1 items-center ml-4">
        <img
          src={QRImg}
          alt="QR Code"
          className="qr-img-sz object-cover ml-4"
        />
      </div>

      <div className="mx-6 d-load d-width-2">
        <span className="qr-head pl-1 mt-2">IDFC FIRST Bank app</span>
        <span className="d-head">Download the App and Start Saving Today</span>
        <p className="d-desc mt-1">
          No more missing out on amazing deals. Just download the IDFC FIRST
          Bank app to unlock great offers on travel, shopping, and gift cards.
        </p>
      </div>

      <div className="flex-shrink-0 mr-4 d-width-3">
        <img
          src={QrRightImg}
          alt="Hand holding phone"
          className="right-img-sz"
        />
      </div>
    </div>
  );
};

export default WebDownloadApp;

import React from "react";
const PageError = ({ pageError }) => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center p-6 max-w-sm bg-white rounded-lg ">
        <div className="flex justify-center mb-4">
          <svg
            className="w-16 h-16 text-blue-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9.172 9.172a4 4 0 105.656 5.656M15 7h.01M19.418 12.593A9 9 0 116.583 4.584a9 9 0 0112.835 8.009z"
            />
          </svg>
        </div>
        <h1 className="text-6xl font-bold text-blue-600">
          {pageError?.StatusCode}
        </h1>

        <p className="text-lg text-gray-800 mt-2">{pageError?.ErrorName}</p>
        <p className="text-gray-500 mt-1">{pageError?.ErrorDesription}</p>
        <a
          href={pageError?.url}
          className="mt-5 inline-block px-6 py-3 text-sm font-semibold text-white bg-blue-500 hover:bg-blue-600 rounded-lg shadow transition duration-200 ease-in-out"
        >
          {pageError?.buttonText}
        </a>
      </div>
    </div>
  );
};

export default PageError;

import { createSlice } from "@reduxjs/toolkit";

export const sectionContentMasterSlice = createSlice({
  name: "section-content-master",
  initialState: {
    isLoading: false,
    isError: false,
    sectionContentMasterData: [],
    message: "",
  },
  reducers: {
    onGetSectionContentMaster: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getSectionContentMasterData: [],
        getmessage: "",
      };
    },

    onGetSectionContentMasterSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getSectionContentMasterData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetSectionContentMasterError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getSectionContentMasterData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },

    onPostSectionContentMaster: (state) => {
      return {
        ...state,
        isPostLoading: true,
        postSectionContentMasterData: [],
      };
    }
  }
});

export const {
  onGetSectionContentMaster,
  onGetSectionContentMasterSuccess,
  onGetSectionContentMasterError
} = sectionContentMasterSlice.actions;

export default sectionContentMasterSlice.reducer;


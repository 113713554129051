import { call, put, takeLatest } from "redux-saga/effects";
import { generateTokenApi } from "../Context/generateTokenApi";
import { onGenerateTokenSubmit, onGenerateTokenSubmitError, onGenerateTokenSubmitSuccess } from "../Store/Slices/generateTokenSlice";

function* generateToken({ payload }) {
  try {
    const generateTokenResponse = yield call(generateTokenApi, payload);
    if (generateTokenResponse.responseCode === "200") {
      yield put(
        onGenerateTokenSubmitSuccess({
          data: generateTokenResponse.response,
        })
      );
    } else {
      yield put(
        onGenerateTokenSubmitError({
          data: generateTokenResponse.response,
          message: generateTokenResponse.errorMessage,
          status_code: generateTokenResponse.httpStatusCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGenerateTokenSubmitError({ data: {}, message, status_code: error?.response?.data?.httpStatusCode }));
  }
}
export default function* generateTokenSaga() {
  yield takeLatest(onGenerateTokenSubmit.type, generateToken);
}

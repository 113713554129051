import React from "react";
import "./index.scss";
import Auth from "./Components/Auth/Auth";
const App = () => {
  return (
    <>
      <Auth />
    </>
  );
};

export default App;

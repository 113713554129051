import React from "react";

const WebSupportingBanner = () => {
  const cards = [
    {
      title: "Phone a Reward",
      subtitle: "Buy your next phone with Points",
      image: "https://via.placeholder.com/100", 
      bgColor: "bg-gradient-to-r from-[#B9502A] to-[#D16D35]",
    },
    {
      title: "Samsung Galaxy S24+",
      subtitle: "₹74,999 - 10% \n₹67,500",
      image: "https://via.placeholder.com/100", 
      bgColor: "bg-gradient-to-r from-gray-800 to-gray-600",
    },
  ];

  return (
    <div className="p-6 mb-4">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">
        Smart Phones, Smarter Rewards
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`flex flex-col justify-between ${card.bgColor} rounded-lg p-6 text-white relative shadow-lg`}
          >
            
            <div>
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-sm font-light">{card.title}</h3>
                  <p className="text-lg font-semibold">{card.subtitle}</p>
                </div>
                <span className="text-xs">Shopsta</span>
              </div>
            </div>
            
            <div className="flex justify-between items-end">
              <div>
                <button className="text-white border border-white rounded-full p-1">
                  →
                </button>
              </div>
              <img
                src={card.image}
                alt={card.title}
                tilte={card.title}
                className="absolute right-4 bottom-4 w-24"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebSupportingBanner;

import { call, put, takeLatest } from "redux-saga/effects";
import { onGetVoucherHistory, onGetVoucherHistoryError, onGetVoucherHistorySuccess } from "../Store/Slices/voucherHistorySlice";
import { callVoucherHistoryGetApi } from "../Context/voucherHistoryApi";

function* GetVoucherHistory({payload}) {
  try {
    const getVoucherHistoryResponse = yield call(callVoucherHistoryGetApi,payload);
    if (getVoucherHistoryResponse.responseCode === "200") {
      yield put(
        onGetVoucherHistorySuccess({
          data: getVoucherHistoryResponse.response,
          message: getVoucherHistoryResponse.responseMessage,
          status_code:getVoucherHistoryResponse.responseCode
        })
      );
    } else {
      yield put(
        onGetVoucherHistoryError({
          data: getVoucherHistoryResponse.response,
          message: getVoucherHistoryResponse.response.message,
          status_code:getVoucherHistoryResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetVoucherHistoryError({ data: [], message, status_code: 400 }));
  }
}
export default function* voucherHistorySaga() {
  yield takeLatest(onGetVoucherHistory.type, GetVoucherHistory);
}

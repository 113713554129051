/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  onGenerateTokenSubmit,
  onGenerateTokenSubmitReset,
} from "../../Store/Slices/generateTokenSlice";
import axiosIntegration from "../../Common/Axios/axiosIntegration";
import axiosService from "../../Common/Axios/axiosService";
import {
  onValidateLoginTokenReset,
  onValidateLoginTokenSubmit,
} from "../../Store/Slices/validateLoginTokenSlice";
import {
  onGetMember,
  onGetMemberReset,
} from "../../Store/Slices/getMemberSlice";
import Loader from "../../Utility/Loader/Loader";
import API from "../../Common/Endpoint/serviceConstrants";
import {
  onSegmentReset,
  onSegmentSubmit,
} from "../../Store/Slices/SegmentSlice";
import {
  ApiConfigData,
  ApiConfigDataLoading,
  GetSegment,
  ThemeDataLoading,
} from "../../Utility/Utility";
import { onSaveCards } from "../../Store/Slices/utilityReducer";

const LoginPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = ThemeDataLoading();
  const apiconfig = ApiConfigData();
  const apiconfigLoading = ApiConfigDataLoading();
  const partnerCode = apiconfig?.find(
    (data) => data?.paramsKey === "partnerCode"
  )?.paramsValue;
  const accessKey = apiconfig?.find(
    (data) => data?.paramsKey === "accessKey"
  )?.paramsValue;
  const secrateKey = apiconfig?.find(
    (data) => data?.paramsKey === "secrateKey"
  )?.paramsValue;
  const clientCode = apiconfig?.find(
    (data) => data?.paramsKey === "clientCode"
  )?.paramsValue;
  const integrationSecretKey = apiconfig?.find(
    (data) => data?.paramsKey === "integrationSecretKey"
  )?.paramsValue;
  const generateToken = useSelector((state) => state.generateTokenSliceReducer);
  const loginToken = useSelector(
    (state) => state.validateLoginTokenSliceReducer
  );
  const defaultCard = loginToken?.data?.subIdentifier;
  const segment = GetSegment();

  const [apiError, setApiError] = useState(false);
  const memberData = useSelector((state) => state.getMemberSliceReducer);
  const cards = memberData?.MemberData?.otherListAttribute
    ?.filter((cardData) => cardData?.valueIndetifier === "CardDetails")
    ?.flatMap((cardData) => cardData?.valueDetails)
    ?.filter((cardData) => cardData.status !== "0");

  const finalCredicards = cards?.filter(
    (card) => card?.identifiertype === "1002"
  );
  const finalDebitcards = cards?.filter(
    (card) => card?.identifiertype === "1001"
  );
  const urlIndex = location.pathname.split("/");

  const [accountData, setAccountData] = useState([]);
  const [isApiCallsDone, setIsApiCallsDone] = useState(false);
  const callSwitchAccountApi = async (payload) => {
    try {
      const { data = [] } = await axiosIntegration.post(
        API.switchAccountApi,
        payload,
        {
          headers: {
            clientCode: clientCode,
            partnerCode: partnerCode,
          },
        }
      );

      setAccountData((prevData) => [...prevData, data]);
      setApiError(false);
    } catch (error) {
      setApiError(true);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  // Set session tokens on initial load
  useEffect(() => {
    sessionStorage.setItem("loginSessionToken", urlIndex[2]);
    sessionStorage.setItem("temporaryToken", urlIndex[3]);
    sessionStorage.setItem("login", true);
    if (partnerCode) {
      dispatch(
        onGenerateTokenSubmit({
          partnerCode: partnerCode,
          clientCode: clientCode,
          accessKey: accessKey,
          secrateKey: secrateKey,
        })
      );
    }
  }, [partnerCode]);

  // Handle generate token response
  useEffect(() => {
    if (generateToken?.status_code === 200) {
      axiosIntegration.defaults.headers[
        "access-token"
      ] = `${generateToken?.data?.accessToken}`;
      dispatch(
        onValidateLoginTokenSubmit({
          loginSessionToken: sessionStorage.getItem("loginSessionToken"),
          integrationSecretKey: integrationSecretKey,
          temporaryToken: sessionStorage.getItem("temporaryToken"),
          urlReferrer: "",
          clientCode: clientCode,
          partnerCode: partnerCode,
        })
      );
      dispatch(onGenerateTokenSubmitReset());
    }
  }, [generateToken]);
  // Handle login token validation response
  useEffect(() => {
    if (loginToken?.status_code === "200") {
      dispatch(
        onGetMember({
          memberIdentifier: loginToken?.data?.memberIdentifier,
          identifierType: loginToken?.data?.identifierType,
          clientCode: clientCode,
          partnerCode: partnerCode,
        })
      );
      dispatch(onValidateLoginTokenReset());
    }
  }, [loginToken]);

  // Handle member data and call the switch account API for each card
  useEffect(() => {
    if (memberData?.get_status_code === "200" && memberData?.MemberData) {
      axiosService.defaults.headers[
        "access-token"
      ] = `${generateToken?.data?.accessToken}`;
      dispatch(
        onSegmentSubmit({
          clientCode: clientCode,
          partnerCode: partnerCode,
          loginToken: loginToken?.data?.memberIdentifier,
        })
      );
      dispatch(onGetMemberReset());
    }
  }, [memberData]);
  useEffect(() => {
    if (
      segment.status_code === "200" ||
      segment.status_code === "400" ||
      segment.status_code === 400
    ) {
      setIsLoading(true);

      if (!cards || cards.length === 0) {
        setIsApiCallsDone(true);
        setIsLoading(false);
        dispatch(onSegmentReset());
        return;
      }

      const fetchCardData = (cardsList) => {
        return cardsList.map((cardData) =>
          callSwitchAccountApi({
            identifier: loginToken?.data?.memberIdentifier,
            subIdentifier: cardData?.identifier,
            identifierType: parseInt(cardData?.identifiertype),
            userSessionToken: sessionStorage.getItem("temporaryToken"),
            isAccountSwitch: false,
          })
        );
      };

      const targetCards = finalCredicards.length
        ? finalCredicards
        : finalDebitcards;
      const cardToFilter =
        defaultCard !== null
          ? targetCards.filter((card) => card?.identifier === defaultCard)
          : targetCards.filter((_, index) => index === 0);

      const cardPromises = fetchCardData(cardToFilter);

      Promise.all(cardPromises).then(() => {
        setIsApiCallsDone(true);
        setIsLoading(false);
        dispatch(onSegmentReset());
      });
    }
  }, [
    segment,
    finalCredicards,
    finalDebitcards,
    defaultCard,
    cards,
    loginToken,
  ]);

  useEffect(() => {
    if (isApiCallsDone) {
      const redirectPath = loginToken?.data?.redirectPath;

      if (redirectPath) {
        try {
          const decodedPath = atob(redirectPath);
          navigate(
            decodedPath.startsWith("/") ? decodedPath : `/${decodedPath}`
          );
        } catch (error) {
          console.error("Invalid redirect path:", error);
          navigate("/");
        }
      } else {
        navigate("/");
      }

      dispatch(
        onSaveCards({
          creditCards: cards?.map((card, index) => {
            const matchingAccount = accountData?.find(
              (account) => account.response.subIdentifier === card.identifier
            );
            return {
              ...card,
              pointBalance:
                matchingAccount?.response?.memberBalance?.pointBalance,
              totalPointBalance:
                matchingAccount?.response?.memberBalance?.totalPointBalance,
              showViewPoint: false,
              showTryAgain:
                (defaultCard !== null &&
                  defaultCard === card.identifier &&
                  apiError) ||
                (index === 0 && apiError),
            };
          }),
          debitCards: cards?.filter((card) => card?.identifiertype === "1001"),
        })
      );
    }
  }, [isApiCallsDone, navigate]);

  return (
    <div>
      {(generateToken?.isLoading ||
        segment?.isLoading ||
        loginToken?.isLoading ||
        memberData?.isgetLoading ||
        theme ||
        apiconfigLoading ||
        isLoading) && <Loader />}
    </div>
  );
};

export default LoginPage;

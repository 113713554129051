import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../../Utility/ScrollToTop/ScrollToTop";
import { DealCouponData } from "../../Utility/Utility";

const TermsAndCondition = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dealCouponData } = DealCouponData();
  const searchParams = new URLSearchParams(location.search);
  const couponId = searchParams.get("couponId");

  const TermsAndCondition = dealCouponData?.find(
    (coupon) => coupon.id === Number(couponId)
  );
  return (
    <>
      <ScrollToTop />
      <div
        id="top_offer_main_wrapper_container"
        className=" top_offer_main_wrapper_container"
      >
        <div className="row ">
          <div className="offer_title mobile_heading sticky top-0 z-10 bg-white">
            <h3 className="hide_desktop">
              <Link
                onClick={() => navigate(-1)}
                className="relative flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-8 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10 19l-7-7 7-7"
                  />
                  <line
                    x1="3"
                    y1="12"
                    x2="21"
                    y2="12"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              </Link>
            </h3>
            <h3>Terms and Conditions</h3>
            <div></div>
          </div>

          <div className="data-container">

            <div className="font-bold text-xl">{TermsAndCondition?.title} </div>
            <div
              dangerouslySetInnerHTML={{ __html: TermsAndCondition?.terms }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;

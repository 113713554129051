import { createSlice } from "@reduxjs/toolkit";

export const voucherHistorySlice = createSlice({
  name: "voucher-history",
  initialState: {
    isLoading: false,
    isError: false,
    voucherHistoryData: [],
    message: "",
  },
  reducers: {
    onGetVoucherHistory: (state) => {
      return {
        ...state,
        isgetLoading: true,
        voucherHistoryData: [],
        getmessage: "",
      };
    },

    onGetVoucherHistorySuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        voucherHistoryData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetVoucherHistoryError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        voucherHistoryData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    }
  }
});

export const {
  onGetVoucherHistory,
  onGetVoucherHistorySuccess,
  onGetVoucherHistoryError
} = voucherHistorySlice.actions;

export default voucherHistorySlice.reducer;

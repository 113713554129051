import React, { useEffect } from "react";
import {
  SectionContentMasterData,
  SectionMasterData,
  ThemeData,
} from "../../Utility/Utility";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../../Utility/ScrollToTop/ScrollToTop";

const ContactUs = () => {
  const login = sessionStorage.getItem("login");

  const { sectionMasterData } = SectionMasterData(login);
  const { sctionContentdata } = SectionContentMasterData();
  const themeData = ThemeData();
  const location = useLocation();
  const isValidColor = (color) => {
    const newStyle = new Option().style;
    newStyle.color = color;
    return newStyle.color !== "";
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--bs-white-2",
      isValidColor(themeData?.commonhgElementSurfaceColor)
        ? themeData?.commonElementSurfaceColor
        : "#9D1D27"
    );
    document.documentElement.style.setProperty(
      "--bs-light-1-black",
      isValidColor(themeData?.headerTextColor)
        ? themeData?.headerTextColor
        : "#222222"
    );
    document.documentElement.style.setProperty(
      "--bs-white",
      isValidColor(themeData?.headerBackgroundColor)
        ? themeData?.headerBackgroundColor
        : "#fff"
    );
    document.documentElement.style.setProperty(
      "--bs-black-3",
      isValidColor(themeData?.headerBorderColor)
        ? themeData?.headerBorderColor
        : "#fffff"
    );
    document.documentElement.style.setProperty(
      "--bs-white-7",
      isValidColor(themeData?.primaryBgColor)
        ? themeData?.primaryBgColor
        : "#fff"
    );
    document.documentElement.style.setProperty(
      "--bs-light-2-black",
      isValidColor(themeData?.primaryText) ? themeData?.primaryText : "#222222"
    );
    document.documentElement.style.setProperty(
      "--bs-light-4-black",
      isValidColor(themeData?.secondaryText)
        ? themeData?.secondaryText
        : "#222222"
    );
    document.documentElement.style.setProperty(
      "--primary-green",
      isValidColor(themeData?.earnElementColor)
        ? themeData?.earnElementColor
        : "#00AE4E"
    );
    document.documentElement.style.setProperty(
      "--earn-bg",
      isValidColor(themeData?.earnSurfaceColor)
        ? themeData?.earnSurfaceColor
        : "#E5F7ED"
    );
    document.documentElement.style.setProperty(
      "--bs-card-name",
      isValidColor(themeData?.cardNameTextColor)
        ? themeData?.cardNameTextColor
        : "#222222"
    );
    document.documentElement.style.setProperty(
      "--bs-dark-grey-2",
      isValidColor(themeData?.cardNumberTextColor)
        ? themeData?.cardNumberTextColor
        : "#9D1D27"
    );
    document.documentElement.style.setProperty(
      "card-points",
      isValidColor(themeData?.cardPointTextColor)
        ? themeData?.cardPointTextColor
        : "#ff496a"
    );
    document.documentElement.style.setProperty(
      "--bs-white-2",
      isValidColor(themeData?.commonElementBackgroundColor)
        ? themeData?.commonElementBackgroundColor
        : "#222222"
    );
    document.documentElement.style.setProperty(
      "--bs-red-2",
      isValidColor(themeData?.commonElementBorderColor)
        ? themeData?.commonElementBorderColor
        : "#a07373"
    );
    document.documentElement.style.setProperty(
      "--bs-red-2",
      isValidColor(themeData?.commonElementTextColor)
        ? themeData?.commonElementTextColor
        : "#a07373"
    );
  }, [themeData]);


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);
  const sectionFilter = sectionMasterData?.find(
    (findData) => findData?.sectionType === "Footer"
  );

  const sectionContent = sctionContentdata?.find(
    (findData) =>
      findData?.sectionMasterId === sectionFilter?.id &&
      findData.contentSourceType === "Contact Us"
  );
  return (
    <>
      <ScrollToTop />
      <div className="contact-container">
        <div className="px-4 py-4">
          {location?.state?.commonComponent ? (
            <span
              dangerouslySetInnerHTML={{
                __html: location?.state?.Content,
              }}
            />
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: sectionContent?.text,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContactUs;

import { createSlice } from "@reduxjs/toolkit";

export const NavConfigurationSlice = createSlice({
  name: "nav-configuration",
  initialState: {
    isLoading: false,
    isError: false,
    navConfigureData: [],
    message: "",
    isgetLoading: true,
  },
  reducers: {
    onGetNavConfigure: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getNavConfigureData: [],
        getmessage: "",
      };
    },

    onGetNavConfigureSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getNavConfigureData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetNavConfigureError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getNavConfigureData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
  },
});

export const {
  onGetNavConfigure,
  onGetNavConfigureSuccess,
  onGetNavConfigureError
} = NavConfigurationSlice.actions;

export default NavConfigurationSlice.reducer;

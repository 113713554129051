/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import copyIcon from "../../Assets/img/copy_Image.png";
// import NewCouponModal from "./NewCouponModal"

import {
  ApiConfigData,
  DealCouponCodeData,
  DealCouponFreqData,
  FormatDate,
} from "../../Utility/Utility";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../Utility/Loader/Loader";
import API from "../../Common/Endpoint/serviceConstrants";
import axiosIntegration from "../../Common/Axios/axiosIntegration";
import {

  onPostDealCouponCode,
} from "../../Store/Slices/dealCouponCodeSlice";
import { decryptAES } from "../../Common/Axios/encrypt";

const CouponModal = ({ onCouponClose, couponData, dealImage }) => {
  const { dealCouponFreqData } = DealCouponFreqData();
  const disptach = useDispatch();
  const generateToken = useSelector((state) => state.generateTokenSliceReducer);
  const apiconfig = ApiConfigData();
  const clientCode = apiconfig?.find(
    (data) => data?.paramsKey === "clientCode"
  )?.paramsValue;
  const partnerCode = apiconfig?.find(
    (data) => data?.paramsKey === "partnerCode"
  )?.paramsValue;

  const fetchEmailTemplate = async () => {
    const response = await fetch("./lifestyle.text"); // Adjust path as needed
    const template = await response.text();
    return template;
  };
  const replacePlaceholders = (template, replacements) => {
    return template.replace(/{{(.*?)}}/g, (_, key) => replacements[key]);
  };
  const { dealCouponCodeData, isDealCouponCodePostLoading } =
    DealCouponCodeData();
  const [openAccordion, setOpenAccordion] = useState(null);
  const [openTermsCondition, setTermsCondition] = useState(null);
  const [copied, setCopied] = useState(null);
  const [showCopyDiv, setShowCopyDiv] = useState(null);
  // to get dealCouponCode Loader
  const toggleAccordion = (id) => {
    setOpenAccordion(openAccordion === id ? null : id);
  };
  const expandTerms = (id) => {
    setTermsCondition(openTermsCondition === id ? null : id);
  };
  // to handle copy coupon code
  const handleCopy = (coupon) => {
    let couponCode = "";
    if (coupon?.typeOfCoupoun === "Static") {
      couponCode = coupon?.coupounCode;
    } else if (coupon?.typeOfCoupoun === "Dynamic") {
      const dynamicCouponCode = dealCouponCodeData.find(
        (deal) => deal.dealCoupounId === coupon.id && deal?.status === "Active"
      );
      couponCode = decryptAES(dynamicCouponCode?.coupounCode);
    }
    if (couponCode) {
      navigator.clipboard.writeText(couponCode);
      setCopied(coupon.id);
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setCopied(null);
        document.body.style.overflow = "hidden";
      }, 3000);
    }
  };

  // Prevent background scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  //to get coupon code from deal coupon code based on couponId and dealId
  const getCouponCode = (couponId) => {
    const coupon = dealCouponCodeData.find(
      (deal) => deal.dealCoupounId === couponId && deal?.status === "Active"
    );
    return decryptAES(coupon?.coupounCode);
  };
  //to get coupon code from deal coupon based on couponId and dealId
  const getCouponCodeFromCoupon = (coupon) => {
    const couponCode = coupon?.coupounCode;

    return couponCode;
  };

  const sendMail = async (payload) => {
    axiosIntegration.defaults.headers[
      "access-token"
    ] = `${generateToken?.data?.accessToken}`;

    try {
      await axiosIntegration.post(API.mailApi, payload, {
        headers: {
          clientCode,
          partnerCode,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  };
  const memberDataReducer = useSelector((state) => state.getMemberSliceReducer);
  const memberData = memberDataReducer?.MemberData?.memberProfile;

  const updateCouponCode = async (e, coupon) => {
    e.preventDefault();
    const codeData = dealCouponCodeData?.find(
      (code) => code?.dealCoupounId === coupon?.id && code?.status === "Active"
    );
    const couponCode = decryptAES(codeData?.coupounCode);
    const couponTitle = coupon?.title;
    const validUpto = FormatDate(codeData?.endDate);
    const cta = coupon?.cta;
    const dealImg = `${process.env.REACT_APP_CLIENT_IMAGE_URL}${dealImage?.mobImage}`;
    const dealName = dealImage?.name;
    const template = await fetchEmailTemplate();
    const htmlContent = replacePlaceholders(template, {
      couponCode,
      couponTitle,
      validUpto,
      cta,
      dealImg,
      dealName,
    });
    const payload = {
      toEmailId: memberData?.emailId,
      templateCode: "ETS002",
      content: htmlContent,
      subject: "Special Offer Claim",
      contentValue: {
        UserName: "",
      },
    };

    try {
      sendMail(payload);
    } catch (error) {
      console.error(error);
    }
    setShowCopyDiv(coupon?.id);
    disptach(
      onPostDealCouponCode({
        id: codeData?.id,
        enabled: true,
        dealId: codeData?.dealId,
        dealCoupounId: codeData?.dealCoupounId,
        descriptions: "22",
        status: "Used",
        coupounCode: codeData?.coupounCode,
        updatedBy: 23,
      })
    );
  };

  const openInExternalBrowser = (url) => {
    const isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    if (isChrome) {
      const chromeUrl = `googlechrome://navigate?url=${url}`;
      window.location.href = chromeUrl;
    } else {
      window.open(url, "_blank");
    }
  };

  const handleRedeem = (coupon) => {
    if (coupon?.typeOfCoupoun === "Dynamic") {
      onCouponClose();
    }
    openInExternalBrowser(coupon?.cta);
  };
  const sortedData = [
    ...couponData?.filter((item) => item.source?.toLowerCase() === "bank"),
    ...couponData?.filter((item) => item.source?.toLowerCase() !== "bank"),
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        window.innerWidth >= 1200 &&
        !event.target.closest(".coupon-modal-container")
      ) {
        onCouponClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40 coupon-modal-overlay"
        onClick={onCouponClose}
      ></div>
      <div
        className={`coupon-modal-container ${
          couponData?.length > 1 ? "he-80" : ""
        }`}
      >
        <div className="bg-white rounded-t-2xl w-full h-full p-4 shadow-lg flex flex-col overflow-hidden">
          <div className="flex justify-between items-center mb-4 sticky top-0 bg-white z-10">
            <h2 className="text-xl coupon-head font-semibold">
              {couponData?.length}{" "}
              {couponData?.length === 1 ? "Coupon" : "Coupons"}
            </h2>
            <button className="text-gray-600 text-2xl" onClick={onCouponClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="overflow-y-auto">
            {sortedData.map((coupon, index) => {
              const expiryDate = dealCouponFreqData?.find(
                (code) => code?.dealCoupounId === coupon?.id
              )?.validUpto;

              return isDealCouponCodePostLoading ? (
                <Loader key={index} />
              ) : (
                <div
                  key={coupon.id}
                  className={`mb-12 relative ${index === 0 ? "mt-8" : ""}`}
                >
                  <div className="sub-container relative coupon-bg-img">
                    <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 coupon-img flex items-center justify-center">
                      <img
                        src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${dealImage?.commonImage}`}
                        alt={coupon?.title}
                        title={coupon?.title}
                        className="h-20 w-20 coupon-img"
                      />
                    </div>
                    {/* Coupon title */}
                    <div className="pt-5">
                      <p className="text-center custom-coupon-heading pt-4">
                        {coupon?.title?.replace(/^"|"$/g, "")}
                      </p>
                      {/* Coupon code section */}
                      {coupon?.typeOfCoupoun !== "NoCode" && (
                        <div className="absolute left-1/2 transform -translate-x-1/2 w-5/6 p-2 coupon-code-field coupon-code-sz mt-3">
                          {copied === coupon.id && (
                            <div className="absolute top-[-2rem] left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg z-50 whitespace-nowrap">
                              Copied successfully!
                            </div>
                          )}
                          {coupon?.typeOfCoupoun === "Dynamic" ? (
                            showCopyDiv === coupon.id ? (
                              <>
                                <input
                                  type="text"
                                  value={getCouponCode(coupon.id)}
                                  className="w-full bg-transparent custom-coupon-heading"
                                  readOnly
                                />
                                <button
                                  className="absolute right-0 copy-icon"
                                  onClick={() => handleCopy(coupon)}
                                >
                                  <img
                                    src={copyIcon}
                                    alt="Copy Icon"
                                    className="h-5 w-5"
                                  />
                                </button>
                              </>
                            ) : (
                              <button
                                className="w-full bg-[#9B1E26] text-white gen-code rounded-md"
                                onClick={(e) => updateCouponCode(e, coupon)}
                              >
                                Generate Code
                              </button>
                            )
                          ) : (
                            <>
                              <input
                                type="text"
                                value={getCouponCodeFromCoupon(coupon)}
                                className="w-full bg-transparent custom-coupon-heading"
                                readOnly
                              />
                              <button
                                className="absolute right-0 copy-icon"
                                onClick={() => handleCopy(coupon)}
                              >
                                <img
                                  src={copyIcon}
                                  alt="Copy Icon"
                                  className="h-5 w-5"
                                />
                              </button>
                            </>
                          )}
                        </div>
                      )}
                      <p className="text-sm text-gray-600 text-center mt-14"></p>
                    </div>
                  </div>

                  {/* Coupon details */}
                  <div className="p-4 bg-white">
                    <p className="custom-coupon-validation">
                      Expiry date: {FormatDate(expiryDate)}
                    </p>
                    <button
                      className="w-full text-left flex justify-between items-center custom-view-details"
                      onClick={() => toggleAccordion(coupon.id)}
                    >
                      View Details
                      <svg
                        className={`h-4 w-4 transform transition-transform ${
                          openAccordion === coupon.id ? "rotate-180" : ""
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>
                    {openAccordion === coupon.id && (
                      <>
                        <div
                          className="mt-2 text-sm text-gray-700 p-3"
                          dangerouslySetInnerHTML={{
                            __html: coupon?.description,
                          }}
                        ></div>
                        <div>
                          <button
                            className="terms-condition flex"
                            onClick={() => expandTerms(coupon.id)}
                          >
                            Terms and Conditions
                            <svg
                              className={`h-6 w-4 transform transition-transform  ${
                                openTermsCondition === coupon.id
                                  ? "rotate-180"
                                  : ""
                              }`}
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </button>
                          {openTermsCondition === coupon.id && (
                            <div
                              className="mt-2 text-sm text-gray-700 p-3"
                              dangerouslySetInnerHTML={{
                                __html: coupon?.terms,
                              }}
                            ></div>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  {/* Redeem button */}
                  <div className="redeem-btn-container">
                    <button
                      onClick={() => {
                        handleRedeem(coupon);
                      }}
                      className="text-white py-2 redeem-btn-sz"
                    >
                      Redeem
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponModal;

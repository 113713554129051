import { call, put, takeLatest } from "redux-saga/effects";
import { callDealCouponGetApi } from "../Context/dealCouponApi";
import { onGetDealCoupon, onGetDealCouponError, onGetDealCouponSuccess } from "../Store/Slices/dealCouponSlice";

function* GetDealCoupon({payload}) {
  try {
    const getDealCouponResponse = yield call(callDealCouponGetApi,payload);
    if (getDealCouponResponse.responseCode === "200") {
      yield put(
        onGetDealCouponSuccess({
          data: getDealCouponResponse.response,
          message: getDealCouponResponse.responseMessage,
          status_code:getDealCouponResponse.responseCode
        })
      );
    } else {
      yield put(
        onGetDealCouponError({
          data: getDealCouponResponse.response,
          message: getDealCouponResponse.response.message,
          status_code:getDealCouponResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetDealCouponError({ data: [], message, status_code: 400 }));
  }
}
export default function* dealCouponSaga() {
  yield takeLatest(onGetDealCoupon.type, GetDealCoupon);
}

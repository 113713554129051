import { createSlice } from "@reduxjs/toolkit";

export const dealCouponCodeSlice = createSlice({
  name: "deal-coupon-code",
  initialState: {
    isLoading: false,
    isError: false,
    dealCouponCodeData: [],
    message: "",
  },
  reducers: {
    onGetDealCouponCode: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getDealCouponCodeData: [],
        getmessage: "",
      };
    },

    onGetDealCouponCodeSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getDealCouponCodeData: data,
        getmessage: message,
        get_status_code: status_code,
      };
    },

    onGetDealCouponCodeError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getDealCouponCodeData: data,
        getmessage: message,
        get_status_code: status_code,
        isgetLoading: false,
      };
    },
    onGetDealCouponCodeActive: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getDealCouponCodeActiveData: [],
        getmessage: "",
      };
    },

    onGetDealCouponCodeActiveSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getDealCouponCodeActiveData: data,
        getmessage: message,
        get_status_code: status_code,
      };
    },

    onGetDealCouponCodeActiveError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getDealCouponCodeActiveData: data,
        getmessage: message,
        get_status_code: status_code,
        isgetLoading: false,
      };
    },
    onPostDealCouponCode: (state) => {
      return {
        ...state,
        isPostLoading: true,
        postDealCouponCodeData: [],
      };
    },

    onPostDealCouponCodeSuccess: (state, { payload }) => {
      const { postData = [], message = "", status_code = "200" } = payload;
      return {
        ...state,
        isPostLoading: false,
        postDealCouponCodeData: postData,
        postMessage: message,
        post_status_code: status_code,
      };
    },

    onPostDealCouponCodeError: (state, { payload }) => {
      const { postData = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        postDealCouponCodeData: postData,
        postMessage: message,
        post_status_code: status_code,
        isPostLoading: false,
      };
    },
    onPostDealCouponCodeReset: (state) => {
      return {
        ...state,
        postMessage: "",
        post_status_code: null,
      };
    },
    onPostDealCodeReset: (state) => {
      return {
        ...state,
        postMessage: "",
        postDealCouponCodeData:[],
        post_status_code: null,
      };
    },
    onGetDealCouponCodeReset: (state) => {
      return {
        ...state,
        get_status_code: [],
      };
    },
  },
});

export const {
  onGetDealCouponCode,
  onGetDealCouponCodeSuccess,
  onGetDealCouponCodeError,
  onGetDealCouponCodeActive,
  onGetDealCouponCodeActiveSuccess,
  onGetDealCouponCodeActiveError,
  onPostDealCouponCode,
  onPostDealCouponCodeSuccess,
  onPostDealCouponCodeError,
  onPostDealCouponCodeReset,
  onGetDealCouponCodeReset,onPostDealCodeReset
} = dealCouponCodeSlice.actions;

export default dealCouponCodeSlice.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onValidateLoginTokenReset } from "../../Store/Slices/validateLoginTokenSlice";
import { onGetMemberLogout } from "../../Store/Slices/getMemberSlice";
import Loader from "../../Utility/Loader/Loader";
import { ApiConfigData } from "../../Utility/Utility";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiConfigData = ApiConfigData();
  const integrationId = apiConfigData?.find(
    (data) => data?.paramsKey === "integrationId"
  )?.paramsValue;
  useEffect(() => {
    if (sessionStorage.getItem("login")) {
      sessionStorage.clear();
      window.location.href = `${
        process.env.REACT_APP_INTEGRATION_LOGIN_URL
      }Logout/${integrationId}/${sessionStorage.getItem("loginSessionToken")}`;
      dispatch(onGetMemberLogout());
      dispatch(onValidateLoginTokenReset());
    } else {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Loader />
    </>
  );
};

export default Logout;

import API from "../Common/Endpoint/serviceConstrants";
import axiosIntegration from "../Common/Axios/axiosIntegration";

export const generateTokenApi = async (payload) => {
  try {
    const response = await axiosIntegration.post(
      API.generate_token,
      {
        partnerCode: payload?.partnerCode,
        accessKey: payload?.accessKey,
        secrateKey: payload?.secrateKey,
      },
      {
        headers: {
          clientCode: payload?.clientCode,
          partnerCode: payload?.partnerCode,
        },
      }
    );
    return response.data || {};
  } catch (error) {
    console.error("Error generating token:", error);
    throw error; 
  }
};

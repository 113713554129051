import { createSlice } from "@reduxjs/toolkit";

export const sectionMasterSlice = createSlice({
  name: "section-master",
  initialState: {
    isLoading: false,
    isError: false,
    sectionMasterData: [],
    message: "",
    isgetLoading: true,
  },
  reducers: {
    onGetsectionMaster: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getsectionMasterData: [],
        getmessage: "",
      };
    },

    onGetsectionMasterSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getsectionMasterData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetsectionMasterError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getsectionMasterData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    }
  },
});

export const {
  onGetsectionMaster,
  onGetsectionMasterSuccess,
  onGetsectionMasterError
} = sectionMasterSlice.actions;

export default sectionMasterSlice.reducer;

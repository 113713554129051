import axiosIntegration from "../Common/Axios/axiosIntegration";
import axiosInstanceClient from "../Common/Axios/axiosInstanceClient";
import API from "../Common/Endpoint/serviceConstrants";

export const callGetMemberApi = async (payload) => {
  const { data = [] } = await axiosIntegration.get(API.getMemberApi, {
    params: {
      memberIdentifier: payload.memberIdentifier,
      identifierType: payload.identifierType,
      optionalIdentifier: payload.optionalIdentifier,
      culture: payload?.culture,
    },
    headers: {
      clientCode: payload?.clientCode,
      partnerCode: payload?.partnerCode,
    },
  });
  return data;
};

export const callGetMemberPostApi = async (payload) => {

  const { data = [] } = await axiosInstanceClient.post(API.MemberApi, payload);
  return data;
};
export const callmemberGetApi = async (payload) => {

  const { data = [] } = await axiosInstanceClient.get(API.MemberApi, {
    params: {
      memberId: payload?.memberId,
    },
  });
  return data;
};

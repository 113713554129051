import { call, put, takeLatest } from "redux-saga/effects";
import { callApiConfigGetApi } from "../Context/apiConfigApi";
import {
  onGetApiConfig,
  onGetApiConfigError,
  onGetApiConfigSuccess,
} from "../Store/Slices/apiConfigSlice";

function* GetApiConfig({payload}) {
  try {
    const getApiConfigResponse = yield call(callApiConfigGetApi,payload);
    if (getApiConfigResponse.responseCode === "200") {
      yield put(
        onGetApiConfigSuccess({
          data: getApiConfigResponse.response,
          message: getApiConfigResponse.responseMessage,
          status_code: getApiConfigResponse.responseCode,
        })
      );
    } else {
      yield put(
        onGetApiConfigError({
          data: getApiConfigResponse.response,
          message: getApiConfigResponse.response.message,
          status_code: getApiConfigResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message =
      error.response.data.responseMessage || "Something went wrong";
    yield put(onGetApiConfigError({ data: [], message, status_code: 400 }));
  }
}

export default function* apiConfigSaga() {
  yield takeLatest(onGetApiConfig.type, GetApiConfig);
}

import { createSlice } from "@reduxjs/toolkit";

export const metaDataSlice = createSlice({
  name: "meta-data",
  initialState: {
    isLoading: false,
    isError: false,
    metaData: [],
    message: "",
    isgetLoading: true,
  },
  reducers: {
    onGetMetaData: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getMetaData: [],
        getmessage: "",
      };
    },

    onGetMetaDataSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getMetaData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetMetaDataError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getMetaData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    }
  }
});

export const {
  onGetMetaData,
  onGetMetaDataSuccess,
  onGetMetaDataError
} = metaDataSlice.actions;

export default metaDataSlice.reducer;

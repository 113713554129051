import { createSlice } from "@reduxjs/toolkit";

export const generateTokenSlice = createSlice({
  name: "generateToken",
  initialState: {
    isLoading: false,
    isError: false,
    data: {},
    error: {},
    message: "",
  },
  reducers: {
    onGenerateTokenSubmit: (state) => {
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: {},
        message: "",
      };
    },

    onGenerateTokenSubmitSuccess: (state, { payload }) => {
      const { data = {}, message = "", status_code = 200 } = payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
        message,
        status_code
      };
    },

    onGenerateTokenSubmitError: (state, { payload }) => {
      const { data = {}, message = "", status_code = 400 } = payload;
      return {
        ...state,
        data,
        message,
        status_code,
        isLoading: false,
        isError: true,
      };
    },
    onGenerateTokenSubmitReset: (state) => {
      return {
        ...state,
        message:"",
        status_code:null,
        isLoading: false,
        isError: false,
      };
    },
  },
});
export const { onGenerateTokenSubmit, onGenerateTokenSubmitError, onGenerateTokenSubmitSuccess, onGenerateTokenSubmitReset } =
generateTokenSlice.actions;

export default generateTokenSlice.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BonusRedeem from "../BonusRedeem/BonusRedeem";
import {
  ApiConfigData,
  DealCategoryData,
  DealCouponFreqData,
  GetSegment,
  SectionContentMasterData,
  UtilityReducer,
} from "../../Utility/Utility";
import { useDispatch, useSelector } from "react-redux";
import {
  onPostDealCouponCode,
  onPostDealCouponCodeReset,
} from "../../Store/Slices/dealCouponCodeSlice";
import {
  onGetUnlockSection,
  onPostUnlockSectionReset,
  onPosUnlockSection,
} from "../../Store/Slices/unlockSectionSlice";
// import ClaimedModal from "./ClaimedModal";
// import RedeemFailedModal from "./RedeemFailedModal";
import axiosIntegration from "../../Common/Axios/axiosIntegration";
import API from "../../Common/Endpoint/serviceConstrants";
import {
  onPostOfferBenefits,
  onPostOfferBenefitsReset,
} from "../../Store/Slices/offerNdBenefitsSlice";
import RedeemFailedModal from "./RedeemFailedModal";
import { decryptAES } from "../../Common/Axios/encrypt";

const GiftVoucher = () => {
  const [isLoading, setIsLoading] = useState(false);
  const segmentData = GetSegment();
  const { dealCouponFreqData } = DealCouponFreqData();
  const [error, setError] = useState(false);
  const segments = segmentData?.data?.[0]?.mappedSegment;

  const pharmeasy = async () => {
    const response = await fetch("./pharmeasy.txt");
    const template = await response.text();
    return template;
  };
  const relianDigi = async () => {
    const response = await fetch("./relianceDigital.txt");
    const template = await response.text();
    return template;
  };
  const lenskart = async () => {
    const response = await fetch("./lenskart.txt");
    const template = await response.text();
    return template;
  };
  const yatra = async () => {
    const response = await fetch("./yatra.txt");
    const template = await response.text();
    return template;
  };
  const eazydiner = async () => {
    const response = await fetch("./eazydiner.txt");
    const template = await response.text();
    return template;
  };
  const apiconfig = ApiConfigData();
  const clientCode = apiconfig?.find(
    (data) => data?.paramsKey === "clientCode"
  )?.paramsValue;
  const partnerCode = apiconfig?.find(
    (data) => data?.paramsKey === "partnerCode"
  )?.paramsValue;
  const generateToken = useSelector((state) => state.generateTokenSliceReducer);

  const sendMail = async (payload, payloadOrder) => {
    axiosIntegration.defaults.headers[
      "access-token"
    ] = `${generateToken?.data?.accessToken}`;

    try {
      const data = await axiosIntegration.post(API.mailApi, payload, {
        headers: {
          clientCode,
          partnerCode,
        },
      });

      if (data?.status === 200) {
        dispatch(onPostOfferBenefits(payloadOrder));
      }
    } catch (error) {
      throw error;
    }
  };
  const replacePlaceholders = (template, replacements) => {
    return template.replace(/{{(.*?)}}/g, (_, key) => replacements[key]);
  };
  const location = useLocation();
  const deal = location?.state?.deal;
  const dealCoupon = location?.state?.coupons;
  const validDates = dealCouponFreqData?.find(
    (findData) => findData?.dealCoupounId === dealCoupon?.id
  );
  const { sctionContentdata } = SectionContentMasterData();
  const orderHistory = useSelector((state) => state?.offerBenefitsReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { dealCategoryData } = DealCategoryData();

  const card = UtilityReducer();

  const unlock = useSelector((state) => state?.unlockSectionReducer);

  const claimedUnits = unlock?.UnlockSectionData?.filter(
    (filterData) =>
      filterData?.sectionId === card?.sectionId && !filterData?.status
  );
  const unlockSectionContents = sctionContentdata?.filter(
    (filterData) => filterData?.sectionMasterId === card?.sectionId
  );
  const reDirectHome =
    claimedUnits?.length === unlockSectionContents?.length ||
    card.claimLimit === claimedUnits?.length;
  const dispatch = useDispatch();
  const dealData = useSelector((state) => state?.dealCouponCodeReducer);
  const identifier = useSelector(
    (state) => state?.validateLoginTokenSliceReducer?.data
  );

  const currentSegment = segments?.find(
    (filterData) => filterData?.segmentId === card?.segmentId
  )?.segmentId;
  const currentSegmentCard = segments?.find(
    (filterData) => filterData?.segmentId === card?.segmentId
  )?.subIdentifier;
  const currentCardData = card?.creditCards?.find(
    (filterData) => filterData?.identifier === currentSegmentCard
  );
  const segmentLenskart = [
    "67497528426b2ce390daa61b",
    "6799bda9c8789e438d61f016",
    "6799bdc5c8789e438d61f017",
    "6749750f426b2ce390daa61a",
    "6799c0d4d82404ae3dcdf3be",
    "6799c0b9d82404ae3dcdf3bd",
  ];
  const segmentYatra = [
    "6799c0d4d82404ae3dcdf3be",
    "6799c0b9d82404ae3dcdf3bd",
    "6749750f426b2ce390daa61a",
    "6799bdc5c8789e438d61f017",
    "6799bda9c8789e438d61f016",
    "67497528426b2ce390daa61b",
  ];
  const openModal = (e) => {
    e.preventDefault();
    const payload = {
      dealCoupounId: dealCoupon?.id,
    };
    dispatch(onPostDealCouponCode(payload));
  };
  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();

  const memberDataReducer = useSelector((state) => state.getMemberSliceReducer);

  const memberData = memberDataReducer?.MemberData?.memberProfile;

  const postUnlockSectionActivity = (coupon) => {
    dispatch(
      onPosUnlockSection({
        enabled: true,
        deleted: false,
        createdBy: 10,
        customeridentifier: identifier.memberIdentifier,
        sectionId: card?.sectionId,
        contentSourceType: "Deals",
        linkedMasterId: coupon?.id,
        activityType: "Offer Claim",
        coupounId: coupon?.id,
        offerId: deal?.id,
        status: false,
        sectionContentId: card?.sectionContentId,
      })
    );
  };
  useEffect(() => {
    if (
      dealData?.post_status_code === "200" &&
      dealData.postDealCouponCodeData
    ) {
      postUnlockSectionActivity(dealCoupon);
    } else if (
      dealData?.post_status_code === "200" &&
      !dealData.postDealCouponCodeData
    ) {
      dispatch(onPostDealCouponCodeReset());
      setError(200);
    }
  }, [dealData]);
  useEffect(() => {
    const payloadOrder = {
      enabled: true,
      createdBy: 10,
      cardImage: currentCardData?.backgroundcolor,
      cardName: currentCardData?.cardname,
      cardFirstFourDigits: currentCardData?.CardNoLast4Digit,
      cardNumber: currentCardData?.CardNoFirst6Digit,
      offerName: deal?.name,
      offerImage: deal?.mobImage,
      offerTitle: dealCoupon?.title,
      validfrom: validDates?.validFrom,
      validto: validDates?.validUpto,
      couponCode: dealData.postDealCouponCodeData,
      cta: dealCoupon?.cta,
      categoryName: "Deal",
      sectionId: card?.sectionId,
      offerId: dealCoupon?.id,
      offerLabel: card.text,
      unlockDealLogId: JSON?.stringify(unlock?.postUnlockSectionData?.[0]?.id),
      customeridentifier: identifier?.memberIdentifier,
      cardSrNo: currentCardData?.identifier,
      name: identifier.firstName,
      email: identifier?.emailId,
      merchant: deal?.name,
      eligiblityTag: "activation",
      phoneNumber: identifier?.mobileNumber,
    };

    const sendEmail = async () => {
      try {
        let selectedTemplate;
        let voucherCode;
        let title;
        let homepage;
        if (deal?.name?.toLowerCase() === "pharmeasy") {
          voucherCode = decryptAES(dealData.postDealCouponCodeData);
          selectedTemplate = await pharmeasy();
        } else if (deal?.name?.toLowerCase() === "lenskart") {
          voucherCode = decryptAES(dealData.postDealCouponCodeData);
          selectedTemplate = await lenskart();
        } else if (deal?.name?.toLowerCase() === "eazydiner") {
          voucherCode = decryptAES(dealData.postDealCouponCodeData);
          selectedTemplate = await eazydiner();
        } else if (deal?.name?.toLowerCase() === "reliance digital") {
          voucherCode = decryptAES(dealData.postDealCouponCodeData);
          title = dealCoupon?.title;
          homepage = window.location.origin;
          selectedTemplate = await relianDigi();
        } else if (deal?.name?.toLowerCase() === "yatra") {
          voucherCode = decryptAES(dealData.postDealCouponCodeData);
          title = dealCoupon?.title;
          homepage = window.location.origin;
          selectedTemplate = await yatra();
        }
        const imagepath = `${process.env.REACT_APP_CLIENT_IMAGE_URL}common`;

        const htmlContent = replacePlaceholders(selectedTemplate, {
          imagepath,
          voucherCode,
          title,
          homepage,
        });
        const payload = {
          toEmailId: memberData?.emailId,
          templateCode: "ETS002",
          content: htmlContent,
          subject:
            deal?.name?.toLowerCase() === "lenskart"
              ? "LIC IDFC FIRST Credit Card joining benefit redeemed: Lenskart Gold membership!"
              : deal?.name?.toLowerCase() === "pharmeasy"
              ? "LIC IDFC FIRST Credit Card joining benefit redeemed: Pharmeasy Plus membership!"
              : deal?.name?.toLowerCase() === "yatra"
              ? "LIC IDFC FIRST Credit Card joining benefit unlocked: Yatra discount voucher!"
              : deal?.name?.toLowerCase() === "reliance digital"
              ? "LIC IDFC FIRST Credit Card joining benefit unlocked: Reliance Digital discount voucher!"
              : "Special Claim Offer",
          contentValue: {
            UserName: "",
          },
        };
        setIsLoading(true);
        await sendMail(payload, payloadOrder);
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    if (unlock?.post_status_code === "200") {
      const lowerCaseDealName = deal?.name?.toLowerCase();

      const shouldSendEmail =
        (lowerCaseDealName === "yatra" &&
          segmentYatra.includes(currentSegment)) ||
        (lowerCaseDealName === "lenskart" &&
          segmentLenskart.includes(currentSegment)) ||
        (lowerCaseDealName === "pharmeasy" &&
          segmentLenskart.includes(currentSegment)) ||
        (lowerCaseDealName === "reliance digital" &&
          segmentYatra.includes(currentSegment));

      if (shouldSendEmail) {
        sendEmail(lowerCaseDealName);
      } else {
        dispatch(onPostOfferBenefits(payloadOrder));
      }
    }

    if (unlock?.post_status_code) {
      dispatch(onPostUnlockSectionReset());
      dispatch(onPostDealCouponCodeReset());
    }
  }, [unlock]);
  useEffect(() => {
    if (orderHistory?.post_status_code === "200") {
      dispatch(
        onGetUnlockSection({ memberIdentifier: identifier.memberIdentifier })
      );
      setIsLoading(true);
      dispatch(onPostOfferBenefitsReset());
    } else if (orderHistory?.post_status_code) {
      dispatch(onPostOfferBenefitsReset());
    }
  }, [orderHistory]);

  useEffect(() => {
    if (isLoading && unlock?.get_status_code) {
      setIsLoading(false);
      setIsModalOpen(true);
    }
  }, [unlock]);
  return (
    <>
      <div className="voucher-container">
        <div className="voucher-top">
          <Link
            onClick={() => {
              navigate(-1);
            }}
            state={{ linkedMasterId: deal?.linkedMasterId }}
            className="back-arrow"
          >
            <i className="fa fa-angle-left"></i>
          </Link>
          <div className="brand-logo">
            <img
              src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${deal?.brandOfferImg}`}
              alt="Brand Logo"
              className="object-cover"
            />
          </div>

          <div className="voucher-redeem">
            <div className="voucher-details">
              <h2>{deal?.name}</h2>
            </div>
            <span className="title">
              {
                dealCategoryData?.find(
                  (findData) => findData?.id === deal?.category
                )?.name
              }
            </span>

            <div className="how-to-redeem">
              <h3>HOW TO REDEEM</h3>
              <div
                className="descrip"
                dangerouslySetInnerHTML={{
                  __html: dealCoupon?.description,
                }}
              ></div>
            </div>

            <button className="terms-button ">
              <Link to={`/terms-condition?couponId=${dealCoupon?.id}`}>
                Terms and Conditions{" "}
                <span className="forward-arrow">
                  <i className="fa fa-angle-right"></i>
                </span>
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="voucher-bottom">
        <button
          className="redeem-button"
          onClick={(e) => openModal(e)}
          disabled={
            dealCoupon?.isPostLoading ||
            unlock?.isPostLoading ||
            unlock?.isgetLoading ||
            isLoading ||
            orderHistory?.isPostLoading
          }
        >
          Redeem
          {(dealData?.isPostLoading ||
            unlock?.isPostLoading ||
            isLoading ||
            orderHistory?.isPostLoading) && (
            <span className="loader-icon pl-4 pt-4">
              <i className="fas fa-spinner fa-spin"></i>{" "}
            </span>
          )}
        </button>
      </div>

      {isModalOpen && (
        <BonusRedeem
          closeModal={closeModal}
          dealCoupon={dealData.postDealCouponCodeData}
          reDirect={reDirectHome}
        />
      )}

      {error && (
        <RedeemFailedModal closeModal={closeModal} navigate={navigate} />
      )}
    </>
  );
};

export default GiftVoucher;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { DealCategoryData } from "../../Utility/Utility";

const FilterModal = ({
  onClose,
  setActiveTab,
  activeTab,
  setAppliedFilters,
  appliedFilters,
  setTagFilters,
  commonDeals,
  dealCouponFilter,
}) => {
  const { dealCategoryData } = DealCategoryData();

  const [searchQuery, setSearchQuery] = useState("");
  const [temporaryFilters, setTemporaryFilters] = useState(appliedFilters);

  const handleClearFilters = () => {
    const emptyFilters = { validity: [], category: [], brand: [] };
    setTemporaryFilters([emptyFilters]);
    setTagFilters(emptyFilters);
    setAppliedFilters(emptyFilters);
    onClose();
  };
  const handleClose = () => {
    if (
      !(
        appliedFilters.validity.length ||
        appliedFilters.category.length ||
        appliedFilters.brand.length
      )
    ) {
      const emptyFilters = { validity: [], category: [], brand: [] };
      setTemporaryFilters([emptyFilters]);
      setTagFilters(emptyFilters);
      setAppliedFilters(emptyFilters);
    }

    onClose();
  };
  const handleApplyFilters = () => {
    setTagFilters(temporaryFilters);
    setAppliedFilters(temporaryFilters);
    onClose();
  };

  const toggleFilter = useCallback((type, value) => {
    setTemporaryFilters((prevFilters) => {
      const filters = JSON.parse(JSON.stringify(prevFilters));
      if (filters[type].includes(value)) {
        filters[type] = filters[type].filter((v) => v !== value);
      } else {
        filters[type].push(value);
      }
      return filters;
    });
  }, []);

  const filteredDeals = commonDeals.filter((deal) =>
    deal.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredCategories = dealCategoryData.filter((category) =>
    commonDeals.some((deal) => deal.category === category.id)
  );

  const renderCheckboxes = (type, options) =>
    options.map((item) => (
      <div key={item} className="flex items-center space-x-3 category-data">
        <input
          type="checkbox"
          className="custom-checkbox"
          value={item}
          checked={temporaryFilters[type].includes(item)}
          onChange={() => toggleFilter(type, item)}
        />
        <span className="category-data-label" title={item}>
          {item.length > 20 ? item.substring(0, 20) + "..." : item}
        </span>
      </div>
    ));

  const renderTabContent = () => {
    const commonClasses = "p-4 fltr-h overflow-y-auto";
    switch (activeTab) {
      case "Validity":
        return (
          <div className={commonClasses}>
            <h2 className="custom-heading">CHECK VALIDITY</h2>
            {renderCheckboxes("validity", [
              "Valid only Today",
              "Valid on Weekdays",
              "Valid on Weekends",
            ])}
          </div>
        );
      case "Category":
        let categoryOptions = [
          ...new Set(filteredCategories.map((cat) => cat.name)),
        ];
        if (dealCouponFilter?.some((offer) => offer?.offerSubType === "EMI")) {
          categoryOptions = [
            "EMI Offers",
            ...categoryOptions.filter((cat) => cat !== "EMI Offers"),
          ];
        }
        return (
          <div className={commonClasses}>
            <h2 className="custom-heading">SHOP BY CATEGORY</h2>
            {renderCheckboxes("category", categoryOptions)}
          </div>
        );
      case "Brand":
        return (
          <div className={commonClasses}>
            <h2 className="custom-heading">SHOP BY BRANDS</h2>
            <div className="flex items-center brand-searchbar p-2 mb-2 w-full search-brand">
              <input
                type="text"
                placeholder="Search Brand"
                className="flex-grow border-none outline-none w-full search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <i className="fas fa-search text-gray-400 ml-2"></i>
            </div>
            {renderCheckboxes(
              "brand",
              filteredDeals.map((deal) => deal.name)
            )}
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={handleClose}
      ></div>
      <div className="relative z-50">
        <div className="modal-container fixed md:w-[40%] w-full">
          <div className="p-5 flex justify-between items-center border-b">
            <h2 className="custom-text-size-filters font-bold filter-txt">
              Filters
            </h2>
            <button className="Custom-cross-icon" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="flex">
            <div className="w-1/3 category-container">
              {["Validity", "Category", "Brand"].map((tab) => (
                <button
                  key={tab}
                  className={`block w-full custom-category-type ${
                    activeTab === tab
                      ? "category-selected"
                      : "category-not-selected"
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div className="w-2/3">{renderTabContent()}</div>
          </div>
          <div className="flex justify-between p-4 border-t custom-shadow">
            <button
              className={`flex-1 button-appclr ${
                temporaryFilters.validity.length ||
                temporaryFilters.category.length ||
                temporaryFilters.brand.length
                  ? "cutome-selected-clr"
                  : "cutome-non-selected-clr"
              }`}
              onClick={handleClearFilters}
            >
              Clear filter
            </button>
            <button
              className={`flex-1 button-appclr apply-fsz ${
                temporaryFilters.validity.length ||
                temporaryFilters.category.length ||
                temporaryFilters.brand.length
                  ? "custome-selected-apply"
                  : "custom-non-selected-apply"
              }`}
              onClick={handleApplyFilters}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterModal;

import { call, put, takeLatest } from "redux-saga/effects";
import {
    onValidateLoginTokenSubmit, onValidateLoginTokenSuccess, onValidateLoginTokenError
} from "../Store/Slices/validateLoginTokenSlice";
import { validateLoginTokenApi } from "../Context/validateLoginTokenApi";

function* validateLoginToken({ payload }) {
    try { 
        const loginTokenResponseData = yield call(validateLoginTokenApi, payload);
        if (loginTokenResponseData.responseCode === "200") { 
            yield put(
                onValidateLoginTokenSuccess({
                    data: loginTokenResponseData.response,
                    status_code:loginTokenResponseData.responseCode,
                })
            );  
        } else {
            yield put(
                onValidateLoginTokenError({ 
                    data: loginTokenResponseData.response,
                })
            );
        }
    } catch (error) {
        // const message = error.message || "Something went wrong";
       // yield put(onValidateLoginTokenError({ data: {}, message, }));
    }
}
export default function* validateLoginTokenSaga() {
    yield takeLatest(onValidateLoginTokenSubmit.type, validateLoginToken);
}

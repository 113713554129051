import { createSlice } from "@reduxjs/toolkit";

export const dealSlice = createSlice({
  name: "deal",
  initialState: {
    isLoading: false,
    isError: false,
    dealData: [],
    message: "",
  },
  reducers: {
    onGetDeal: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getDealData: [],
        getmessage: "",
      };
    },

    onGetDealSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getDealData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetDealError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getDealData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    }
  }
});

export const {
  onGetDeal,
  onGetDealSuccess,
  onGetDealError
} = dealSlice.actions;

export default dealSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const unlockSectionSlice = createSlice({
  name: "unlock-section",
  initialState: {
    isLoading: false,
    isError: false,
    unlockSectionData: [],
    message: "",
    isgetLoading: false,
  },
  reducers: {
    onGetUnlockSection: (state) => {
      return {
        ...state,
        isgetLoading: true,
        UnlockSectionData: [],
        getmessage: "",
        get_status_code:null
      };
    },

    onGetUnlockSectionSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        UnlockSectionData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetUnlockSectionError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        UnlockSectionData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
    onGetUnlockSectionReset: (state) => {
      return {
        ...state,
        get_status_code:null,
        isgetLoading: false,
      };
    },
    onGetUnlockSectionLinkedMaster: (state) => {
      return {
        ...state,
        isgetLoading: true,
        UnlockSectionLinkedData: [],
        getmessage: "",
      };
    },

    onGetUnlockSectionLinkedMasterSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        UnlockSectionLinkedData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetUnlockSectionLinkedMasterError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        UnlockSectionLinkedData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
    onGetUnlockSectionLinkedMasterReset: (state) => {
      return {
        ...state,
        get_status_code:null,
        isgetLoading: false,
      };
    },
    onPosUnlockSection: (state) => {
      return {
        ...state,
        isPostLoading: true,
        postUnlockSectionData: [],
      };
    },

    onPostUnlockSectionSuccess: (state, { payload }) => {
      
      const { postData = [], message = "", status_code = "200" } = payload;
      return {
        ...state,
        isPostLoading: false,
        postUnlockSectionData: postData,
        postMessage: message,
        post_status_code: status_code,
      };
    },

    onPostUnlockSectionError: (state, { payload }) => {
      const { postData = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        postUnlockSectionData: postData,
        postMessage: message,
        post_status_code: status_code,
        isPostLoading: false,
      };
    },
    onPostUnlockSectionReset: (state) => {
      return {
        ...state,
        postUnlockSectionData: [],
        postMessage: "",
        post_status_code: null,
      };
    },
  },
});

export const {
 onGetUnlockSection,
 onGetUnlockSectionSuccess,
 onGetUnlockSectionError,
 onGetUnlockSectionReset,
 onGetUnlockSectionLinkedMaster,
 onGetUnlockSectionLinkedMasterSuccess,
 onGetUnlockSectionLinkedMasterError,
 onGetUnlockSectionLinkedMasterReset,
 onPosUnlockSection,
 onPostUnlockSectionSuccess,
 onPostUnlockSectionError,
 onPostUnlockSectionReset
} = unlockSectionSlice.actions;

export default unlockSectionSlice.reducer;

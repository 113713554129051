/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import copyIcon from "../../Assets/img/copy_Image.png";
import {
  CustomFormatNumber,
  FormatDate,
  VoucherHistoryReducer,
} from "../../Utility/Utility";
import { decryptAES } from "../../Common/Axios/encrypt";

const VoucherHistory = () => {
  const voucherHistory = VoucherHistoryReducer();

  const [activeVoucher, setActiveVoucher] = useState(null);
  const [copied, setCopied] = useState(null);

  const toggleDetails = (id) => {
    setActiveVoucher(activeVoucher === id ? null : id);
  };

  const handleCopy = (value, id) => {
    navigator.clipboard.writeText(value);
    setCopied(id);
    setTimeout(() => {
      setCopied(null);
    }, 2000);
  };

  const sortedData = [...voucherHistory?.voucherHistoryData].sort(
    (a, b) => new Date(b.purchasedAt) - new Date(a.purchasedAt)
  );

  return (
    <div>
      {sortedData.map((data) => (
        <div key={data.id} className="bg-white voucher-card p-4 m-4">
          <div className="flex justify-between">
            <div className="items-center">
              <h3 className="pb-3">{data?.brandName}</h3>
              {data?.purchasedAt ? (
                <div className="block">
                  <span className="voucher-valid-span-1">
                    Purchase Date:
                    <span className="voucher-valid-span-4">
                      {" "}
                      {FormatDate(data?.purchasedAt)}
                    </span>
                  </span>
                </div>
              ) : (
                ""
              )}
              {data?.voucherExpiryDate ? (
                <div>
                  <span className="voucher-valid-span-1">
                    Expiry Date:
                    <span className="voucher-valid-span-4">
                      {" "}
                      {FormatDate(data?.voucherExpiryDate)}
                    </span>
                  </span>
                </div>
              ) : (
                ""
              )}

              {data?.redemptionOrderNo && (
                <div className="block">
                  <span className="voucher-valid-span-1">Order No.:</span>
                  <span className="voucher-valid-span-4 pl-1">
                    {data?.redemptionOrderNo}
                  </span>
                </div>
              )}
            </div>
            {data?.denomination ? (
              <h3 className="pb-3">
                ₹ {CustomFormatNumber(data?.denomination)}
              </h3>
            ) : (
              ""
            )}
          </div>

          {decryptAES(data?.cardNumber) ? (
            <div className="voucher-pink-card p-4 mt-4">
              <div className="mt-1 relative flex justify-between items-center">
                <div>
                  <span className="voucher-valid-span-1 block">
                    Gift Card Code
                  </span>
                  <span
                    className={`$ {
                  activeVoucher === data.id ? "voucher-valid-span-4" : "voucher-valid-span-3"
                }`}
                  >
                    {activeVoucher === data.id
                      ? decryptAES(data?.cardNumber)
                      : "XXXX-XXXX-XXXX"}
                  </span>
                </div>
                {activeVoucher === data.id &&
                  (decryptAES(data?.cardNumber) ? (
                    <button
                      className="copy-icon pt-1 relative"
                      onClick={() =>
                        handleCopy(decryptAES(data?.cardNumber), data.id)
                      }
                    >
                      <img src={copyIcon} alt="Copy Icon" className="h-4 w-4" />
                      {copied === data.id && (
                        <div className="absolute top-[-3rem] right-0 bg-green-500 text-white px-2  py-1 rounded-lg text-sm">
                          Copied Successfully!
                        </div>
                      )}
                    </button>
                  ) : (
                    ""
                  ))}
              </div>
              {decryptAES(data?.pin) && (
                <div className="voucher-border-top mt-2 relative">
                  <span className="voucher-valid-span-1 mt-2 block">Pin</span>
                  <span
                    className={`$ {
                  activeVoucher === data.id ? "voucher-valid-span-4" : "voucher-valid-span-3"
                }`}
                  >
                    {activeVoucher === data.id
                      ? decryptAES(data?.pin)
                      : "XXXX-XXXX-XXXX"}
                  </span>
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          <button
            className="w-full mt-4 py-4 show-details"
            onClick={() => toggleDetails(data.id)}
          >
            {activeVoucher === data.id ? "Hide Details" : "Show Details"}
          </button>
        </div>
      ))}
    </div>
  );
};

export default VoucherHistory;

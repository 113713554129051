import React, { useRef } from "react";
import { Link } from "react-router-dom";
import MakeDiv from "../../Assets/imgNewUI/MakeDiv.png";

const MakeDiffData = [
  {
    title: "Smile Foundation",
    description: "Sponsor a Student's Education",
    buttonText: "Donate Now",
  },
  {
    title: "CRY India",
    description: "Feed Hope: End Hunger Today",
    buttonText: "Donate Now",
  },
  {
    title: "The Mission",
    description: "Bring a smile to Every Person, Every Day.",
    buttonText: "Donate Now",
  },
  {
    title: "CRY India",
    description: "Feed Hope: End Hunger Today",
    buttonText: "Donate Now",
  },
  {
    title: "The Mission",
    description: "Bring a smile to Every Person, Every Day.",
    buttonText: "Donate Now",
  },
];

const MakeDifferenceDay = () => {
  const cardGridRef = useRef(null);
  const sliderThumbRef = useRef(null);
  const sliderRef = useRef(null);
  // Handle slider changes
  const handleSliderChange = (e) => {
    const slider = sliderRef.current;
    const sliderRect = slider?.getBoundingClientRect();
    const thumbWidth = sliderThumbRef.current.offsetWidth;
    const sliderWidth = slider?.offsetWidth - thumbWidth;
    let thumbLeft = e.clientX - sliderRect.left - thumbWidth / 2;

    // Constrain thumb within the slider
    if (thumbLeft < 0) thumbLeft = 0;
    if (thumbLeft > sliderWidth) thumbLeft = sliderWidth;

    // Move the slider thumb
    sliderThumbRef.current.style.left = `${thumbLeft}px`;

    // Calculate the corresponding scroll position for the card grid
    const cardGrid = cardGridRef.current;
    const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
    const scrollLeft = (thumbLeft / sliderWidth) * maxScrollLeft;

    // Scroll the card grid
    cardGrid.scrollLeft = scrollLeft;
  };
  // Update slider thumb when the card grid is scrolled
  const handleCardGridScroll = () => {
    const cardGrid = cardGridRef?.current;
    const scrollLeft = cardGrid?.scrollLeft;
    const maxScrollLeft = cardGrid?.scrollWidth - cardGrid?.clientWidth;
    const sliderWidth = sliderRef?.current?.offsetWidth;
    const thumbWidth = sliderThumbRef?.current?.offsetWidth;
    const maxThumbLeft = sliderWidth - thumbWidth;

    // Calculate the slider thumb position
    const thumbLeft = (scrollLeft / maxScrollLeft) * maxThumbLeft;
    sliderThumbRef.current.style.left = `${thumbLeft}px`;
  };
  return (
    <div className="make-card-bg">
      <div className="flex justify-between pl-4 pt-6 items-center mb-2">
        <h2 className="make-head">Make a Difference Today ❤️</h2>
        <Link to="#" className="make-view-all pr-4">
          View all
        </Link>
      </div>

      <div
        className="scrollable-container mt-4 mb-4"
        ref={cardGridRef}
        onScroll={handleCardGridScroll}
      >
        {MakeDiffData.map((item, index) => (
          <div key={index} className={`make-card ${index === 0 ? "ml-4" : ""}`}>
            <span className="make-title mt-4">{item.title}</span>
            <img
              src={MakeDiv}
              alt={item.title}
              title={item.title}
              className="w-full h-48 make-img mb-4 mt-2"
            />
            <span className="make-des mb-4 mt-2">{item.description}</span>
            <Link to="#" className="donate-now">
              {item.buttonText}
            </Link>
          </div>
        ))}
      </div>

      {MakeDiffData.length > 2 && (
        <div className="slider_wrapper pt-8 pb-6">
          <div
            className="slider"
            ref={sliderRef}
            onMouseMove={handleSliderChange}
          >
            <div className="slider_track"></div>
            <div className="slider_thumb" ref={sliderThumbRef}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MakeDifferenceDay;

import React from "react";
import DeskPromoBanner from "../../Assets/imgNewUI/DeskPromoBanner.png";

const WebPromoBanner = () => {
  return (
    <div
      className="relative w-full bg-cover bg-center mb-4"
    >
      <img src={DeskPromoBanner} alt="IDFC Logo" className="w-auto" />
    </div>
  );
};

export default WebPromoBanner;

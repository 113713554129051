import React from "react";
import rewards from "../../Assets/imgNewUI/rewards.png";

const RewardsBanner = () => {
  return (
    <div className="flex items-center justify-between bg-gradient-to-r from-[#F4F3CB] via-[#F9EBD9] to-[rgba(251,157,213,0.75)] rounded-lg shadow-lg p-8 m-6 rewards-banner">
      <div className="flex-1">
        <h1 className="text-xl font-bold text-gray-800">
          Welcome to a new rewards experience.
        </h1>
        <p className="text-gray-600 mt-2">
          Now, unlocking amazing deals on travel, shopping and gift cards with
          your credit card is easier than ever.
        </p>
      </div>

      <div className="ml-4">
        <img src={rewards} alt="Megaphone Icon" />
      </div>
    </div>
  );
};
export default RewardsBanner;

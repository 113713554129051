/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import copyIcon from "../../Assets/img/copy_image2.png";
import { useDispatch, useSelector } from "react-redux";
import { onGetOfferBenefits } from "../../Store/Slices/offerNdBenefitsSlice";
import Loader from "../../Utility/Loader/Loader";
import {
  GetSegment,
  SectionMasterData,
  UtilityReducer,
  ValidateLoginToken,
} from "../../Utility/Utility";
import NoRecord from "../NoRecord/NoRecord";
import { toast, ToastContainer } from "react-toastify";
import { decryptAES } from "../../Common/Axios/encrypt";

const OffersNdBenefits = ({ login }) => {
  const dispatch = useDispatch();
  const { sectionMasterData } = SectionMasterData(login);

  const [activeFilter, setActiveFilter] = useState("All");
  const [expandedCard, setExpandedCard] = useState(null);
  const offerIsLoading = useSelector(
    (state) => state?.offerBenefitsReducer?.isgetLoading
  );

  const token = ValidateLoginToken();

  const offerNdBenfitData = useSelector(
    (state) => state?.offerBenefitsReducer?.offerBenefitData?.items
  );

  const reversedOfferNdBenfitData = Array.isArray(offerNdBenfitData)
    ? [...offerNdBenfitData].reverse()
    : [];
  const hasOffers = reversedOfferNdBenfitData.some(
    (offer) => !decryptAES(offer?.gCid)
  );
  const hasGiftCards = reversedOfferNdBenfitData.some((offer) =>
    decryptAES(offer?.gCid)
  );

  const FilterOptions =
    hasOffers && hasGiftCards
      ? ["All", "Offers", "Gift Cards"]
      : hasOffers
      ? ["All", "Offers"]
      : hasGiftCards
      ? ["All", "Gift Cards"]
      : ["All", "Offers", "Gift Cards"];

  const handleToggle = (index) => {
    setExpandedCard((prev) => (prev === index ? null : index));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  useEffect(() => {
    dispatch(onGetOfferBenefits({ memberIdentifier: token?.memberIdentifier }));
  }, []);

  // Filter data based on the selected filter
  const filteredData = reversedOfferNdBenfitData?.filter((offer) => {
    if (activeFilter === "All") return true;
    if (activeFilter === "Offers") return !decryptAES(offer?.gCid);
    if (activeFilter === "Gift Cards") return decryptAES(offer?.gCid);
    return true;
  });

  //to handle copy
  const handleCopy = (offer, type) => {
    let couponCode = "";
    if (type === "couponcode") {
      if (decryptAES(offer?.gCid)) {
        couponCode = decryptAES(offer?.couponCode);
      } else {
        couponCode = decryptAES(offer?.couponCode);
      }
    } else if (type === "gcid") {
      couponCode = decryptAES(offer?.gCid);
    } else if (type === "couponpin") {
      couponCode = decryptAES(offer?.couponPin);
    }
    navigator.clipboard.writeText(couponCode);

    toast.success("Copied Successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        margin: "30px",
        fontSize: "12px",
        padding: "8px",
        minHeight: "40px",
      },
    });
  };
  const headline = (offer) => {
    const htmlContent = sectionMasterData?.find(
      (findData) => findData?.id === offer?.sectionId
    )?.text;

    if (htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");
      return doc.body.textContent?.length > 35
        ? doc.body.textContent?.substring(0, 35) + "..."
        : doc.body.textContent;
    }
  };
  const segmentData = GetSegment();
  const card = UtilityReducer();
  const segments = segmentData?.data?.[0]?.mappedSegment;
  const handleCard = (offerData) => {
    const htmlContent = sectionMasterData?.find(
      (findData) => findData?.id === offerData?.sectionId
    )?.segmentId;
    const currentSegmentCard = segments?.find(
      (filterData) => filterData?.segmentId === htmlContent
    )?.subIdentifier;
    const currentCardData = card?.creditCards?.find(
      (filterData) => filterData?.identifier === currentSegmentCard
    );
    return currentCardData;
  };

  return (
    <>
      <ToastContainer />
      {offerIsLoading ? (
        <Loader />
      ) : (
        <div className="bg-white h-full">
          {filteredData?.length ? (
            <div className="m-2">
              <div className="bg-white top-[64px] z-10 mb-4">
                <div className="flex gap-2 overflow-x-auto flex-nowrap mt-4">
                  {FilterOptions.map((filter, index) => (
                    <button
                      key={index}
                      onClick={() => setActiveFilter(filter)}
                      className={`px-4 py-2 feat-card ${
                        activeFilter === filter
                          ? "feat-card-active"
                          : "feat-card-non-active"
                      }`}
                    >
                      {filter}
                    </button>
                  ))}
                </div>
              </div>
              {filteredData?.map((offer, index) => (
                <div
                  key={index}
                  className="max-w-sm mx-auto bg-white offer-card shadow-md border relative mt-8"
                >
                  <span className="absolute left-4 offer-box">
                    {headline(offer)
                      ? headline(offer)
                      : "Welcome Benefits (Redeemed using Credits)"}
                  </span>

                  <div className="p-4">
                    <div className="flex items-center mt-4">
                      <div className="w-10">
                        <img
                          src={
                            decryptAES(offer?.gCid)
                              ? offer?.offerImage
                              : `${process.env.REACT_APP_CLIENT_IMAGE_URL}${offer?.offerImage}`
                          }
                          alt="Zomato"
                          className="rounded-full img-size"
                        />
                      </div>
                      <span className="offer-txt">{offer?.offerName}</span>
                    </div>
                  </div>

                  <div className="img-txt"></div>
                  <div className="p-4">
                    <div className="date-title">
                      Validity:{" "}
                      <span className="font-semibold">{`${formatDate(
                        offer?.validfrom
                      )} to ${formatDate(offer?.validto)}`}</span>
                    </div>

                    {decryptAES(offer?.gCid) ? (
                      <div className="coupon-card-1">
                        <div className="coupon-row-1">
                          <div>
                            <span className="code-label">Coupon Code</span>
                            <div className="flex items-center gap-2">
                              <span className="text-sm font-semibold pr-2">
                                {decryptAES(offer?.couponCode)}
                              </span>
                              <img
                                src={copyIcon}
                                alt="Copy Icon"
                                className="h-7 w-7"
                                onClick={() => handleCopy(offer, "couponcode")}
                              />
                            </div>
                          </div>
                        </div>
                        {offer?.cta ? (
                          <div className="flex items-center">
                            <hr className="flex-grow border-t border-gray-300" />
                            <button
                              className="ml-4 offer-redeem px-4 py-1"
                              onClick={() => window.open(offer?.cta, "_blank")}
                            >
                              Redeem
                            </button>
                          </div>
                        ) : (
                          ""
                        )}{" "}
                        <div className="coupon-row-1">
                          <div>
                            <span className="code-label">
                              {decryptAES(offer?.couponPin) ? "Pin" : "GcId"}
                            </span>
                            <div className="flex items-center gap-2">
                              <span className="text-sm font-semibold pr-2">
                                {decryptAES(offer?.couponPin)
                                  ? decryptAES(offer?.couponPin)
                                  : decryptAES(offer?.gCid)}
                              </span>
                              <img
                                src={copyIcon}
                                alt="Copy Icon"
                                className="h-7 w-7"
                                onClick={() =>
                                  handleCopy(
                                    offer,
                                    decryptAES(offer?.couponPin)
                                      ? "couponpin"
                                      : "gcid"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <p className="benefit-des mt-4">{offer?.offerTitle}</p>

                        <div className="p-3 code-box mt-4 flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-semibold">
                              {decryptAES(offer?.couponCode)}
                            </span>
                            <img
                              src={copyIcon}
                              alt="Copy Icon"
                              className="h-7 w-7"
                              onClick={() => handleCopy(offer, "couponcode")}
                            />
                          </div>
                          <button
                            className="offer-redeem px-4 py-1"
                            onClick={() => window.open(offer?.cta, "_blank")}
                          >
                            Redeem
                          </button>
                        </div>
                      </>
                    )}
                    {decryptAES(offer?.gCid) && (
                      <div className="w-full max-w-sm border rounded-md shadow-sm mt-3">
                        <div className="flex items-center justify-between border-b px-4 py-2">
                          <div className="view-data-th">Denomination</div>
                          <div className="border-l h-4 border-gray-300 mx-2"></div>
                          <div className="view-data-th">Quantity</div>
                        </div>

                        <div className="flex items-center justify-between px-4 py-3">
                          <div className="view-data-coln">
                            ₹ {offer?.denomination}
                          </div>
                          <div className="view-data-coln">{offer.quantity}</div>
                        </div>
                      </div>
                    )}
                    <div className="view-des mb-4">
                      <p className="mt-1">
                        Please use your Gift Card Code at checkout in merchant
                        app/site. For more details, please check T&C.
                      </p>
                    </div>
                    {handleCard(offer) ? (
                      <div className="mt-4 items-center">
                        {expandedCard === index && (
                          <>
                            {handleCard(offer) && (
                              <div className="dot-border">
                                {!decryptAES(offer?.gCid) && (
                                  <div className="dashed-border" />
                                )}
                                <div className="card-head mt-3">
                                  <span className="mb-2">Card Used: </span>
                                </div>
                                <div className="flex items-center mt-2 mb-4">
                                  <img
                                    src={handleCard(offer)?.backgroundcolor}
                                    alt="Card"
                                    className="cr-card-img mr-3"
                                  />
                                  <div>
                                    <p className="card-txt">
                                      {handleCard(offer)?.CardTypeName}
                                    </p>
                                    <p className="card-num pt-2">
                                      •••• {handleCard(offer)?.CardNoLast4Digit}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        <button
                          onClick={() => handleToggle(index)}
                          className="text-gray-700 font-semibold flex items-center gap-1"
                        >
                          {expandedCard === index ? (
                            <>
                              <span>View less</span>
                              <i className="fa fa-angle-up ml-2"></i>
                            </>
                          ) : (
                            <>
                              <span>View more</span>
                              <i className="fa fa-angle-down ml-2"></i>
                            </>
                          )}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoRecord />
          )}
        </div>
      )}
    </>
  );
};

export default OffersNdBenefits;

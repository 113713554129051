import { call, put, takeLatest } from "redux-saga/effects";
import { callDealGetApi } from "../Context/dealApi";
import { onGetDeal, onGetDealError, onGetDealSuccess } from "../Store/Slices/dealSlice";

function* GetDeal() {
  try {
    const getDealResponse = yield call(callDealGetApi);
    if (getDealResponse.responseCode === "200") {
      yield put(
        onGetDealSuccess({
          data: getDealResponse.response,
          message: getDealResponse.responseMessage,
          status_code:getDealResponse.responseCode
        })
      );
    } else {
      yield put(
        onGetDealError({
          data: getDealResponse.response,
          message: getDealResponse.response.message,
          status_code:getDealResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetDealError({ data: [], message, status_code: 400 }));
  }
}
export default function* dealSaga() {
  yield takeLatest(onGetDeal.type, GetDeal);
}

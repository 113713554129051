import React from "react";

const SelectedBrand = ({
  tagFilters,
  setTagFilters,
  setIsModalOpen,
  setAppliedFilters,
  setActiveTab,
  isMobileView,
}) => {
  const handleRemoveTag = (type, value) => {
    setTagFilters((prevFilters) => {
      return {
        ...prevFilters,
        [type]: prevFilters[type].filter((tag) => tag !== value),
      };
    });

    setAppliedFilters((prevFilters) => {
      return {
        ...prevFilters,
        [type]: prevFilters[type].filter((tag) => tag !== value),
      };
    });
  };
  return (
    <div className={`select_brand_box ${isMobileView ? " my-3" : ""}`}>
      <div className="brand_container">
        {tagFilters?.brand?.length > 0 && (
          <div
            className="brand_btn selct-mb"
            onClick={() => {
              setActiveTab("Brand");
              setIsModalOpen(true);
            }}
          >
            <span className="brand_qty">
              <span>{tagFilters.brand.length}</span>
            </span>
            <span
              className="brand_name"
              onClick={() => {
                setActiveTab("Brand");
                setIsModalOpen(true);
              }}
            >
              {"Brand"}
            </span>

            {tagFilters.brand.length > 1 ? (
              <span
              className="select pl-1.5 ml-2.5 icon-down flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`down-arrow-icon`}
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path d="M12 17.27L18.18 12l-1.64-1.41L12 14.83l-4.54-4.24L5.82 12z" />
              </svg>
            </span>
            
            ) : (
              <span
                className="select px-1.5 ml-2.5 icon-cross"
                onClick={() => handleRemoveTag("brand", tagFilters.brand[0])}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
            )}
          </div>
        )}

        {/* Category Filter */}
        {tagFilters?.category?.map((category, index) => (
          <div key={index} className="brand_btn selct-mb">
            <span className="brand_name">{category}</span>
            <span
              className="select px-1.5 ml-2.5 icon-cross-cat"
              onClick={() => handleRemoveTag("category", category)}
            >
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
        ))}

        {/* Validity Filter */}
        {tagFilters?.validity?.map((validity, index) => {
          const lastWord = validity.split(" ").pop();
          return (
            <div key={index} className="brand_btn selct-mb">
              <span className="brand_name">{lastWord}</span>
              <span
                className="select px-1.5 ml-2.5 icon-cross-cat"
                onClick={() => handleRemoveTag("validity", validity)}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectedBrand;

import { call, put, takeLatest } from "redux-saga/effects";
import { onGetMetaData, onGetMetaDataError, onGetMetaDataSuccess } from "../Store/Slices/metaDataSlice";
import { callMetaDataGetApi } from "../Context/metaDataApi";
function* GetMetaData() {
  try {
    const getMetaDataResponse = yield call(callMetaDataGetApi);
    if (getMetaDataResponse.errorCode === "200") {
      yield put(
        onGetMetaDataSuccess({
          data: getMetaDataResponse.response,
          message: getMetaDataResponse.errorMessage,
          status_code:getMetaDataResponse.errorCode
        })
      );
    } else {
      yield put(
        onGetMetaDataError({
          data: getMetaDataResponse.response,
          message: getMetaDataResponse.response.message,
          status_code:getMetaDataResponse.errorCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetMetaDataError({ data: [], message, status_code: 400 }));
  }
}
export default function* metaDataSaga() {
  yield takeLatest(onGetMetaData.type, GetMetaData);
}

import React from "react";

const WebTermsCondition = ({ coupon, closed, about, deal }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="terms-modal max-h-[90vh] overflow-hidden">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="terms-head">
            {" "}
            {about ? `About ${deal.name}` : "Terms and Conditions"}
          </h2>
          <button className="terms-cross" onClick={closed}>
            &times;
          </button>
        </div>
        {about ? (
          <div className="flex items-center space-x-2 px-3 pt-4">
            <img
              src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${deal?.commonImage}`}
              alt={deal?.name}
              title={deal?.name}
              className="img-sz-h "
            />
            <span className="deal-name pl-2"> {deal.name}</span>
          </div>
        ) : (
          ""
        )}

        <div
          className="p-4 pl-5 overflow-y-auto max-h-[70vh] text-gray-700"
          dangerouslySetInnerHTML={{
            __html: about ? deal?.aboutBrand : coupon?.terms,
          }}
        ></div>
      </div>
    </div>
  );
};

export default WebTermsCondition;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import filter from "../../Assets/imgNewUI/filter1.png";
import SelectedBrand from "../../Components/SelectedBrand/SelectedBrand";
import FilterModal from "../../Components/FilterModal/FilterModal";
import ScrollToTop from "../../Utility/ScrollToTop/ScrollToTop";
import CouponModal from "../../Components/CouponModal/CouponModal";
import { useDispatch, useSelector } from "react-redux";
import Star from "../../Assets/imgNewUI/topoffer/StarT.png";
import {
  DealCategoryData,
  DealCouponCodeData,
  DealCouponData,
  DealCouponFreqData,
  DealData,
  FetchDealData,
  SectionContentMasterData,
  SectionMasterData,
  ThemeData,
  UtilityReducer,
} from "../../Utility/Utility";
import Loader from "../../Utility/Loader/Loader";
import NoRecord from "../../Components/NoRecord/NoRecord";
import CommonWebOffer from "../../Components/CommonWebOffer/CommonWebOffer";
import { decryptAES } from "../../Common/Axios/encrypt";

const TopOfferPage = ({
  isModalOpenoffer,
  setIsModalOpenoffer,
  isMobileView,
}) => {
  const [dealsData, setDealsData] = useState({
    dealCoupon: [],
    deal: null,
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const { dealData, isDealLoading } = DealData();
  const themeData = ThemeData();
  const { dealCouponData, isDealCouponLoading } = DealCouponData();
  const { dealCategoryData, isDealCategoryLoading } = DealCategoryData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { dealCouponFreqData, isDealFreqLoading } = DealCouponFreqData();
  const { isDealCouponCodeLoading } = DealCouponCodeData();
  const [activeTab, setActiveTab] = useState("Validity");
  const [appliedFilters, setAppliedFilters] = useState({
    validity: [],
    category: [],
    brand: [],
  });
  const { sctionContentdata } = SectionContentMasterData();
  const { sectionMasterData } = SectionMasterData();
  const queryParams = new URLSearchParams(location.search);
  const stid = queryParams.get("stid")?.replace(/\s/g, "+");
  const sectionId = stid && decryptAES(stid);
  const filtersData = sctionContentdata?.filter(
    (filterData) => filterData?.sectionMasterId === sectionId
  );
  const heading = sectionMasterData.find(
    (findData) => findData?.id === sectionId
  );
  const [temporaryFilters, setTemporaryFilters] = useState(appliedFilters);
  const [tagFilters, setTagFilters] = useState({
    validity: [],
    category: [],
    brand: [],
  });
  const cards = UtilityReducer();
  const creditCards = cards?.creditCards;
  const netFilterDealCoupons = dealCouponData?.filter((filtersData) => {
    if (filtersData.offerType === "NetworkCardType") {
      if (filtersData.offerTypeValue.toUpperCase() === "RUPAY") {
        return creditCards?.some(
          (card) =>
            card.Network.toLowerCase() ===
            filtersData.offerTypeValue.toLowerCase()
        );
      }
      return true;
    }
    if (filtersData.offerType === "SpecialType") {
      return creditCards?.some(
        (card) =>
          card.CardTypeName.toLowerCase() ===
          filtersData.offerTypeValue.toLowerCase()
      );
    }
    return true;
  });

  const today = new Date();
  const todayDayOfWeek = today.getDay();

  let filterCouponFreq = dealCouponFreqData;

  if (tagFilters?.validity?.length) {
    filterCouponFreq = dealCouponFreqData.filter((coupon) => {
      const isTodayValid =
        tagFilters?.validity?.includes("Valid only Today") &&
        coupon.weekDayId.includes(todayDayOfWeek);

      const isWeekdayValid =
        tagFilters?.validity?.includes("Valid on Weekdays") &&
        coupon.weekDayId.some((day) => day >= 1 && day <= 5);

      const isWeekendValid =
        tagFilters?.validity?.includes("Valid on Weekends") &&
        coupon.weekDayId.some((day) => day === 0 || day === 6);
      return isTodayValid || isWeekdayValid || isWeekendValid;
    });
  }

  const dealCouponFilter = netFilterDealCoupons.filter((deal) => {
    if (tagFilters?.category.includes("EMI Offers")) {
      return deal?.offerSubType === "EMI";
    }
    if (deal.typeOfCoupoun === "Membership") {
      return false;
    }

    if (deal.typeOfCoupoun === "Static" || deal.typeOfCoupoun === "NoCode") {
      return filterCouponFreq.some(
        (freqData) => freqData.dealCoupounId === deal.id
      );
    } else if (deal.typeOfCoupoun === "Dynamic") {
      return false;
    }

    return false;
  });

  const isValidColor = (color) => {
    const newStyle = new Option().style;
    newStyle.color = color;
    return newStyle.color !== "";
  };
  const navigate = useNavigate();
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--bs-white-2",
      isValidColor(themeData?.commonhgElementSurfaceColor)
        ? themeData.commonhgElementSurfaceColor
        : "#FCF1F2"
    );
    document.documentElement.style.setProperty(
      "--bs-light-1-black",
      isValidColor(themeData?.headerTextColor)
        ? themeData.headerTextColor
        : "#222222"
    );
    document.documentElement.style.setProperty(
      "--bs-white",
      isValidColor(themeData?.headerBackgroundColor)
        ? themeData.headerBackgroundColor
        : "#fff"
    );
    document.documentElement.style.setProperty(
      "--bs-black-3",
      isValidColor(themeData?.headerBorderColor)
        ? themeData.headerBorderColor
        : "#ffffff"
    );

    document.documentElement.style.setProperty(
      "--bs-white-7",
      isValidColor(themeData?.primaryBgColor)
        ? themeData.primaryBgColor
        : "#fff"
    );
    document.documentElement.style.setProperty(
      "--bs-light-2-black",
      isValidColor(themeData?.primaryText) ? themeData.primaryText : "#222222"
    );
    document.documentElement.style.setProperty(
      "--bs-light-4-black",
      isValidColor(themeData?.secondaryText)
        ? themeData.secondaryText
        : "#222222"
    );
    document.documentElement.style.setProperty(
      "--primary-green",
      isValidColor(themeData?.earnElementColor)
        ? themeData.earnElementColor
        : "#00AE4E"
    );
    document.documentElement.style.setProperty(
      "--earn-bg",
      isValidColor(themeData?.earnSurfaceColor)
        ? themeData.earnSurfaceColor
        : "#E5F7ED"
    );
    document.documentElement.style.setProperty(
      "--bs-card-name",
      isValidColor(themeData?.cardNameTextColor)
        ? themeData.cardNameTextColor
        : "#E5F7ED"
    );
    document.documentElement.style.setProperty(
      "--bs-white-7",
      isValidColor(themeData?.commonElementBackgroundColor)
        ? themeData.commonElementBackgroundColor
        : "#fff"
    );
    document.documentElement.style.setProperty(
      "--bs-red-2",
      isValidColor(themeData?.commonElementBorderColor)
        ? themeData.commonElementBorderColor
        : "#9a1b24"
    );
    document.documentElement.style.setProperty(
      "--bs-red-2",
      isValidColor(themeData?.commonElementTextColor)
        ? themeData.commonElementTextColor
        : "#9a1b24"
    );
  }, [themeData]);

  const dealfilter = dealData.filter((deal) =>
    dealCouponFilter.some((freqData) => freqData.dealId === deal.id)
  );
  const dealfilterHome = dealData
    .filter((deal) =>
      dealCouponFilter.some((freqData) => freqData.dealId === deal.id)
    )
    .sort((a, b) => {
      const aHasBankOffer = dealCouponFilter.some(
        (coupon) =>
          coupon.dealId === a.id && coupon.source.toLowerCase().includes("bank")
      );
      const bHasBankOffer = dealCouponFilter.some(
        (coupon) =>
          coupon.dealId === b.id && coupon.source.toLowerCase().includes("bank")
      );

      return bHasBankOffer - aHasBankOffer;
    })
    .filter((deal) =>
      filtersData?.some((filter) => filter.linkedMasterId === deal.id)
    );
  const commonDeals = sectionId ? dealfilterHome : dealfilter;

  const filterDeals = (deals, categoryData) => {
    return deals.filter((deal) => {
      const categoryName = categoryData.find(
        (category) => category.id === deal.category
      )?.name;

      const isValid = !tagFilters?.category.includes("EMI Offers")
        ? (tagFilters.category.length === 0 ||
            tagFilters.category?.includes(categoryName)) &&
          (tagFilters.brand.length === 0 ||
            tagFilters?.brand?.includes(deal.name))
        : true;

      return isValid;
    });
  };
  const filteredDeals = filterDeals(commonDeals, dealCategoryData);

  useEffect(() => {
    localStorage.setItem("appliedFilters", JSON.stringify(tagFilters));
  }, [appliedFilters]);
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  //to fetch dealcoupon data on mount
  useEffect(() => {
    if (loginAuthData?.status_code === "200") {
      FetchDealData(dispatch);
      setIsModalOpenoffer(false);
    }
  }, [loginAuthData]);

  const handleDealCoupon = (deal) => {
    const dealCoupons = dealCouponFilter?.filter(
      (coupon) => coupon?.dealId === deal?.id
    );

    setDealsData({
      dealCoupon: dealCoupons,
      deal: deal,
    });

    setIsModalOpenoffer(true);
  };

  useEffect(() => {
    const handleBodyOverflow = () => {
      const isOpen = isModalOpenoffer || isModalOpen;
      document.body.style.overflow = isOpen ? "hidden" : "auto";
    };

    handleBodyOverflow();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpenoffer, isModalOpen]);

  return (
    <>
      <ScrollToTop />
      {/* <WebTopOffer /> */}
      <>
        {isDealLoading ||
        isDealCategoryLoading ||
        isDealCouponLoading ||
        isDealFreqLoading ||
        isDealCouponCodeLoading ? (
          <Loader />
        ) : (
          <div
            id="top_offer_main_wrapper_container"
            className="top_offer_main_wrapper_container container mx-auto"
          >
            {/* {!isMobileView && (
          <>
            <OfferDownloadApp />
            <WebTopOfferBanner />
          </>
        )} */}
            <div className="row ">
              {isMobileView ? (
                <div className="offer_title1 mobile_heading sticky top-0 z-10 bg-white">
                  <h3 className="hide_desktop">
                    <Link
                      onClick={() => navigate(-1)}
                      className="relative flex items-center"
                    >
                      <span className="font-light">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-8 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10 19l-7-7 7-7"
                          />
                          <line
                            x1="3"
                            y1="12"
                            x2="21"
                            y2="12"
                            stroke="currentColor"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </span>
                    </Link>
                  </h3>
                  <h3>{sectionId ? heading?.text : "Offers"}</h3>
                  {tagFilters?.brand?.length ||
                  tagFilters?.category?.length ||
                  tagFilters?.validity?.length ? (
                    <span className="notification-dot"></span>
                  ) : (
                    <span className="without-filt"></span>
                  )}

                  <img
                    className="hide_desktop filter_icon"
                    src={filter}
                    alt="filterImg"
                    onClick={() => setIsModalOpen(true)}
                  />
                </div>
              ) : (
                <div className="p-6 mb-2 ">
                  <h2 className="topOff-head mb-6">
                    {sectionId ? heading?.text : "Offers"}
                  </h2>
                  <div className="flex items-center space-x-4 relative filter-row">
                    <div className="flex items-center space-x-2 relative filter-col">
                      <span onClick={() => setIsModalOpen(true)}>Filter</span>
                      <div className="relative inline-block">
                        <img
                          className="h-4 w-4 cursor-pointer"
                          src={filter}
                          alt="filterImg"
                          onClick={() => setIsModalOpen(true)}
                        />
                        {tagFilters?.brand?.length ||
                        tagFilters?.category?.length ||
                        tagFilters?.validity?.length ? (
                          <span className="notification-dot"></span>
                        ) : null}
                      </div>
                    </div>

                    <SelectedBrand
                      setActiveTab={setActiveTab}
                      setIsModalOpen={setIsModalOpen}
                      tagFilters={tagFilters}
                      setTagFilters={setTagFilters}
                      setAppliedFilters={setAppliedFilters}
                      isMobileView={isMobileView}
                    />
                  </div>
                </div>
              )}
              {isModalOpen && (
                <FilterModal
                  dealCouponFilter={dealCouponFilter}
                  commonDeals={commonDeals}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  onClose={() => setIsModalOpen(false)}
                  setAppliedFilters={setAppliedFilters}
                  appliedFilters={appliedFilters}
                  setTagFilters={setTagFilters}
                  setIsModalOpen={setIsModalOpen}
                  tagFilters={tagFilters}
                  temporaryFilters={temporaryFilters}
                  setTemporaryFilters={setTemporaryFilters}
                />
              )}{" "}
              <div
                className={`col-lg-8 col-md-8 col-sm-8 col-12 top_offer_content_wrapper ${
                  (tagFilters.validity.length > 0 ||
                    tagFilters.category.length > 0 ||
                    tagFilters.brand.length > 0) &&
                  !isMobileView
                    ? ""
                    : "mt-8"
                }`}
              >
                {isMobileView && (
                  <SelectedBrand
                    setActiveTab={setActiveTab}
                    setIsModalOpen={setIsModalOpen}
                    tagFilters={tagFilters}
                    setTagFilters={setTagFilters}
                    setAppliedFilters={setAppliedFilters}
                    isMobileView={isMobileView}
                  />
                )}

                <div
                  className={
                    filteredDeals?.length ? "top_offer_card_container" : ""
                  }
                >
                  {filteredDeals.length ? (
                    filteredDeals.map((deal, index) => {
                      const coupon = dealCouponFilter?.find(
                        (dealCoupon) => dealCoupon?.dealId === deal?.id
                      );
                      const isFeature = dealCouponFilter?.filter(
                        (dealCoupon) =>
                          dealCoupon?.dealId === deal?.id &&
                          dealCoupon?.offerType === "Feature"
                      ).length;
                      return (
                        <>
                          {isMobileView ? (
                            <div
                              className={`offer_card continer mx-auto ${
                                isMobileView ? "top-width2 mt-5" : "top-width"
                              }`}
                              key={index}
                            >
                              <img
                                onClick={() => handleDealCoupon(deal)}
                                className="top_image img_s"
                                src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${deal?.commonImage}`}
                                alt={coupon?.title}
                                title={coupon?.title}
                              />
                              {isFeature ? (
                                <div className="featured-badge">
                                  <img
                                    src={Star}
                                    className="icon-sz"
                                    alt="star"
                                  />
                                  <span className="text">FEATURED</span>
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="card_title pd-l">
                                <h4>
                                  {deal?.name?.length > 15
                                    ? deal?.name?.substring(0, 12) + ".."
                                    : deal?.name}
                                </h4>
                              </div>

                              <div
                                className="card_content pd-l"
                                onClick={() => handleDealCoupon(deal)}
                              >
                                <p
                                  className={`${
                                    isFeature ? "heading" : "title-heading"
                                  }`}
                                >
                                  {coupon?.title?.replace(/^"|"$/g, "")}
                                </p>
                              </div>

                              <div
                                className="card_footer clp"
                                onClick={() => handleDealCoupon(deal)}
                              >
                                <a onClick={() => handleDealCoupon(deal)}>
                                  Claim
                                </a>
                              </div>
                            </div>
                          ) : (
                            <CommonWebOffer
                              matchingCoupon={coupon}
                              offer={deal}
                              index={index}
                              page={true}
                              dealCouponFilter={dealCouponFilter}
                            />
                          )}
                        </>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {isModalOpenoffer && (
          <CouponModal
            onCouponClose={() => setIsModalOpenoffer(false)}
            couponData={dealsData?.dealCoupon}
            dealImage={dealsData?.deal}
            isMobileView={isMobileView}
          />
        )}
      </>
    </>
  );
};

export default TopOfferPage;

import React, { useRef, useState } from "react";
import {
  handleRedirectWithLoading,
  ModalOrSso,
  SectionContentMasterData,
} from "../../Utility/Utility";

const SpecialCartBanner = ({
  text,
  sectionId,
  setShowModal,
  login,
  setIsLoading,
  isMobileView,
}) => {
  const { sctionContentdata } = SectionContentMasterData();
  const cartBannerContent = sctionContentdata.filter(
    (content) => content.sectionMasterId === sectionId
  );
  const isModal = ModalOrSso();

  const [setScrollPosition] = useState(0);
  const cardGridRef = useRef(null);
  const sliderThumbRef = useRef(null);
  const sliderRef = useRef(null);

  // Update slider thumb when the card grid is scrolled
  const handleCardGridScroll = () => {
    const cardGrid = cardGridRef.current;
    const scrollLeft = cardGrid?.scrollLeft;
    const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
    const sliderWidth = sliderRef?.current?.offsetWidth;
    const thumbWidth = sliderThumbRef?.current?.offsetWidth;
    const maxThumbLeft = sliderWidth - thumbWidth;

    // Calculate the slider thumb position
    const thumbLeft = (scrollLeft / maxScrollLeft) * maxThumbLeft;
    sliderThumbRef.current.style.left = `${thumbLeft}px`;
  };

  // Handle slider changes
  const handleSliderChange = (e) => {
    const slider = sliderRef.current;
    const sliderRect = slider.getBoundingClientRect();
    const thumbWidth = sliderThumbRef.current.offsetWidth;
    const sliderWidth = slider?.offsetWidth - thumbWidth;
    let thumbLeft = e.clientX - sliderRect.left - thumbWidth / 2;

    // Constrain thumb within the slider
    if (thumbLeft < 0) thumbLeft = 0;
    if (thumbLeft > sliderWidth) thumbLeft = sliderWidth;

    // Move the slider thumb
    sliderThumbRef.current.style.left = `${thumbLeft}px`;

    // Calculate the corresponding scroll position for the card grid
    const cardGrid = cardGridRef.current;
    const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
    const scrollLeft = (thumbLeft / sliderWidth) * maxScrollLeft;

    // Scroll the card grid
    cardGrid.scrollLeft = scrollLeft;
    setScrollPosition(scrollLeft);
  };

  return (
    <div
      className={`all-p summer_special_container container mx-auto ${
        cartBannerContent?.length ? "px-4" : ""
      } ${isMobileView ? "" : "mt-12"}`}
    >
      {cartBannerContent?.length ? (
        <div className="special_card_title">
          <h3 className="mb-3">{text}</h3>
        </div>
      ) : (
        ""
      )}
      <div
        className={`${cartBannerContent?.length ? "summer_card_grid" : ""}`}
        ref={cardGridRef}
        onScroll={handleCardGridScroll}
      >
        {cartBannerContent?.map((content, index) => (
          <img
            key={index}
            onClick={() => {
              content.cta &&
                handleRedirectWithLoading(
                  setIsLoading,
                  content.cta,
                  login,
                  setShowModal,
                  isModal
                );
            }}
            className="sp-banner-card"
            src={
              isMobileView
                ? `${process.env.REACT_APP_CLIENT_IMAGE_URL}${content?.mobImage}`
                : `${process.env.REACT_APP_CLIENT_IMAGE_URL}${content?.webImage}`
            }
            alt={text}
            title={text}
          />
        ))}
      </div>
      {cartBannerContent?.length > (isMobileView ? 1 : 4) && (
        <div className="slider_wrapper mt-4">
          <div
            className="slider"
            ref={sliderRef}
            onMouseMove={handleSliderChange}
          >
            <div className="slider_track"></div>
            <div className="slider_thumb" ref={sliderThumbRef}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpecialCartBanner;

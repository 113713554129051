const API = {
  //login
  loginAuth: "generate-auth-token",
  //translation
  translationApi: "translation-content",
  //add Coupon
  addCoupon: "addCoupon",
  //section-master
  sectionMaster: "section-master",
  //section-content-master
  sectionContentMaster: "section-content-master",
  //deal
  deal: "deal",
  //deal-category
  dealCategory: "deal-category",
  //deal-coupon
  dealCoupon: "deal-coupoun",
  //deal-coupon-code
  dealCouponCode: "/update-active-deal-coupoun-code",
  dealcouponcodeactive: "deal-coupoun-code-Current-Active",
  // upload image path
  uploadImage: "upload",
  // nav-configuration
  navConfiguration: "nav-configure",
  // deal-coupon-frequency
  dealcouponfrequency: "deal-coupoun-frequency-Current-Active",
  //customer-segment
  customersegment: "customer-segment",
  //product-content
  productcontent: "product-content",
  //client-master
  clientmaster: "client-master",
  //meta-data
  metadata: "linked-master-metaData",
  // generate token
  generate_token: "/v3/generateToken",
  // validate login token
  validate_login_token: "/v3/validateLoginToken",
  //getMember Api
  getMemberApi: "/v3/GetMember",
  //member api
  MemberApi: "/member",
  //switch account card api
  switchAccountApi: "/v3/switchAccount",
  // segmentId
  segmentApi: "/v3/getMappedSegmentWithMember",
  //api-config
  apiconfig: "api-config",
  //theme-management
  thememaster: "theme-management",
  //unlock-section-activity
  unlocksectionactivity: "unlock-section-activity",
  unlocksectionactivitylinkedmasterid:
    "unlock-section-activity-by-linked-master-id",
  //mail-api
  mailApi: "/v3/sendEmail",
  //order-history
  offerhistory: "offer-history",
  //keepAliveApi
  keepAlive: "/v3/keepSessionAlive",
  //poshvine-data
  poshvinedata: "VoucherHistoricalData",
  //userCardBase
  userCardBase: "user-card-base",
};
export default API;

import { createSlice } from "@reduxjs/toolkit";

export const MemberSlice = createSlice({
  name: "get-member ",
  initialState: {
    isLoading: false,
    isError: false,
    MemberData: [],
    message: "",
    isgetLoading: false,
  },
  reducers: {
    onGetMember: (state) => {
      return {
        ...state,
        isgetLoading: true,
        MemberData: [],
        getmessage: "",
      };
    },

    onGetMemberSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        MemberData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetMemberError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        MemberData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
    onGetMemberReset: (state) => {

      return {
        ...state,
        get_status_code:null,
        isgetLoading: false,
      };

    },
    onGetMemberData: (state) => {
      return {
        ...state,
        isgetMemberLoading: true,
        getMemberData: [],
        getmembermessage: "",
      };
    },

    onGetMemberDataSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetMemberLoading: false,
        getMemberData: data,
        getmembermessage:message,
        get_member_status_code:status_code,
      };
    },

    onGetMemberDataError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getMemberData: data,
        getmembermessage:message,
        get_member_status_code:status_code,
        isgetMemberLoading: false,
      };
    },
    onGetMemberDataReset: (state) => {

      return {
        ...state,
        get_member_status_code:null,
        isgetMemberLoading: false,
      };

    },
    onGetMemberLogout: (state) => {
      return {
        ...state,
        MemberData:null,
        get_status_code:null,
        isgetLoading: false,
      };

    },
    onPostMember: (state) => {
      return {
        ...state,
        isPostLoading: true,
        postMemberData: [],
      };
    },

    onPostMemberSuccess: (state, { payload }) => {
      const { postData = [], message = "", status_code="200" } = payload;
      return {
        ...state,
        isPostLoading: false,
        postMemberData: postData,
        postMessage:message,
        post_status_code:status_code,
      };
    },

    onPostMemberError: (state, { payload }) => {
      const { postData = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        postMemberData: postData,
        postMessage:message,
        post_status_code:status_code,
        isPostLoading: false,
      };
    },
    onPostMemberReset: (state) => {
      return {
        ...state,
        postMemberData: [],
        postMessage:"",
        post_status_code:null
      };
    },

  },
});

export const {
  onGetMember,
  onGetMemberSuccess,
  onGetMemberError,
  onGetMemberReset,
  onGetMemberLogout,
  onPostMember,
  onPostMemberSuccess,
  onPostMemberError,
  onPostMemberReset,
  onGetMemberData,
  onGetMemberDataError,
  onGetMemberDataSuccess,
  onGetMemberDataReset
} = MemberSlice.actions;

export default MemberSlice.reducer;

import React from "react";
import MoreBg from "../../Assets/imgNewUI/More.jpg";
import { Link } from "react-router-dom";
import {
  handleRedirectWithLoading,
  ModalOrSso,
  ProductContentData,
  SectionContentMasterData,
} from "../../Utility/Utility";

const GiftCardSection = ({
  sectionData,
  setShowModal,
  login,
  setIsLoading,
  isMobileView,
}) => {
  const isModal = ModalOrSso();

  const { productContentData } = ProductContentData();
  const { sctionContentdata } = SectionContentMasterData();
  const filtersData = sctionContentdata?.filter(
    (filterData) => filterData?.sectionMasterId === sectionData?.id
  );
  const filteredProducts = filtersData
    .map(
      (filterData) =>
        Array.isArray(productContentData) &&
        productContentData.filter(
          (product) =>
            product.productId === filterData.linkedMasterId &&
            product.productType === "Gift Cards"
        )
    )
    .flat();
  const calculateDiscount = (mrp, price) => {
    if (!mrp || !price || mrp <= 0) {
      return 0;
    }
    return Math.floor(((mrp - price) / mrp) * 100);
  };
  return (
    <>
      {filteredProducts?.length ? (
        <div className="all-p ml-sp container mx-auto p-4">
          {filteredProducts?.length ? (
            <>
              {" "}
              {sectionData?.text && (
                <div className={`view-all-sp ${!isMobileView && "relative"}`}>
                  <h5
                    className={`home-head pos-rlt ${
                      isMobileView ? " pb-5" : "z-20 mt-4"
                    }`}
                  >
                    {sectionData?.text}
                    {!isMobileView && filteredProducts?.length > 4 && (
                      <Link
                        onClick={() => setShowModal(true)}
                        className="view-a2  pt-1"
                      >
                        View All
                      </Link>
                    )}
                  </h5>
                </div>
              )}
            </>
          ) : (
            ""
          )}
          <div className={`image-grid ${isMobileView ? "" : "relative -mt-4"}`}>
            {filteredProducts?.slice(0, 5)?.map((giftCard, index) => (
              <div className="gift-card-container" key={index}>
                <div>
                  <div className="image-container">
                    <img
                      src={giftCard.image}
                      alt={giftCard.name}
                      title={giftCard.name}
                      onClick={() =>
                        handleRedirectWithLoading(
                          setIsLoading,
                          giftCard?.productUrl,
                          login,
                          setShowModal,
                          isModal
                        )
                      }
                      className="image"
                    />
                    {calculateDiscount(giftCard?.mrp, giftCard?.price) !==
                      0 && (
                      <div className="discount-banner discount-off">
                        {calculateDiscount(giftCard?.mrp, giftCard?.price)}% OFF
                      </div>
                    )}
                  </div>

                  <div
                    className={`text-center pt-gft ${
                      isMobileView ? "-mt-2" : "-mt-9 relative"
                    }`}
                  >
                    <span
                      className="upto"
                      onClick={() =>
                        handleRedirectWithLoading(
                          setIsLoading,
                          giftCard?.productUrl,
                          login,
                          setShowModal,
                          isModal
                        )
                      }
                    >
                      Earn up to
                    </span>
                    <p className="poin">{giftCard?.earnPoints} Pts</p>
                  </div>
                </div>
              </div>
            ))}
            {filteredProducts?.length >= 5 && (
              <div className="image-card mt-1">
                <Link to="">
                  <img
                    className="image2 image more-e"
                    onClick={() => {
                      handleRedirectWithLoading(
                        setIsLoading,
                        filtersData.filter((filterData) => filterData?.cta)?.[0]
                          ?.cta,
                        login,
                        setShowModal,
                        isModal
                      );
                    }}
                    src={MoreBg}
                    alt="More"
                  />
                  <div
                    className="text-center"
                    onClick={
                      login
                        ? () => {
                            handleRedirectWithLoading(
                              setIsLoading,
                              filtersData.filter(
                                (filterData) => filterData?.cta
                              )?.[0]?.cta,
                              login,
                              setShowModal,
                              isModal
                            );
                          }
                        : () => setShowModal(false)
                    }
                  >
                    <div className="more-pad">
                      <span className="upto">More</span>
                      <p className="poin">e-Gift Cards</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default GiftCardSection;

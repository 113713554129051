import React, { useRef } from "react";
import Nayka from "../../Assets/imgNewUI/Nayka.png";
import { Link } from "react-router-dom";
import star from "../../Assets/imgNewUI/EarnStar.png";
const PointsData = [
  { title: "Nykaa Gift Card", discount: "10% OFF" },
  { title: "Vijay Sales", discount: "10% OFF" },
  { title: "Spotify Premium", discount: null },
  { title: "Tanishq", discount: "10% OFF" },
  { title: "Amazon", discount: null },
  { title: "Tanishq", discount: "10% OFF" },
  { title: "Amazon", discount: null },
];
const WebMorePoints = () => {
  const cardGridRef = useRef(null);
  const sliderThumbRef = useRef(null);
  const sliderRef = useRef(null);

  // Handle left button click
  const handleScrollLeft = () => {
    const cardGrid = cardGridRef.current;
    cardGrid.scrollBy({
      left: -300, // Adjust the value as per your card width
      behavior: "smooth",
    });
  };

  // Handle right button click
  const handleScrollRight = () => {
    const cardGrid = cardGridRef.current;
    cardGrid.scrollBy({
      left: 300, // Adjust the value as per your card width
      behavior: "smooth",
    });
  };
  // Handle slider changes
  const handleSliderChange = (e) => {
    const slider = sliderRef.current;
    const sliderRect = slider?.getBoundingClientRect();
    const thumbWidth = sliderThumbRef.current.offsetWidth;
    const sliderWidth = slider?.offsetWidth - thumbWidth;
    let thumbLeft = e.clientX - sliderRect.left - thumbWidth / 2;

    // Constrain thumb within the slider
    if (thumbLeft < 0) thumbLeft = 0;
    if (thumbLeft > sliderWidth) thumbLeft = sliderWidth;

    // Move the slider thumb
    sliderThumbRef.current.style.left = `${thumbLeft}px`;

    // Calculate the corresponding scroll position for the card grid
    const cardGrid = cardGridRef.current;
    const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
    const scrollLeft = (thumbLeft / sliderWidth) * maxScrollLeft;

    // Scroll the card grid
    cardGrid.scrollLeft = scrollLeft;
  };
  // Update slider thumb when the card grid is scrolled
  const handleCardGridScroll = () => {
    const cardGrid = cardGridRef?.current;
    const scrollLeft = cardGrid?.scrollLeft;
    const maxScrollLeft = cardGrid?.scrollWidth - cardGrid?.clientWidth;
    const sliderWidth = sliderRef?.current?.offsetWidth;
    const thumbWidth = sliderThumbRef?.current?.offsetWidth;
    const maxThumbLeft = sliderWidth - thumbWidth;

    // Calculate the slider thumb position
    const thumbLeft = (scrollLeft / maxScrollLeft) * maxThumbLeft;
    sliderThumbRef.current.style.left = `${thumbLeft}px`;
  };
  return (
    <div className="px-10 my-10">
      <div className="flex items-center justify-between mb-4">
        <h2 className="w-head">More Points with Gift Cards</h2>
        <Link to="" className="w-view">
          View all
        </Link>
      </div>
      <div className="relative">
        <button
          className="absolute left-[-20px] top-[40%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 flex items-center justify-center w-10 h-10"
          aria-label="Scroll Left"
          onClick={handleScrollLeft}
        >
          <i class="fa-solid fa-arrow-left"></i>
        </button>
        <div
          className="flex gap-4 overflow-x-scroll no-scrollbar"
          ref={cardGridRef}
          onScroll={handleCardGridScroll}
        >
          {PointsData.map((card, index) => (
            <div key={index} className="flex-shrink-0 text-center">
              <div className="relative more-card flex items-center justify-center mb-4">
                <img src={Nayka} alt="Nayka" />
                {card.discount && (
                  <span className="absolute top-0 left-0 discount-banner1 px-3 py-1">
                    {card.discount}
                  </span>
                )}
              </div>
              <span className="flex earn-spn">
                Earn up to{" "}
                <span className="flex web-earn-1 ml-2 py-1 px-2">
                  {" "}
                  <img src={star} alt="star" className="earn-star" /> 100 Points
                </span>
              </span>
            </div>
          ))}
        </div>

        <button
          className="absolute right-[-20px] top-[40%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 flex items-center justify-center w-10 h-10"
          aria-label="Scroll Left"
          onClick={handleScrollRight}
        >
          <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
      {PointsData.length > 2 && (
        <div className="slider_wrapper pt-4">
          <div
            className="slider"
            ref={sliderRef}
            onMouseMove={handleSliderChange}
          >
            <div className="slider_track"></div>
            <div className="slider_thumb" ref={sliderThumbRef}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebMorePoints;

import TermsAndCondition from "../Pages/TermsAndCondition/TermsAndCondition";
import TopOfferPage from "../Pages/TopOfferPage/TopOfferPage";
import HomePage from "../HomePage/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { useState } from "react";
import LoginPage from "../Pages/LoginPage/LoginPage";

import PageError from "../Utility/PageError/PageError";
import ClaimBonus from "../Components/ClaimBonus/ClaimBonus";
import GiftVoucher from "../Components/GiftVoucher/GiftVoucher";
import BonusRedeem from "../Components/BonusRedeem/BonusRedeem";
import OffersNdBenefits from "../Components/OffersNdBenefits/OffersNdBenefits";
import Layout from "../Utility/Layout/Layout";
import SideNavbar from "../Pages/SideNavbar/SideNavbar";
import ContactUs from "../Pages/ContactUs/ContactUs";

import Logout from "../Components/Logout/Logout";
import MakeDifferenceDay from "../Components/MakeDifferenceDay/MakeDifferenceDay";
import WebCouponModal from "../WebComponents/WebCouponModal/WebCouponModal";
import WebLayout from "../WebComponents/WebLayout/WebLayout";
import WebHomePage from "../WebComponents/WebHomePage/WebHomePage";
import WebOffers from "../WebComponents/WebOffers/WebOffers";
import RewardPoint from "../Components/RewardPoint/RewardPoint";
import VoucherHistory from "../Components/VoucherHistory/VoucherHistory";
function RouteConfiq() {
  const [isModalOpenoffer, setIsModalOpenoffer] = useState(false);
  const login = sessionStorage.getItem("login");
  const [showModal, setShowModal] = useState(false);

  const [Loading, setIsLoading] = useState(false);
  return (
    <Router>
      <Routes>
        <Route
          path="/login/:loginSessionToken/:temporaryToken"
          element={<LoginPage />}
        />

        <Route
          path="/side-navbar"
          element={
            <Layout
              Component={SideNavbar}
              login={login}
              setShowModal={setShowModal}
              setIsLoading={setIsLoading}
              Loading={Loading}
            />
          }
        />
        <Route path="/privacy-policy" element={<Layout Component={ContactUs} />} />
        <Route path="/terms-conditions" element={<Layout Component={ContactUs} />} />
        <Route path="/contact-us" element={<Layout Component={ContactUs} />} />
        <Route
          path="/order-history"
          element={<Layout Component={OffersNdBenefits} login={login} />}
        />
        <Route
          path="/deal"
          element={
            <Layout
              setShowModal={setShowModal}
              login={login}
              Component={TopOfferPage}
              setIsLoading={setIsLoading}
              setIsModalOpenoffer={setIsModalOpenoffer}
              isModalOpenoffer={isModalOpenoffer}
            />
          }
        />
        <Route
          path="/reward-point"
          element={
            <RewardPoint
              setIsLoading={setIsLoading}
              Loading={Loading}
              login={login}
              setShowModal={setShowModal}
            />
          }
        />
        <Route
          path="/voucher-history"
          element={
            <Layout
              setShowModal={setShowModal}
              login={login}
              Component={VoucherHistory}
            />
          }
        />
        <Route
          path="/"
          element={
            <Layout
              Component={HomePage}
              showModal={showModal}
              setShowModal={setShowModal}
              login={login}
              setIsModalOpenoffer={setIsModalOpenoffer}
              isModalOpenoffer={isModalOpenoffer}
              setIsLoading={setIsLoading}
              Loading={Loading}
            />
          }
        />
        <Route
          path="/Offers"
          element={
            <Layout
              Component={WebCouponModal}
              showModal={showModal}
              setShowModal={setShowModal}
              login={login}
              setIsModalOpenoffer={setIsModalOpenoffer}
              isModalOpenoffer={isModalOpenoffer}
              setIsLoading={setIsLoading}
              Loading={Loading}
            />
          }
        />

        <Route
          path="/claim-bonus"
          element={
            <ClaimBonus
              setIsModalOpenoffer={setIsModalOpenoffer}
              isModalOpenoffer={isModalOpenoffer}
            />
          }
        />
        <Route path="/make-difference" element={<MakeDifferenceDay />} />
        <Route path="/terms-condition" element={<TermsAndCondition />} />
        <Route path="/gift-voucher" element={<GiftVoucher />} />
        <Route path="/bonus-redeem" element={<BonusRedeem />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/desktop-homepage"
          element={<WebLayout Component={WebHomePage} />}
        />

        <Route
          path="/web-offers"
          element={<WebLayout Component={WebOffers} />}
        />
        <Route
          path="*"
          element={
            <PageError
              pageError={{
                StatusCode: "404",
                ErrorName: "Route not found",
                ErrorDesription: "The page you were looking for is not found!",
                url: "/",
                buttonText: "Back to home",
              }}
            />
          }
        />
      </Routes>
    </Router>
  );
}
export default RouteConfiq;

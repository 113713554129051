import { call, put, takeLatest } from "redux-saga/effects";
import { callDealCouponFreqGetApi } from "../Context/dealCouponFreqApi";
import { onGetDealCouponFreq, onGetDealCouponFreqError, onGetDealCouponFreqSuccess } from "../Store/Slices/dealCouponFreqSlice";
function* GetDealCouponFreq() {
  try {
    const getDealCouponFreqResponse = yield call(callDealCouponFreqGetApi);
    if (getDealCouponFreqResponse.responseCode === "200") {
      yield put(
        onGetDealCouponFreqSuccess({
          data: getDealCouponFreqResponse.response,
          message: getDealCouponFreqResponse.responseMessage,
          status_code:getDealCouponFreqResponse.responseCode
        })
      );
    } else {
      yield put(

        onGetDealCouponFreqError({

          data: getDealCouponFreqResponse.response,
          message: getDealCouponFreqResponse.responseMessage,
          status_code:getDealCouponFreqResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetDealCouponFreqError({ data: [], message, status_code: 400 }));
  }
}
export default function* sectionMasterSaga() {
  yield takeLatest(onGetDealCouponFreq.type, GetDealCouponFreq);
}

import axiosInstanceAdmin from "../Common/Axios/axiosInstanceAdmin";
import API from "../Common/Endpoint/serviceConstrants";
export const loginAuthApi = async (payload) => {
    const { data = [] } = await axiosInstanceAdmin.post(API.loginAuth, payload,{
      headers: {
        Userid: process.env.REACT_APP_USER_ID,
        Password: process.env.REACT_APP_PASSWORD
      }
    });
  return data;
}; 


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import WebTermsCondition from "../WebTermsCondition/WebTermsCondition";
import { Link, useLocation } from "react-router-dom";
import {
  DealCouponData,
  DealCouponFreqData,
  DealData,
  FormatDate,
  SectionMasterData,
  UtilityReducer,
} from "../../Utility/Utility";
import copyIcon from "../../Assets/img/copy_Image.png";
import ScrollToTop from "../../Utility/ScrollToTop/ScrollToTop";
import { decryptAES } from "../../Common/Axios/encrypt";
import { useDispatch, useSelector } from "react-redux";
import { onGetDeal } from "../../Store/Slices/dealSlice";
import { onGetDealCoupon } from "../../Store/Slices/dealCouponSlice";
import { onGetDealCouponFreq } from "../../Store/Slices/dealCouponFreqSlice";
import Loader from "../../Utility/Loader/Loader";
import { onGetsectionMaster } from "../../Store/Slices/sectionMasterSlice";

const WebCouponModal = () => {
  const location = useLocation();
  const [copied, setCopied] = useState(null);
  const [isExpanded, setIsExpanded] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { dealData, isDealLoading } = DealData();
  const { dealCouponFreqData, isDealFreqLoading } = DealCouponFreqData();
  const [coupon, setCoupon] = useState(null);
  const [about, setAbout] = useState(false);
  const { dealCouponData, isDealCouponLoading } = DealCouponData();
  const { isSectionLoading } = SectionMasterData();
  const handleClick = (params, couponData) => {
    setIsModalOpen(true);
    if (params === "terms") {
      setAbout(false);
      setCoupon(couponData);
    } else {
      setAbout(true);
    }
  };
  // to handle copy coupon code
  const handleCopy = (coupon) => {
    let couponCode = "";
    if (coupon?.typeOfCoupoun === "Static") {
      couponCode = coupon?.coupounCode;
    }
    if (couponCode) {
      navigator.clipboard.writeText(couponCode);
      setCopied(coupon.id);
      setTimeout(() => {
        setCopied(null);
      }, 3000);
    }
  };
  const dispatch = useDispatch();
  const card = UtilityReducer();
  const creditCards = card?.creditCards;
  const netFilterDealCoupons = dealCouponData?.filter((filtersData) => {
    if (filtersData.offerType === "NetworkCardType") {
      if (filtersData.offerTypeValue.toUpperCase() === "RUPAY") {
        return creditCards?.some(
          (card) =>
            card.Network.toLowerCase() ===
            filtersData.offerTypeValue.toLowerCase()
        );
      }
      return true;
    }

    if (filtersData.offerType === "SpecialType") {
      return creditCards?.some(
        (card) =>
          card.CardTypeName.toLowerCase() ===
          filtersData.offerTypeValue.toLowerCase()
      );
    }
    return true;
  });
  const queryParams = new URLSearchParams(location.search);
  const did = queryParams.get("did")?.replace(/\s/g, "+");
  const dealIdData = did && decryptAES(did);
  const deal = dealData.find((findData) => findData?.id === dealIdData);

  const dealCouponFilter = netFilterDealCoupons.filter((deal) => {
    if (deal.typeOfCoupoun === "Static" || deal.typeOfCoupoun === "NoCode") {
      return dealCouponFreqData.some(
        (freqData) => freqData.dealCoupounId === deal.id
      );
    } else if (deal.typeOfCoupoun === "Dynamic") {
      return false;
      // return dealCouponCodeData
      //   ?.filter((deal) => deal?.enabled && deal?.status === "Active")
      //   ?.some(
      //     (codeData) =>
      //       codeData.dealCoupounId === deal.id &&
      //       dealCouponFreqData?.some(
      //         (coupon) => coupon?.dealCoupounId === deal.id
      //       )
      //   );
    }
    return false;
  });
  //to get coupon code from deal coupon based on dealIdData and dealId
  const getCouponCodeFromCoupon = (coupon) => {
    const couponCode = coupon?.coupounCode;
    return couponCode;
  };
  const couPons = dealCouponFilter?.filter(
    (filterData) => filterData.dealId === dealIdData
  );
  const sortedData = [
    ...couPons?.filter((item) => item.source?.toLowerCase() === "bank"),
    ...couPons?.filter((item) => item.source?.toLowerCase() !== "bank"),
  ];
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  useEffect(() => {
    if (loginAuthData?.status_code === "200") {
      dispatch(onGetDeal());
      dispatch(onGetsectionMaster());
      dispatch(onGetDealCoupon());
      dispatch(onGetDealCouponFreq());
    }
  }, [loginAuthData]);
  return (
    <>
      <ScrollToTop />
      {isDealLoading ||
      isDealCouponLoading ||
      isDealFreqLoading ||
      isSectionLoading ? (
        <Loader />
      ) : (
        <div className="mt-6 px-4">
          <div className="bg-white rounded-lg web-coupon-modal mx-auto">
            <div className="py-4 flex justify-between items-center">
              <div className="flex flex-col space-y-4">
                <span className="option-head flex">
                  {" "}
                  <Link to="/" className="flex">
                    Home
                    <div className="breadcrumb">
                      {pathnames.length > 0 &&
                        pathnames.map((name, index) => {
                          const routeTo = `/${pathnames
                            .slice(0, index + 1)
                            .join("/")}`;
                          return (
                            <span key={index}>
                              <span className="breadcrumb-separator"> / </span>
                              <Link to={routeTo} className="breadcrumb-link">
                                {name.charAt(0).toUpperCase() + name.slice(1)}
                              </Link>
                            </span>
                          );
                        })}
                    </div>
                  </Link>
                  / {deal?.name}
                </span>
                <div className="flex items-center space-x-2">
                  <img
                    src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${deal?.commonImage}`}
                    alt={deal?.name}
                    title={deal?.name}
                    className="img-sz-h"
                  />
                  <h2 className="text-xl font-bold text-gray-800">
                    {deal?.name}
                  </h2>
                </div>
              </div>
            </div>

            {sortedData?.map((couponData, index) => {
              const expiryDate = dealCouponFreqData?.find(
                (code) => code?.dealCoupounId === couponData?.id
              )?.validUpto;
              return (
                <div key={index} className="coupon-card mb-8">
                  <div className="relative">
                    <div className="coupon-card-header rounded-xl inset-4 flex flex-col p-2">
                      <span className="span-head text-center pt-4 mb-5">
                        {couponData.title}
                      </span>
                      {couponData?.typeOfCoupoun !== "NoCode" && (
                        <div className="left-1/2 transform -translate-x-1/2 w-5/6 p-1 coupon-code-field coupon-code-sz mt-4">
                          {copied === couponData.id && (
                            <div className=" absolute top-[-2rem] left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg z-50 whitespace-nowrap">
                              Copied successfully!
                            </div>
                          )}
                          <div>
                            <input
                              type="text"
                              value={getCouponCodeFromCoupon(couponData)}
                              className="w-full bg-transparent custom-coupon-heading"
                              readOnly
                            />
                            <button
                              className="right-0 copy-icon"
                              onClick={() => handleCopy(couponData)}
                            >
                              <img
                                src={copyIcon}
                                alt="Copy Icon"
                                className="h-5 w-5"
                              />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mx-3">
                    <div className="span-expiry-d mb-6 pl-2">
                      Expiry date: {FormatDate(expiryDate)}
                    </div>
                    {couponData?.description ||
                    couponData?.terms ||
                    couponData?.howToRedeem ||
                    deal.aboutBrand ? (
                      <button
                        onClick={() =>
                          setIsExpanded(isExpanded === index ? null : index)
                        }
                        className="view-details flex justify-between items-center w-full pl-2 pb-4 mb-2"
                      >
                        View Details
                        {isExpanded === index ? (
                          <i className="fa fa-angle-up mr-3"></i>
                        ) : (
                          <i className="fa fa-angle-down ml-2"></i>
                        )}
                      </button>
                    ) : (
                      ""
                    )}

                    {isExpanded === index && (
                      <div className="space-y-4 mt-4 pl-2">
                        <div
                          className="mx-3"
                          dangerouslySetInnerHTML={{
                            __html: couponData?.description,
                          }}
                        />

                        <div
                          className={`bg-white ${
                            deal?.aboutBrand ? "about-condition" : ""
                          } `}
                        >
                          {couponData?.terms ? (
                            <div
                              className="flex items-center justify-between px-4 py-3"
                              onClick={() => handleClick("terms", couponData)}
                            >
                              <button
                                className="about-condition-span"
                                onClick={() => handleClick("terms", couponData)}
                              >
                                Terms and Conditions
                              </button>
                              <span className="about-condition-arr">›</span>
                            </div>
                          ) : (
                            ""
                          )}
                          {deal?.aboutBrand ? (
                            <div
                              className={`flex items-center ${
                                couponData?.terms ? "border-t" : ""
                              } justify-between px-4 py-3`}
                              onClick={() => handleClick("About")}
                            >
                              <span className="about-condition-span">
                                About {deal?.name}
                              </span>
                              <span
                                className="about-condition-arr"
                                onClick={() => handleClick("About")}
                              >
                                ›
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="px-6 mt-4">
                    {couponData?.cta ? (
                      <span className="flex claim-top-border"></span>
                    ) : (
                      ""
                    )}
                    <div className="py-4 flex justify-between items-center">
                      {couponData?.cta ? (
                        <button
                          className="coupon-claim px-4 py-2"
                          onClick={() => window.open(couponData?.cta, "_blank")}
                        >
                          Claim now
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {isModalOpen && (
            <WebTermsCondition
              coupon={coupon}
              closed={() => setIsModalOpen(false)}
              deal={deal}
              about={about}
            />
          )}
        </div>
      )}
    </>
  );
};

export default WebCouponModal;

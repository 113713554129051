import React from "react";
import RedeemFailed from "../../Assets/imgNewUI/RedeemFailed.png";

const RedeemFailedModal = ({ navigate }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white shadow-lg warn-card py-6 text-center px-4">
        <div className="flex justify-center mb-2">
          <img src={RedeemFailed} alt="warmStar" />
        </div>

        <span className="warn-span mx-4">
          Issue with Claim. Please try after some time.
        </span>

        <button className="ok-button px-4 mt-4" onClick={() => navigate("/")}>
          OK
        </button>
      </div>
    </div>
  );
};

export default RedeemFailedModal;

import React from "react";

const Modal = ({ title, content, button }) => {
  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-lg w-full relative">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">{title}</h2>
          </div>
          <p className="mt-4 text-gray-700">{content}</p>
          <div className=" flex justify-end">
            {button && (
              <button
                onClick={() =>
                  window.open("https://my.idfcfirstbank.com/login", "_self")
                }
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
              >
                OK
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;

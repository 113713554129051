/* eslint-disable eqeqeq */
import CryptoJS from "crypto-js";

export const encryptAES = (data, mode=2) => {
  let dataToEncrypt;
  var secretKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY_1);
  var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV_1);
  if(mode==2){
    secretKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY_2);
    iv = CryptoJS.enc.Utf8.parse(sessionStorage.getItem("ivkey"));
  }
  if (typeof data === "string") {
    dataToEncrypt = data;
  } else if (typeof data === "object") {
    dataToEncrypt = JSON.stringify(data);
  } else {
    throw new Error("Unsupported data type for encryption");
  }

  const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, secretKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};

export const decryptAES = (cipherText, mode=2) => {
  var secretKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY_1);
  var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV_1);
  if(mode==2){
    secretKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY_2);
    iv = CryptoJS.enc.Utf8.parse(sessionStorage.getItem("ivkey"));
  }
  const decrypted = CryptoJS.AES.decrypt(cipherText, secretKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  try {
    return JSON.parse(decryptedText);
  } catch (error) {
    return decryptedText;
  }
};

import { createSlice } from "@reduxjs/toolkit";

export const dealCategorySlice = createSlice({
  name: "deal-category",
  initialState: {
    isLoading: false,
    isError: false,
    dealCategoryData: [],
    message: "",
    isPostLoading: false,
  },
  reducers: {
    onGetDealCategory: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getDealCategoryData: [],
        getmessage: "",
      };
    },

    onGetDealCategorySuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getDealCategoryData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetDealCategoryError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getDealCategoryData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
  }
});

export const {
  onGetDealCategory,
  onGetDealCategorySuccess,
  onGetDealCategoryError
} = dealCategorySlice.actions;

export default dealCategorySlice.reducer;

import { call, put, takeLatest } from "redux-saga/effects";
import { onGetSectionContentMaster, onGetSectionContentMasterError, onGetSectionContentMasterSuccess } from "../Store/Slices/sectionContentMasterSlice";
import { callSectionContentMasterGetApi } from "../Context/sectionContentMasterApi";
function* GetSectionContentMaster({payload}) {
  try {
    const getSectionContentMasterResponse = yield call(callSectionContentMasterGetApi,payload);
    if (getSectionContentMasterResponse.responseCode === "200") {
      yield put(
        onGetSectionContentMasterSuccess({
          data: getSectionContentMasterResponse.response,
          message: getSectionContentMasterResponse.responseMessage,
          status_code:getSectionContentMasterResponse.responseCode
        })
      );
    } else {
      yield put(
        onGetSectionContentMasterError({
          data: getSectionContentMasterResponse.response,
          message: getSectionContentMasterResponse.response.message,
          status_code:getSectionContentMasterResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetSectionContentMasterError({ data: [], message, status_code: 400 }));
  }
}
export default function* SectionContentMasterSaga() {
  yield takeLatest(onGetSectionContentMaster.type, GetSectionContentMaster);;
}

import React from "react";

const WebMessageSection = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen msg-card p-4 my-10">
      <div className="max-w-lg text-center">
        <p className="message-p">
          IDFC Bank's loyalty program turned my dream vacation into reality! I
          used my points to book flights to Bali and even covered some hotel
          stays. The portal is user-friendly, and I found great deals. It’s like
          I got a free vacation just for using my card!
        </p>
        <div className="mt-4">
          <span className="flex items-center justify-center name-spn mt-4">
            Sarah T
          </span>
          <span className="pblis-frm">MEMBER SINCE 2020</span>
        </div>
      </div>
      <div className="flex mt-4 space-x-2 items-center">
        <span className="w-1 h-1 bg-gray-300 rounded-full"></span>
        <span className="w-2 h-2 dot-bg rounded-full"></span>
        <span className="w-1 h-1 bg-gray-300 rounded-full"></span>
      </div>
    </div>
  );
};

export default WebMessageSection;

import React from 'react';
import WebPromoBanner from "../WebPromoBanner/WebPromoBanner";
import RewardsBanner from "../RewardsBanner/RewardsBanner";
import WebSupportingBanner from "../WebSupportingBanner/WebSupportingBanner";
import WebTopOffer from "../WebTopOffer/WebTopOffer";
import WebDownloadApp from "../WebDownloadApp/WebDownloadApp";
import WebSpecialSection from "../WebSpecialSection/WebSpecialSection";
import WebMorePoints from "../WebMorePoints/WebMorePoints";
import WebMessageSection from "../WebMessageSection/WebMessageSection";
import WebBonusPoints from "../WebBonusPoints/WebBonusPoints";
import WebTripSection from "../WebTripSection/WebTripSection";
import WebSummerSpecials from "../WebSummerSpecials/WebSummerSpecials";

const WebHomePage = () => {
  return (
    <>
       <WebPromoBanner />
        <RewardsBanner />
        <WebSupportingBanner />
        <WebTopOffer/>
        <WebDownloadApp/>
        <WebSpecialSection/>
        <WebMorePoints/>
        <WebMessageSection/>
        <WebBonusPoints/>
        <WebTripSection/>
        <WebSummerSpecials/>
    </>
  )
}

export default WebHomePage;

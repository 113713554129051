import React from "react";
import { Link } from "react-router-dom";
import { PointPerPrice } from "../../Utility/Utility";

const GiftCard = ({ product, currentPoints }) => {
  const perPoint = PointPerPrice();

  const calculateDiscount = (mrp, price) => {
    if (!mrp || !price || mrp <= 0) {
      return 0;
    }
    return Math.floor(((mrp - price) / mrp) * 100);
  };

  return (
    <div className="col-lg-3 col-6 pad-0 w-50 pr-3 ml-4 ">
      <div className="giftcardbox">
        <div>
          <div className="image-container">
            <img
              onClick={() => window.open(product?.productUrl, "_self")}
              src={product?.image}
              alt="tanishq"
              className="w-100 img-sz -mt-8 -mb-6"
            />
            {calculateDiscount(product?.mrp, product?.price) !== 0 && (
              <div className="discount-banner1">
                {calculateDiscount(product?.mrp, product?.price)}% OFF
              </div>
            )}
          </div>

          <h4 className="card-name relative">
            {product?.name?.length > 10
              ? product.name.substring(0, 10) + "..."
              : product?.name}
          </h4>
        </div>
        <div className="fixFromBottom">
          <p className="aapo">
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#earnpoints"
              className="start-point"
            >
              Starting ₹{Math.floor(product?.price)}{" "}
              {currentPoints > Math.floor(product?.price * perPoint) && (
                <>/ {Math.floor(product?.price * perPoint)} Pts</>
              )}
            </Link>
          </p>
          <p className="flex justify-center items-center earn-bg w-fit mx-auto">
            Earn Upto {Math.floor(product?.earnPoints)} Pts
          </p>
        </div>
      </div>
    </div>
  );
};

export default GiftCard;

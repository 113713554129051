import { createSlice } from "@reduxjs/toolkit";

export const apiConfigSlice = createSlice({
  name: "api-config",
  initialState: {
    isLoading: false,
    isError: false,
    apiConfigData: [],
    message: "",
    isgetLoading: true,
  },
  reducers: {
    onGetApiConfig: (state) => {
      return {
        ...state,
        isgetLoading: true,
        getApiConfigData: [],
        getmessage: "",
      };
    },

    onGetApiConfigSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        getApiConfigData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetApiConfigError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getApiConfigData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
  },
});

export const {
  onGetApiConfig,
  onGetApiConfigSuccess,
  onGetApiConfigError,
} = apiConfigSlice.actions;

export default apiConfigSlice.reducer;

import { call, put, takeLatest } from "redux-saga/effects";
import {
  onGetMember,
  onGetMemberData,
  onGetMemberDataError,
  onGetMemberDataSuccess,
  onGetMemberError,
  onGetMemberSuccess,
  onPostMember,
  onPostMemberError,
  onPostMemberSuccess,
} from "../Store/Slices/getMemberSlice";
import {
  callGetMemberApi,
  callGetMemberPostApi,
  callmemberGetApi,
} from "../Context/getMemberApi";
function* GetMember({ payload }) {
  try {
    const getMemberResponse = yield call(callGetMemberApi, payload);

    if (getMemberResponse.responseCode === "200") {
      yield put(
        onGetMemberSuccess({
          data: getMemberResponse,
          message: getMemberResponse.responseMessage,
          status_code: getMemberResponse.responseCode,
        })
      );
    } else {
      yield put(
        onGetMemberError({
          data: getMemberResponse,
          message: getMemberResponse.response.message,
          status_code: getMemberResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message =
      error.response.data.responseMessage || "Something went wrong";
    yield put(onGetMemberError({ data: [], message, status_code: 400 }));
  }
}
function* PostMember({ payload }) {
  try {
    const postMemberResponse = yield call(callGetMemberPostApi, payload);
    if (postMemberResponse.responseCode === "200") {
      yield put(
        onPostMemberSuccess({
          postData: postMemberResponse.response,
          message: postMemberResponse.responseMessage,
          status_code: postMemberResponse.responseCode,
        })
      );
    } else {
      yield put(
        onPostMemberError({
          data: postMemberResponse.response,
          message: postMemberResponse?.responseMessage,
          status_code: postMemberResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message =
      error.response.data.responseMessage || "Something went wrong";
    yield put(onPostMemberError({ data: [], message, status_code: 400 }));
  }
}
function* getMemberData({ payload }) {
  try {
    const getMemberDataResponse = yield call(callmemberGetApi, payload);
    if (getMemberDataResponse.responseCode === "200") {
      yield put(
        onGetMemberDataSuccess({
          data: getMemberDataResponse.response,
          message: getMemberDataResponse.responseMessage,
          status_code: getMemberDataResponse.responseCode,
        })
      );
    } else {
      yield put(
        onGetMemberDataError({
          data: getMemberDataResponse.response,
          message: getMemberDataResponse?.responseMessage,
          status_code: getMemberDataResponse.responseCode,
        })
      );
    }
  } catch (error) {
    const message =
      error.response.data.responseMessage || "Something went wrong";
    yield put(onGetMemberDataError({ data: [], message, status_code: 400 }));
  }
}
export default function* getMemberSaga() {
  yield takeLatest(onGetMember.type, GetMember);
  yield takeLatest(onPostMember.type, PostMember);
  yield takeLatest(onGetMemberData.type, getMemberData);
}

import axiosInstanceAdmin from '../Common/Axios/axiosInstanceAdmin';
import API from '../Common/Endpoint/serviceConstrants';
export const callThemeMasterGetApi = async (payload) => {
  const { data = [] } = await axiosInstanceAdmin.get(API.thememaster,{
    params:{
      id:payload?.id
    }
  });
  return data;
};


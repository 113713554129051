import React, { useEffect } from "react";
import Account from "../../Assets/imgNewUI/Account.png";
import credidCardImage from "../../Assets/imgNewUI/creditPlaceHolder.png";
import Loader from "../../Utility/Loader/Loader";
import { useDispatch } from "react-redux";
import { onSaveIamge } from "../../Store/Slices/utilityReducer";
import viewpoints from "../../Assets/imgNewUI/viewpoints.png";
import { CustomFormatNumber } from "../../Utility/Utility";
import MyIcon from "../../Assets/imgNewUI/rotate-right";

const CardModal = ({
  isOpen,
  setIsOpen,
  selectedCard,
  creditCards,
  selectCard,
  defaultCard,
  loading,
}) => {
  const dispatch = useDispatch();

  const capitalizeFirstLetter = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleCardSelection = (index, type, card, callApi, defaultCard, e) => {
    e && e.stopPropagation();
    sessionStorage.setItem("autoSelect", true);
    selectCard(index, type, card, callApi, defaultCard);
    if (!callApi) {
      dispatch(
        onSaveIamge({
          currentPoints: card?.pointBalance,
          currentBg: card?.imagelink,
          cardName: card.CardTypeName,
          totalPointBalance: card?.totalPointBalance,
        })
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);
  const finalCredicards = creditCards?.filter(
    (card) => card?.identifiertype === "1002"
  );
  const finalDebitcards = creditCards?.filter(
    (card) => card?.identifiertype === "1001"
  );
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50">
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end pointer-events-auto">
            <div className="bg-white w-full max-h rounded-t-3xl p-4 overflow-y-auto relative">
              <div className="flex pb-4 pt-2 justify-between items-center">
                <div className="fs-wh text-xxl">Choose your card</div>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-3xl font-medium text-black pr-1"
                >
                  &times;
                </button>
              </div>
              {loading ? (
                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
                  <Loader />
                </div>
              ) : (
                <div className="mt-2 space-y-2.5 max-h-[60vh] overflow-y-auto">
                  {finalCredicards?.length ? (
                    <h3 className="text-sm font-semibold text-gray-500 mt">
                      CREDIT CARDS
                    </h3>
                  ) : null}
                  {finalCredicards.map((card, index) => (
                    <div
                      key={index}
                      className={`card-item width ${

                           selectedCard?.index === card?.identifier
                          ? "selected"
                          : ""
                      }`}
                      onClick={(e) => {
                        if (!card.showTryAgain && !card.viewpoints)
                          handleCardSelection(card?.identifier, "credit", card);
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          onClick={() =>
                            handleCardSelection(
                              card?.identifier,
                              "credit",
                              card
                            )
                          }
                          src={card.backgroundcolor || credidCardImage}
                          alt={card.cardname}
                          title={card.cardname}
                          className="card-img-sz mr-4"
                        />
                        <div>
                          <div
                            className="card-name"
                            onClick={(e) =>
                              handleCardSelection(
                                card?.identifier,
                                "credit",
                                card
                              )
                            }
                          >
                            {capitalizeFirstLetter(card.CardTypeName).length >
                            12
                              ? capitalizeFirstLetter(
                                  card.CardTypeName
                                )?.substring(0, 12) + "..."
                              : capitalizeFirstLetter(card.CardTypeName)}
                          </div>
                          <div
                            className="card-code"
                            onClick={(e) =>
                              handleCardSelection(
                                card?.identifier,
                                "credit",
                                card
                              )
                            }
                          >
                            ••{card.CardNoLast4Digit}
                          </div>
                        </div>
                      </div>

                      {card.showTryAgain ? (
                        <button
                          className="flex items-center justify-center py-3 px-3 bg-gradient-to-r rounded-full modal-try-again"
                          onClick={(e) =>
                            handleCardSelection(
                              card?.identifier,
                              "credit",
                              card,
                              true,
                              selectedCard?.index === card?.identifier,
                              e
                            )
                          }
                        >
                          <MyIcon />
                          <span className="view-points pl-1 modal-try-again-text">
                            Try Again
                          </span>
                        </button>
                      ) : isNaN(card?.pointBalance) || card.showViewPoint ? (
                        <button
                          className="flex items-center justify-center py-3 px-3 bg-gradient-to-r rounded-full view-banner"
                          onClick={(e) =>
                            handleCardSelection(
                              card?.identifier,
                              "credit",
                              card,
                              true,
                              selectedCard?.index === card?.identifier,
                              e
                            )
                          }
                        >
                          <img
                            src={viewpoints}
                            alt={card.CardTypeName}
                            title={card.CardTypeName}
                            className="w-4 h-4"
                          />
                          <span className="view-points pl-2">View Points</span>
                        </button>
                      ) : (
                        <div className="card-point font-medium text-sm flex items-center">
                          {CustomFormatNumber(Math.floor(card?.pointBalance))}
                          <span className="ml-1">Pts</span>
                        </div>
                      )}
                    </div>
                  ))}

                  {finalDebitcards?.length ? (
                    <h3 className="text-sm font-semibold text-gray-500 mt">
                      ACCOUNTS
                    </h3>
                  ) : null}

                  {finalDebitcards.map((card, index) => (
                    <div
                      key={index}
                      className={`debit-card ${
                        selectedCard?.index === card?.identifier
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        if (!card.showTryAgain && !card.viewpoints)
                          handleCardSelection(card?.identifier, "debit", card);
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={Account}
                          alt={card?.cardname}
                          title={card?.cardname}
                          className="card-img-sz1 mr-4"
                        />
                        <div>
                          <div className="debit-head ">Savings Account</div>
                        </div>
                      </div>
                      {card.showTryAgain ? (
                        <button
                          className="flex items-center justify-center py-3 px-3 bg-gradient-to-r rounded-full modal-try-again"
                          onClick={(e) =>
                            handleCardSelection(
                              card?.identifier,
                              "debit",
                              card,
                              true,
                              selectedCard?.index === card?.identifier,
                              e
                            )
                          }
                        >
                          <MyIcon />
                          <span className="view-points pl-1 modal-try-again-text">
                            Try Again
                          </span>
                        </button>
                      ) : isNaN(card?.totalPointBalance) || card.showViewPoint ? (
                        <button
                          className="flex items-center justify-center py-2 px-2 bg-gradient-to-r rounded-full view-banner"
                          onClick={(e) =>
                            handleCardSelection(
                              card?.identifier,
                              "credit",
                              card,
                              true,
                              selectedCard?.index === card?.identifier,
                              e
                            )
                          }
                        >
                          <img
                            src={viewpoints}
                            alt={card.CardTypeName}
                            title={card.CardTypeName}
                            className="w-4 h-4"
                          />
                          <span className="view-points pl-2">View Points</span>
                        </button>
                      ) : (
                        <div className="card-point font-medium text-sm flex items-center">
                          {CustomFormatNumber(Math.floor(card?.totalPointBalance))}
                          <span className="ml-1">Pts</span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CardModal;

import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import CommonWebOffer from "../../Components/CommonWebOffer/CommonWebOffer";
import { encryptAES } from "../../Common/Axios/encrypt";

const WebTopOffer = ({ text, dealfilter, dealCouponFilter, sectionData }) => {
  const navigate = useNavigate();
  const cardGridRef = useRef(null);
  const sliderThumbRef = useRef(null);
  const sliderRef = useRef(null);
  const handleScrollLeft = () => {
    const cardGrid = cardGridRef.current;
    cardGrid.scrollBy({
      left: -300,
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    const cardGrid = cardGridRef.current;
    cardGrid.scrollBy({
      left: 300,
      behavior: "smooth",
    });
  };
  const handleSliderChange = (e) => {
    const slider = sliderRef.current;
    const sliderRect = slider?.getBoundingClientRect();
    const thumbWidth = sliderThumbRef.current.offsetWidth;
    const sliderWidth = slider?.offsetWidth - thumbWidth;
    let thumbLeft = e.clientX - sliderRect.left - thumbWidth / 2;

    if (thumbLeft < 0) thumbLeft = 0;
    if (thumbLeft > sliderWidth) thumbLeft = sliderWidth;

    sliderThumbRef.current.style.left = `${thumbLeft}px`;

    const cardGrid = cardGridRef.current;
    const maxScrollLeft = cardGrid.scrollWidth - cardGrid.clientWidth;
    const scrollLeft = (thumbLeft / sliderWidth) * maxScrollLeft;

    cardGrid.scrollLeft = scrollLeft;
  };
  const handleCardGridScroll = () => {
    const cardGrid = cardGridRef?.current;
    const scrollLeft = cardGrid?.scrollLeft;
    const maxScrollLeft = cardGrid?.scrollWidth - cardGrid?.clientWidth;
    const sliderWidth = sliderRef?.current?.offsetWidth;
    const thumbWidth = sliderThumbRef?.current?.offsetWidth;
    const maxThumbLeft = sliderWidth - thumbWidth;
    const thumbLeft = (scrollLeft / maxScrollLeft) * maxThumbLeft;
    sliderThumbRef.current.style.left = `${thumbLeft}px`;
  };
  const handleNavigate = () => {
    navigate(`/deal?stid=${encryptAES(String(sectionData?.id))}`);
  };
  return (
    <div className="p-10 w-top">
      <div className="flex justify-between items-center mb-4">
        <h2 className="w-head">{text}</h2>
        {dealfilter?.length  ? (
          <span
            // to="/top-offer"
            // state={{
            //   topOffer: true,
            //   filteredDeals: dealfilter,
            //   topOfferHead: text,
            // }}
            onClick={handleNavigate}
            className="w-view cursor-pointer"
          >
            View all
          </span>
        ) : (
          ""
        )}
      </div>

      <div className="relative">
        {dealfilter?.length >= 4 ? (
          <button
            className="absolute left-[-20px] top-[28%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 flex items-center justify-center w-10 h-10"
            aria-label="Scroll Left"
            onClick={handleScrollLeft}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
        ) : (
          ""
        )}
        <div
          className="flex gap-5 overflow-x-auto no-scrollbar"
          ref={cardGridRef}
          onScroll={handleCardGridScroll}
        >
          {dealfilter?.map((offer, index) => {
            const matchingCoupon = dealCouponFilter.find(
              (coupon) => coupon.dealId === offer.id && coupon.enabled
            );
            return (
              <>
                <CommonWebOffer
                  index={index}
                  dealCouponFilter={dealCouponFilter}
                  offer={offer}
                  matchingCoupon={matchingCoupon}
                />
              </>
            );
          })}
        </div>
        {dealfilter?.length > 3 ? (
          <button
            className="absolute right-[-20px] top-[28%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 flex items-center justify-center w-10 h-10"
            aria-label="Scroll Left"
            onClick={handleScrollRight}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 transform scale-x-[-1]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
        ) : (
          ""
        )}
      </div>
      {dealfilter?.length > 3 && (
        <div className="slider_wrapper pt-4">
          <div
            className="slider"
            ref={sliderRef}
            onMouseMove={handleSliderChange}
          >
            <div className="slider_track"></div>
            <div className="slider_thumb" ref={sliderThumbRef}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebTopOffer;

import { call, put, takeLatest } from "redux-saga/effects";
import { callUnlockSectionGetApi, callUnlockSectionLinkedMasterGetApi, callUnlockSectionPostApi } from "../Context/unlockSectionApi";
import { onGetUnlockSection, onGetUnlockSectionError, onGetUnlockSectionLinkedMaster, onGetUnlockSectionLinkedMasterError, onGetUnlockSectionLinkedMasterSuccess, onGetUnlockSectionSuccess, onPostUnlockSectionError, onPostUnlockSectionSuccess, onPosUnlockSection } from "../Store/Slices/unlockSectionSlice";

function* GetUnlockSection({payload}) {
  try {
    const getUnlockSectionResponse = yield call(callUnlockSectionGetApi,payload);
    if (getUnlockSectionResponse.responseCode === "200") {
      yield put(
        onGetUnlockSectionSuccess({
          data: getUnlockSectionResponse.response,
          message: getUnlockSectionResponse.responseMessage,
          status_code:getUnlockSectionResponse.responseCode
        })
      );
    } else {
      yield put(
        onGetUnlockSectionError({
          data: getUnlockSectionResponse.response,
          message: getUnlockSectionResponse.response.message,
          status_code:getUnlockSectionResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onGetUnlockSectionError({ data: [], message, status_code: 400 }));
  }
}
function* GetUnlockSectionLinkedMaster() {
  try {
    const getUnlockSectionLinkedIdResponse = yield call(callUnlockSectionLinkedMasterGetApi);
    if (getUnlockSectionLinkedIdResponse.responseCode === "200") {
      yield put(
        onGetUnlockSectionLinkedMasterSuccess({
          data: getUnlockSectionLinkedIdResponse.response,
          message: getUnlockSectionLinkedIdResponse.responseMessage,
          status_code:getUnlockSectionLinkedIdResponse.responseCode
        })
      );
    } else {
      yield put(
        onGetUnlockSectionLinkedMasterError({
          data: getUnlockSectionLinkedIdResponse.response,
          message: getUnlockSectionLinkedIdResponse.response.message,
          status_code:getUnlockSectionLinkedIdResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onGetUnlockSectionLinkedMasterError({ data: [], message, status_code: 400 }));
  }
}
function* PostUnlockSection({ payload }) {

  try {
    const postUnlockSectionResponse = yield call(callUnlockSectionPostApi, payload);
    if (postUnlockSectionResponse.responseCode === "200") {
      yield put(
        onPostUnlockSectionSuccess({
          postData: postUnlockSectionResponse.response,
          message: postUnlockSectionResponse.responseMessage,
          status_code: postUnlockSectionResponse.responseCode,
        })
      );
    } else {
      yield put(
        onPostUnlockSectionError({
          data: postUnlockSectionResponse.response,
          message: postUnlockSectionResponse?.responseMessage,
          status_code:postUnlockSectionResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onPostUnlockSectionError({ data: [], message, status_code: 400 }));
  }
}
export default function* unlockSectionSaga() {
  yield takeLatest(onGetUnlockSection.type, GetUnlockSection);
  yield takeLatest(onGetUnlockSectionLinkedMaster.type, GetUnlockSectionLinkedMaster);
  yield takeLatest(onPosUnlockSection.type, PostUnlockSection);
}

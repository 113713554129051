/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  DealCouponData,
  DealCouponFreqData,
  DealData,
  GetSegment,
  ProductContentData,
  SectionContentMasterData,
  ThemeData,
  UtilityReducer,
  UserCardBase,
  ValidateLoginToken,
  ApplyThemeStyles,
} from "../../Utility/Utility";
import ScrollToTop from "../../Utility/ScrollToTop/ScrollToTop";
import Loader from "../../Utility/Loader/Loader";
import { onSaveUnlockContId } from "../../Store/Slices/utilityReducer";
import { onGetUnlockSection } from "../../Store/Slices/unlockSectionSlice";
import {
  onGetUserCardBaseHead,
  onPostUserCardBase,
  onPostUserCardBaseReset,
} from "../../Store/Slices/userCardBaseSlice";

const ClaimBonus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productContentData } = ProductContentData();
  const { dealData } = DealData();
  const { dealCouponData } = DealCouponData();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = parseInt(queryParams.get("stid"));
  const { sctionContentdata } = SectionContentMasterData();
  const sectionContent = sctionContentdata.filter(
    (findData) => findData?.sectionMasterId === id
  );
  const { dealCouponFreqData } = DealCouponFreqData();
  const card = UtilityReducer();
  const token = ValidateLoginToken();
  const unlock = useSelector(
    (state) => state?.unlockSectionReducer?.UnlockSectionData
  );
  const usercardBase = UserCardBase();
  const segmentData = GetSegment();
  const segments = segmentData?.data?.[0]?.mappedSegment;
  const [url, setUrl] = useState(null);
  const currentSegmentCard = segments?.find(
    (filterData) => filterData?.segmentId === card?.segmentId
  )?.subIdentifier;
  const currentCardData = card?.creditCards?.find(
    (filterData) => filterData?.identifier === currentSegmentCard
  );
  const unlockAPIData = useSelector((state) => state?.unlockSectionReducer);
  useEffect(() => {
    setLoading(true);
    dispatch(onGetUnlockSection({ memberIdentifier: token?.memberIdentifier }));
    dispatch(
      onGetUserCardBaseHead({ customerUcicId: token?.memberIdentifier })
    );
  }, []);

  useEffect(() => {
    if (loading && unlockAPIData?.get_status_code) {
      setLoading(false);
    }
  }, [unlockAPIData]);
  const isClaimed = (linkedMasterId, card) => {
    const idKey =
      card?.productType === "Gift Cards" || card?.productType === "Products"
        ? "linkedMasterId"
        : "offerId";

    return sectionContent?.some((filterData) =>
      unlock?.some(
        (unlockItem) =>
          unlockItem.sectionId === filterData.sectionMasterId &&
          unlockItem[idKey] === linkedMasterId &&
          unlockItem.status === false
      )
    );
  };

  const dealCouponFilter = dealCouponData.filter((deal) => {
    if (deal.typeOfCoupoun === "Membership") {
      return dealCouponFreqData?.some(
        (data) => data?.dealCoupounId === deal.id
      );
    }
    return false;
  });
  const isProduct = sectionContent.filter((filterData) => {
    return filterData?.contentSourceType === "Products";
  }).length
    ? true
    : false;
  const isGiftard = sectionContent.filter((filterData) => {
    return filterData?.contentSourceType === "Gift Cards";
  }).length
    ? true
    : false;

  let filteredProducts =
    isProduct || isGiftard
      ? sectionContent
          .map(
            (filterData) =>
              Array.isArray(productContentData) &&
              productContentData.filter(
                (product) => product.productId === filterData.linkedMasterId
              )
          )
          .flat()
      : dealData
          .filter((deal) =>
            dealCouponFilter?.some((freqData) => freqData.dealId === deal.id)
          )
          .filter((deal) =>
            sectionContent?.some((filter) => filter.linkedMasterId === deal.id)
          );

  const handleClick = (product, giftcarrd, data, dealCoupons) => {
    const sectionContentId = sectionContent?.find(
      (findData) => findData?.linkedMasterId === data?.productId
    )?.id;
    setLoading(true);
    dispatch(
      onSaveUnlockContId({
        sectionContentId: sectionContentId,
      })
    );
    if (product || giftcarrd) {
      const payLoad = {
        enabled: true,
        createdBy: 10,
        cardFirstFourDigitNumber: currentCardData?.CardNoFirst6Digit,
        cardLastFourDigitNumber: currentCardData?.CardNoFirst4Digit,
        cardName: currentCardData?.CardTypeName,
        customerUcicId: token?.memberIdentifier,
        offerName: card?.text,
        name: token?.firstName,
        email: token?.emailId,
        phoneNumber: token.mobileNumber,
        cardSubIdentifier: currentCardData?.identifier,
        cardImage: currentCardData?.backgroundcolor,
        productId: data?.productId,
        sectionMasterId: card?.sectionId,
      };

      dispatch(onPostUserCardBase(payLoad));
      setUrl(
        data?.productUrl +
          `&${card?.sectionId}&${sectionContentId}&${data?.productId}`
      );

      setLoading(true);
    } else {
      navigate("/gift-voucher", {
        state: { deal: data, coupons: dealCoupons },
      });
    }
  };

  const themeData = ThemeData();

  useEffect(() => {
    ApplyThemeStyles(themeData);
  }, [themeData]);
  const handlereDirect = () => {
    setLoading(true);
    window.open(url, "_self");
  };
  useEffect(() => {
    if (usercardBase.post_status_code === "200") {
      handlereDirect();
      dispatch(onPostUserCardBaseReset());
      dispatch(
        onGetUserCardBaseHead({ customerUcicId: token.memberIdentifier })
      );
    }
  }, [usercardBase]);
  return (
    <>
      <ScrollToTop />
      <div className="claim-container">
        <div className="row ">
          <div className="offer_title1 mobile_heading">
            <h3 className="hide_desktop">
              <button
                onClick={() => navigate("/")}
                className="relative flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-8 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10 19l-7-7 7-7"
                  />
                  <line
                    x1="3"
                    y1="12"
                    x2="21"
                    y2="12"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </h3>
          </div>
        </div>
        <>
          {loading ||
          usercardBase.isgetLoading ||
          usercardBase.isPostLoading ? (
            <Loader />
          ) : (
            <div className="px-4 py-4">
              <div dangerouslySetInnerHTML={{ __html: card.text }}></div>
              <div className="card-grid mt-4">
                {filteredProducts.map((card, index) => {
                  const title = dealCouponFilter?.find(
                    (coupon) => coupon?.dealId === card?.id
                  );

                  return (
                    <div
                      key={index}
                      className={`gift-card ${
                        isClaimed(card?.productId || card?.id, card)
                          ? "disabled"
                          : ""
                      }`}
                    >
                      {isGiftard || isProduct ? (
                        <div className="leading-tight gift-head">
                          <span>
                            {card?.name && card.name.length > 20
                              ? card.name.split(/[\s-]/).reduce((acc, word) => {
                                  if ((acc + word).length <= 20) {
                                    return acc + (acc ? " " : "") + word;
                                  }
                                  return acc;
                                }, "") + "..."
                              : card?.name}
                          </span>
                        </div>
                      ) : null}

                      <img
                        onClick={() =>
                          handleClick(isProduct, isGiftard, card, title)
                        }
                        src={
                          isGiftard || isProduct
                            ? card.image
                            : `${process.env.REACT_APP_CLIENT_IMAGE_URL}${card?.mobImage}`
                        }
                        className={`${
                          card?.productType === "Products"
                            ? "object-con"
                            : "object-cov"
                        } ${
                          isClaimed(card?.productId, card) ? "greyed-out" : ""
                        }`}
                        alt={card?.name || "Card Image"}
                        title={card?.name}
                      />

                      {!(isGiftard || isProduct) && (
                        <div className="pl-2 pt-2">
                          <div
                            className="titile-head"
                            onClick={() =>
                              handleClick(isProduct, isGiftard, card, title)
                            }
                          >
                            {card.name?.length > 22
                              ? card.name.substring(0, 22) + "..."
                              : card?.name}
                          </div>
                          <div
                            className="title-claim"
                            onClick={() =>
                              handleClick(isProduct, isGiftard, card, title)
                            }
                          >
                            {title?.title?.length > 15
                              ? title?.title.substring(0, 15) + "..."
                              : title?.title}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default ClaimBonus;

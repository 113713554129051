import axiosInstanceClient from "../Common/Axios/axiosInstanceClient";
import API from "../Common/Endpoint/serviceConstrants";
export const callUserCardBaseGetHeadApi = async (payload) => {
  const { data = {} } = await axiosInstanceClient.get(API.userCardBase, {
    params: {
      customerUcicId: payload?.customerUcicId,
    },
  });
  return data;
};
export const callUserCardBaseGetApi = async () => {
  const { data = {} } = await axiosInstanceClient.get(API.userCardBase);
  return data;
};
export const callUserCardBasePostApi = async (payload) => {
  const { data = [] } = await axiosInstanceClient.post(
    API.userCardBase,
    payload
  );
  return data;
};

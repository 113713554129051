import React from "react";
import { useNavigate } from "react-router-dom";

import { encryptAES } from "../../Common/Axios/encrypt";

const CommonWebOffer = ({
  index,
  offer,
  matchingCoupon,

  page,
}) => {
  const navigate = useNavigate();

  const handleClaim = (dealData) => {
  navigate(`/Offers?did=${encryptAES(String(dealData?.id))}`);

  };
  return (
    <div
      key={index}
      className="w-64 flex-shrink-0 top-card rounded-lg overflow-hidden mt-2 flex flex-col"
      onClick={() => handleClaim(offer)}
    >
      <div className="relative">
        <img
          src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${offer?.webImage}`}
          alt={offer.name}
          title={offer.name}
          className="p-img"
        />
        {matchingCoupon.discountText && (
          <span className="absolute top-0 left-0 discount px-3 py-1">
            {matchingCoupon.discountText}
          </span>
        )}
        {/* <img
          src={`${process.env.REACT_APP_CLIENT_IMAGE_URL}${offer?.commonImage}`}
          alt={matchingCoupon.title}
          className="small-img"
        /> */}
      </div>

      <div className="flex flex-col flex-grow p-2">
        <h3 className="top-title  mt-2 mb-3">
          {offer.name} {page ? <i className="fa fa-angle-right pl-2"></i> : ""}
        </h3>
        <span className="top-des mt-1 mb-3">
          {matchingCoupon.title.length > 55
            ? matchingCoupon.title?.substring(0, 55) + "..."
            : matchingCoupon.title}
        </span>
        <button
          className="web-claim py-2 w-full mt-auto"
          onClick={(e) => {
            e.stopPropagation();
            handleClaim(offer);
          }}
        >
          Claim now
        </button>
      </div>
    </div>
  );
};

export default CommonWebOffer;
